import React from 'react';

import { Box, Paper, Typography, Grid } from '@mui/material';

import PageHeader from './PageHeader';
import axios from '../api/axios';
import { HContainer } from './TopTipsters';

import { Days } from '../data';

const FreeTipView = ({ data }) => {
    const { time, teamA, teamB, league, tip, type } = data;
    return (
        <Box
            sx={{
                width: '100%',
                // '@media screen and (max-width: 600px)': {
                //     width: '100%'
                // },
                maxHeight: 120,
                minWidth: '100%'
                // minWidth: 307
            }}
        >
            <Paper
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    p: 0,
                    m: 0
                }}
            >
                <Box
                    sx={{
                        px: 1,
                        m: '0',
                        // backgroundColor: '#06001E',
                        backgroundColor: '#00280E',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Typography
                        variant="subtitle2"
                        color={time === 'Live' ? '#FFD565' : 'white'}
                        alignSelf="center"
                        css={{
                            animation: '1.5s linear infinite',
                            '@keyframes animate': {
                                '0%': {
                                    opacity: 0.1
                                },
                                '50%': {
                                    opacity: 0.7
                                },
                                '100%': {
                                    opacity: 0
                                }
                            }
                        }}
                    >
                        {time}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        m: 0,
                        p: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        gap: 1,
                        flexWrap: 'wrap'
                    }}
                >
                    <Typography variant="subtitle2" style={{ textTransform: 'uppercase' }}>
                        {teamA}
                    </Typography>
                    <Typography variant="caption">vs</Typography>
                    <Typography variant="subtitle2" style={{ textTransform: 'uppercase' }}>
                        {teamB}
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="button" display="block" gutterBottom>
                        {league}
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="caption" display="block" gutterBottom>
                        {type} - {tip}
                    </Typography>
                </Box>
            </Paper>
        </Box>
    );
};

function createTip(data) {
    const tip = data['tips'][0];
    const game = data['tips'][0]['fixture'];
    const date = new Date(game['game_date']);

    const day = new Date().getDay() === date.getDay() ? 'Today' : Days[date.getDay()];
    const parseMinutes = (mins) => (mins <= 9 ? `0${mins}` : `${mins}`);
    const time = `${date.getHours()}:${parseMinutes(date.getMinutes())}`;

    let game_time = `${day} ${time}`;

    console.log(game);
    console.log(tip);

    return {
        time: game_time,
        teamA: game['home_team'],
        teamB: game['away_team'],
        league: game['league'],
        type: tip['m_market']['type'],
        tip: tip['m_market']['value']
    };
}

class FreeTips extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tips: [],
            phoneSize: false
        };

        this.handleLoadFreeTips = this.handleLoadFreeTips.bind(this);
    }

    componentDidMount() {
        this.handleLoadFreeTips();

        this.interval = setInterval(() => this.handleLoadFreeTips(), 1000 * 30);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleLoadFreeTips() {
        const config = {
            method: 'GET',
            url: '/api/slip/free',
            headers: {}
        };

        axios.request(config).then((res) => {
            this.setState({
                tips: []
            });

            const games = res.data['data'];
            games.map((tip) => {
                this.setState((prev) => ({
                    tips: [...prev.tips, createTip(tip)]
                }));
            });
        });
    }

    render() {
        const { tips } = this.state;

        if (tips.length <= 0) {
            return <></>;
        }

        const matches = [];
        for (let i = 0; i < tips.length; i++) {
            matches.push(
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FreeTipView data={tips[i % tips.length]} />
                </Grid>
            );
        }

        return (
            <Box sx={{ p: 0, m: 1 }}>
                <HContainer>
                    <Paper
                        square
                        elevation={1}
                        sx={{
                            p: 1,
                            mx: 0,
                            my: 1,
                            width: '100%',
                            borderRadius: 1
                        }}
                    >
                        <PageHeader title="Free Games" disableDivider />
                    </Paper>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 1,
                            justifyContent: 'left',
                            flexWrap: 'wrap'
                        }}
                    >
                        <Grid container spacing={1}>
                            {matches}
                        </Grid>
                    </Box>
                </HContainer>
            </Box>
        );
    }
}

export default FreeTips;
