import React from 'react';
import { Button, Modal, Box } from '@mui/material';

import Register from '../../routes/auth/Register';
import Login from '../../routes/auth/Login';

import { blueGradientButton } from '../customStyles';
import PasswordReset from '../../routes/auth/PasswordReset';

class AuthButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            rOpen: false,
            loginText: null
        };

        this.updateLoginText = this.updateLoginText.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleOpenForgotPass = this.handleOpenForgotPass.bind(this);
        this.handleOpenRegistration = this.handleOpenRegistration.bind(this);
    }

    componentDidMount() {
        this.setState({
            open: false,
            rOpen: false,
            fOpen: false
        });

        window.addEventListener('resize', this.updateLoginText);
        this.updateLoginText();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateLoginText);
    }

    updateLoginText() {
        this.setState({
            loginText: window.innerWidth < 720 ? null : 'LOGIN'
        });
    }

    handleOpen() {
        this.handleCloseModals();
        this.setState({ open: true });
    }

    handleOpenRegistration() {
        this.handleCloseModals();
        this.setState({ rOpen: true });
    }

    handleOpenForgotPass() {
        this.handleCloseModals();
        this.setState({ fOpen: true });
    }

    handleCloseModals() {
        this.setState({
            open: false,
            rOpen: false,
            fOpen: false
        });
    }

    render() {
        const open = this.state.open;
        const rOpen = this.state.rOpen;
        const fOpen = this.state.fOpen;

        const handleClose = () => {
            this.handleCloseModals();
        };

        const handleLoginAction = () => {
            this.handleOpen();
        };

        const handleRegisterAction = () => {
            this.handleOpenRegistration();
        };

        const handleLogin = () => {
            this.props.handleLogin();
            this.handleCloseModals();
        };

        const handleRemembered = () => {
            this.handleCloseModals();
            this.handleOpen();
        };

        const handleForgotPassword = () => {
            this.handleCloseModals();
        };

        return (
            <Box sx={{ p: 0, m: 0 }}>
                <Button
                    style={{ ...blueGradientButton }}
                    sx={this.props.sx}
                    variant="contained"
                    onClick={handleRegisterAction}
                >
                    Become a Tipster
                </Button>

                <Modal
                    open={rOpen}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-register-title"
                >
                    <Register
                        handleRegister={handleLogin}
                        handleLogin={this.handleOpen}
                    />
                </Modal>

                <Button
                    style={{ background: '#211b36' }}
                    sx={{ ...this.props.sx }}
                    variant="contained"
                    onClick={handleLoginAction}
                    // startIcon={<LoginIcon />}
                >
                    Login
                </Button>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-login-title"
                    aria-describedby="modal-modal-login-description"
                >
                    <Login
                        handleLogin={handleLogin}
                        handleForgotPass={this.handleOpenForgotPass}
                        handleRegister={this.handleOpenRegistration}
                    />
                </Modal>

                <Modal
                    open={fOpen}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-forgot-title"
                    aria-describedby="modal-modal-forgot-description"
                >
                    <PasswordReset
                        handleLogin={handleRemembered}
                        handleForgotPass={handleForgotPassword}
                    />
                </Modal>
            </Box>
        );
    }
}

export default AuthButtons;
