import React from 'react';
import { useNavigate } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { Chip, CircularProgress, Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import BreadCrumbView from '../../components/app_bar/BreadCrumbView';
import { HContainer } from '../../components/TopTipsters';
import PageHeader from '../../components/PageHeader';
import { StyledDataGrid } from '../../components/data_lists/DataListComponents';
import axios from '../../api/axios';

function createData(withdraw, id) {
    const { amount, status, phone_number, date, total_paid } = withdraw;

    return {
        id: id,
        amount: amount,
        status: status,
        phone: phone_number,
        balance: amount - total_paid,
        date: Date.parse(date)
    };
}

const parseDate = (date) => {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

const dataColumns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 10
    },
    {
        field: 'amount',
        headerName: 'Amount',
        minWidth: 120,
        flex: 1
    },
    {
        field: 'balance',
        headerName: 'Balance',
        minWidth: 120,
        flex: 1
    },
    {
        field: 'date',
        headerName: 'Date',
        minWidth: 150,
        flex: 1,
        renderCell: (params) => (
            <Typography variant="body1">
                {parseDate(new Date(params.row.date))}
            </Typography>
        )
    },
    {
        field: 'phone',
        headerName: 'Phone',
        minWidth: 120,
        flex: 1
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 130,
        flex: 1,
        renderCell: (params) => {
            const value = params.row.status;
            const size = 'small',
                variant = 'outlined';
            if (value === 'P') {
                return (
                    <Chip
                        label="pending"
                        color="primary"
                        variant={variant}
                        size={size}
                    />
                );
            }

            if (value === 'W') {
                return (
                    <Chip
                        label="paid"
                        color="success"
                        variant={variant}
                        size={size}
                    />
                );
            }

            return <Chip label="unpaid" variant={variant} size={size} />;
        }
    }
];

class WithdrawRequestComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            withdrawals: [],
            username: null,
            loading: true
        };

        this.handleLoadPayments = this.handleLoadPayments.bind(this);
    }

    componentDidMount() {
        this.handleLoadPayments();
    }

    handleLoadPayments() {
        const { cookies } = this.props;
        const token = cookies.get('token');

        const options = {
            method: 'GET',
            url: '/api/finance/withdrawals',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        axios
            .request(options)
            .then((response) => {
                const data = response.data;
                const withdrawals = data['withdrawals'];
                let id = 1;
                withdrawals.map((withdrawal) => {
                    this.setState((prevState) => ({
                        withdrawals: [
                            ...prevState.withdrawals,
                            createData(withdrawal, id++)
                        ]
                    }));
                });
                this.setState({ loading: false });
                console.log(withdrawals);
            })
            .catch((err) => {
                console.log(err.response);
                this.setState({
                    loading: false
                });
            });
    }

    render() {
        return (
            <Box
                sx={{
                    p: 1,
                    m: 0,
                    gap: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}
            >
                <BreadCrumbView sx={1} />
                <HContainer>
                    <Paper
                        square
                        elevation={1}
                        sx={{
                            p: 1,
                            mx: 0,
                            my: 1,
                            width: '100%',
                            height: '100%',
                            borderRadius: 1
                        }}
                    >
                        <PageHeader
                            sx={{ pl: 2 }}
                            title="Withdraw Requests"
                            description="Withdrawals"
                            disableDivider
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                minHeight: 500
                            }}
                        >
                            {this.state.loading ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                        height: 450,
                                        m: 0
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <StyledDataGrid
                                    rows={this.state.withdrawals}
                                    columns={dataColumns}
                                    pageSize={100}
                                    rowsPerPageOptions={[100]}
                                    disableSelectionOnClick
                                />
                            )}
                        </Box>
                    </Paper>
                </HContainer>
            </Box>
        );
    }
}

function WithdrawRequest(props) {
    const navigate = useNavigate();

    return <WithdrawRequestComponent {...props} navigate={navigate} />;
}

export default withCookies(WithdrawRequest);
