import React from 'react';

import { useNavigate, useParams } from 'react-router';
import { Box, Paper, Typography } from '@mui/material';

import axios from '../api/axios';
import { useCookies } from 'react-cookie';

export default function EmailVerification() {
    const [cookies] = useCookies(['token']);
    const params = useParams();

    const token = cookies.token;
    const verificationCode = params.code;

    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [successMessage, setSuccessMessage] = React.useState('');

    const handleVerifyCode = () => {
        const config = {
            method: 'POST',
            url: '/api/account/verify-email-verification/'.concat(
                verificationCode
            ),
            headers: {
                Authorization: 'Bearer '.concat(token)
            }
        };

        axios
            .request(config)
            .then((response) => {
                const data = response.data;
                if (data) {
                    setSuccessMessage(data['message']);
                }
            })
            .catch((error) => {
                const data = error.response['data'];
                if (data && data['message']) {
                    setErrorMessage(data['message']);
                } else {
                    setErrorMessage("An error occured");
                }
            }).finally(() => {
                setLoading(false)
            })
    };

    React.useEffect(() => {
        if (!token) {
            setErrorMessage("You need to be logged in to use the email verification link");
            setLoading(false);
            return;
        }

        handleVerifyCode();
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Paper
                square
                elevation={1}
                sx={{
                    p: 4,
                    mx: 1,
                    my: 1,
                    borderRadius: 1,
                    maxWidth: '100%',
                    minWidth: 350,
                    alignSelf: 'center',
                    justifySelf: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1
                }}
            >
                <Typography variant="h5">
                    Email Verification
                </Typography>

                <Typography variant="subtitle1">
                    {successMessage}
                </Typography>

                <Typography variant="subtitle1" color="error">
                    {errorMessage}
                </Typography>
            </Paper>
        </Box>
    );
}
