import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Button, Paper, Typography } from '@mui/material';
import axios from '../../api/axios';
import PasswordInput from '../../components/controls/PasswordInput';

export default function PasswordChange() {
    let params = useParams();
    let navigate = useNavigate();

    const [newPassword, setNewPassword] = React.useState('');
    const [newPasswordErr, setNewPasswordErr] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [confirmPasswordErr, setConfirmPasswordErr] = React.useState('');

    const [errorMessage, setErrorMessage] = React.useState('');

    const user_token = params.user_token;

    React.useEffect(() => {
        if (!user_token) {
            navigate('/');
        }
    });

    const resetErrors = () => {
        setNewPasswordErr('');
        setConfirmPasswordErr('');
        setErrorMessage('');
    };

    const handleSubmit = () => {
        resetErrors();
        let errors = false;
        if (!newPassword) {
            setNewPasswordErr('Fill This');
            errors = true;
        } else if (newPassword.length < 8) {
            setNewPasswordErr('At least 8 characters');
            errors = true;
        }

        if (errors) {
            return;
        }

        if (!confirmPassword) {
            setConfirmPasswordErr('Fill this');
            errors = true;
        } else if (confirmPassword !== newPassword) {
            setConfirmPasswordErr('This should be similar to the password');
            errors = true;
        }

        if (errors) {
            return;
        }

        const options = {
            method: 'POST',
            url: '/api/account/reset-password/confirm/',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                password: newPassword,
                token: user_token
            }
        };

        axios
            .request(options)
            .then(() => {
                setErrorMessage('Password set successfully.');
            })
            .catch((error) => {
                const data = error.response.data;
                if (data['password']) {
                    setErrorMessage(data['password'][0]);
                } else {
                    setErrorMessage('Invalid token. Repeat the process.');
                }
            });
    };

    const handleChangeNewPass = (e) => {
        setNewPassword(e.target.value);
    };

    const handleChangeConfirmPass = (e) => {
        setConfirmPassword(e.target.value);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Paper
                square
                elevation={1}
                sx={{
                    p: 0,
                    mx: 1,
                    my: 1,
                    borderRadius: 1,
                    maxWidth: '100%',
                    minWidth: 350,
                    alignSelf: 'center',
                    justifySelf: 'center'
                }}
            >
                <form>
                    <Box
                        sx={{
                            px: 0,
                            paddingTop: 5,
                            paddingBottom: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            id="modal-modal-login-title"
                            variant="h5"
                            component="div"
                            fontWeight={500}
                        >
                            Password Reset
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            gap: 1,
                            paddingX: 3,
                            paddingY: 5,
                            m: 0
                        }}
                    >
                        <PasswordInput
                            label="New Password"
                            error={newPasswordErr}
                            onChange={handleChangeNewPass}
                        />

                        <PasswordInput
                            label="Confirm Password"
                            error={confirmPasswordErr}
                            onChange={handleChangeConfirmPass}
                        />

                        <Box
                            sx={{
                                p: 0,
                                m: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 3
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{ w: 1, minWidth: '100%' }}
                                disableElevation
                                onClick={handleSubmit}
                            >
                                Reset The Password
                            </Button>

                            <Typography
                                variant="caption"
                                display="block"
                                color="red"
                            >
                                {errorMessage}
                            </Typography>
                        </Box>
                    </Box>
                </form>
            </Paper>
        </Box>
    );
}
