export function createGameSearch(id, teamA, teamB, date, league) {
    return {
        id: parseInt(id),
        teamA: teamA,
        teamB: teamB,
        date: new Date(date),
        league: league
    };
}

export const Months = [
    '',
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC'
];

export const Days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
];

// noinspection SpellCheckingInspection
export const GOOGLE_ANALYTICS_KEY = 'G-4MYXBP6X4T';

export const CURRENCIES = {
    ke: 'Ksh',
    // ng: '₦',
    ng: 'NGN',
    ug: 'UGX'
};
