import React from 'react';
import Box from '@mui/material/Box';
import { Avatar, Button, CircularProgress, Link, Paper } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { withCookies } from 'react-cookie';

import PageHeader from '../../components/PageHeader';
import { HContainer } from '../../components/TopTipsters';
import axios from '../../api/axios';
import { StyledDataGrid } from '../../components/data_lists/DataListComponents';
import BreadCrumbView from '../../components/app_bar/BreadCrumbView';
import { stringAvatarSx } from '../profile/Profile';

import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_KEY } from '../../data';
import Title from '../../components/Title';

ReactGA.initialize(GOOGLE_ANALYTICS_KEY);
ReactGA.pageview(window.location.pathname + window.location.search);

function createData(data, id, navigate, weekly) {
    const state = {
        user: data.user,
        profile: data.profile,
        stats: data.stats,
        stats2: data.stats2,
        active_tips: data.active_tips,
        finished_tips: data.finished_tips
    };

    return {
        id: id,
        username: state.user['username'],
        profile_pic: state.profile['profile_image_url'],
        tips: state.stats[0]['tips'],
        won: state.stats[1]['won'],
        lost: state.stats[3]['lost'],
        week_rating: state.stats2['week_rating'],
        rating: state.stats[4]['rating'],
        navigate: navigate
    };
}

const getProfilePic = (profile_url, username) =>
    profile_url ? (
        <Avatar
            sx={{ ml: 0, p: 0, width: 24, height: 24 }}
            alt="Profile Pic"
            src={profile_url}
        />
    ) : (
        <Avatar
            {...stringAvatarSx({
                name: username,
                sx: {
                    ml: 0,
                    p: 0,
                    width: 24,
                    height: 24,
                    fontSize: 'smaller',
                    fontWeight: 500
                }
            })}
        />
    );

const dataColumns = [
    {
        field: 'profile_pic',
        headerName: 'Profile',
        minWidth: 35,
        flex: 1,
        renderCell: (params) =>
            getProfilePic(params.row.profile_pic, params.row.username)
    },
    {
        field: 'username',
        headerName: 'Tipster',
        minWidth: 100,
        flex: 1,
        editable: false,
        renderCell: (params) => (
            <Link
                fontWeight="bold"
                fontFamily="GT Walsheim Pro"
                fontSize="small"
                underline="hover"
                style={{ color: 'black' }}
                to={`/profile/${params.row.username}`}
            >
                {params.row.username}
            </Link>
        )
    },
    {
        field: 'tips',
        headerName: 'Tips',
        type: 'number',
        width: 55,
        flex: 1,
        editable: false,
        sortable: true
    },
    {
        field: 'won',
        headerName: 'Won',
        type: 'number',
        width: 55,
        flex: 1,
        editable: false,
        sortable: true
    },
    {
        field: 'lost',
        headerName: 'Lost',
        type: 'number',
        width: 55,
        flex: 1,
        editable: false,
        sortable: true
    },
    {
        field: 'rating',
        headerName: 'Rating',
        type: 'number',
        minWidth: 75,
        flex: 1,
        editable: false,
        sortable: true
    },
    {
        field: 'week_rating',
        headerName: 'Weekly Rating',
        type: 'number',
        minWidth: 75,
        flex: 1,
        editable: false,
        sortable: true
    },
    {
        field: 'profile',
        headerName: 'Profile',
        minWidth: 75,
        flex: 1,
        renderCell: (params) => (
            <Button
                variant="contained"
                disableElevation
                size="small"
                onClick={() => {
                    params.row.navigate(`/profile/${params.row.username}`);
                }}
            >
                View
            </Button>
        )
    }
];

class LostTipsListComponent extends React.Component {
    constructor(props) {
        super(props);

        const [query, _] = props.query;

        this.state = {
            tipsters: [],
            query: query
        };

        this.handleLoadAllProfiles = this.handleLoadAllProfiles.bind(this);
    }

    handleLoadAllProfiles() {
        const { navigate } = this.props;
        const { query } = this.state;

        const weekly = query.get('type') === 'weekly' ? 1 : 0;

        const options = {
            method: 'GET',
            url: `/v2/api/tipster/data/?all=1&weekly=${weekly}`
        };

        axios
            .request(options)
            .then((response) => {
                const tipsters = response.data['results'];

                for (let i = 0; i < tipsters.length; i++) {
                    this.setState((prev) => ({
                        tipsters: [
                            ...prev.tipsters,
                            createData(
                                tipsters[i].result,
                                i + 1,
                                navigate,
                                weekly
                            )
                        ]
                    }));
                }
            })
            .catch((error) => {
                if (error) console.error(error);
            });
    }

    componentDidMount() {
        this.handleLoadAllProfiles();
    }

    render() {
        return (
            <Box
                sx={{
                    p: 1,
                    m: 0,
                    gap: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                    // maxWidth: 650
                }}
            >
                <Title title="FutaaPredict| Top Tipsters" />

                <BreadCrumbView sx={1} />

                <HContainer>
                    <Paper
                        square
                        elevation={1}
                        sx={{
                            p: 1,
                            mx: 0,
                            my: 0,
                            width: '100%',
                            height: '100%',
                            borderRadius: 1
                        }}
                    >
                        <PageHeader
                            sx={{ pl: 2 }}
                            title="Top Tipsters"
                            description="Select an option"
                            disableDivider
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                minHeight: 500
                            }}
                        >
                            {this.state.tipsters.length === 0 ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                        height: 450,
                                        m: 0
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <StyledDataGrid
                                    initialState={{
                                        sorting: {
                                            sortModel: [
                                                {
                                                    field: 'rating',
                                                    sort: 'desc'
                                                }
                                            ]
                                        }
                                    }}
                                    rows={this.state.tipsters}
                                    columns={dataColumns}
                                    pageSize={100}
                                    rowsPerPageOptions={[100]}
                                    disableSelectionOnClick
                                />
                            )}
                        </Box>
                    </Paper>
                </HContainer>
            </Box>
        );
    }
}

function LostTipsList(props) {
    const navigate = useNavigate();
    const query = useSearchParams();

    return (
        <LostTipsListComponent {...props} navigate={navigate} query={query} />
    );
}

export default withCookies(LostTipsList);
