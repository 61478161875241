import React from 'react';
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    Container,
    Grid,
    Link,
    List,
    ListItem,
    ListItemText,
    Paper,
    Skeleton,
    Typography
} from '@mui/material';

import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import axios from '../api/axios';
import Divider from '@mui/material/Divider';
import PageHeader from './PageHeader';
import { stringAvatar } from '../routes/profile/Profile';
import { HContainer } from './TopTipsters';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

function getCompanyJackpot(company, _type) {
    const companies = {
        SP: 'SportPesa',
        BT: 'Betika'
    };

    const types = {
        MG: 'Mega',
        MW: 'Mid Week'
    };

    return `${companies[company]} ${types[_type]} Jackpot`;
}

class ActiveTipComponent extends React.Component {
    constructor(props) {
        super(props);

        console.log(props);

        const { slip, jackpot, cookies } = this.props;

        this.state = {
            slip: slip ? slip : null,
            tips: slip.tips ? slip.tips : [],
            user: slip.user ? slip.user : [],
            accumulator: 0,
            company: '',
            type: '',
            meta: {},
            rating: 0,
            jackpot: jackpot ? jackpot : {},
            limit: this.props.limit || 8
        };

        this.handleBuyTip = this.handleBuyTip.bind(this);
        this.handleBuyTipNg = this.handleBuyTipNg.bind(this);
        this.handleClickChip = this.handleClickChip.bind(this);
        this.handlePublishUnpublishTip = this.handlePublishUnpublishTip.bind(this);

        console.log(this.state);
    }

    handleBuyTip() {
        this.props.navigate(`/buy_tip/${this.state.slip['id']}`);
    }

    handleBuyTipNg() {
        this.props.navigate(`/buy_tip_ng/${this.state.slip['id']}`);
    }

    handleClickChip(user) {
        const { navigate } = this.props;
        navigate(`/profile/${user}`);
    }

    handlePublishUnpublishTip(e) {
        e.preventDefault();
    }

    render() {
        const IconChip = ({ username = '' }) => {
            const link = username.toLowerCase().replaceAll(' ', '-').trim();
            return (
                <Link to={`/jackpot/${link}`} underline="hover" color="black">
                    <Chip
                        size="small"
                        style={{
                            cursor: 'pointer'
                        }}
                        sx={{
                            '& .MuiChip-label': {
                                fontFamily: 'GT Walsheim Pro',
                                fontWeight: '500',
                                fontSize: 'small'
                            }
                        }}
                        avatar={<Avatar style={{ color: 'white', fontWeight: 'bold' }} {...stringAvatar(username)} />}
                        label={username}
                    />
                </Link>
            );
        };

        const getYield = (num) => {
            const _val = Math.round((num + Number.EPSILON) * 100) / 100;
            const sign = _val >= 0 ? '+' : '';
            return `${sign}${_val}`;
        };

        try {
            if (!this.state.slip) {
                return null;
            }

            const myDate = (_date) => {
                const date = new Date(Date.parse(_date));
                // noinspection JSCheckFunctionSignatures
                return new Intl.DateTimeFormat('en-US', {
                    dateStyle: 'medium'
                }).format(date);
            };

            const tipCount = [];
            if (this.state.slip)
                for (let i = 0; i < this.state.tips.length; i++) {
                    if (i >= this.state.limit) break;
                    const game_date = new Date(this.state.tips[i]['game_date']);
                    const hours = (game_date - Date.now()) / 36e5;

                    tipCount.push(
                        <ListItem key={i} disablePadding divider>
                            <ListItemText
                                secondaryTypographyProps={{
                                    color: 'black'
                                }}
                                primaryTypographyProps={{
                                    color: 'black',
                                    fontSize: 'smaller'
                                }}
                                primary={`${this.state.tips[i]['home_team']} vs ${this.state.tips[i]['away_team']}`}
                            />
                        </ListItem>
                    );
                }

            if (this.state.slip && this.state.tips.length > this.state.limit)
                tipCount.push(
                    <ListItem key={10001} disablePadding>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 0,
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 1
                            }}
                        >
                            <MoreHorizIcon />
                            <Link href={`/buy_tip/${this.state.slip['id']}`} sx={{}}>
                                <Typography variant="caption">{'SEE MORE'}</Typography>
                            </Link>
                        </Box>
                    </ListItem>
                );

            return (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{
                        p: 1,
                        '@media screen and (max-width: 600px)': {
                            width: '100%'
                        }
                    }}
                >
                    <Card
                        elevation={0}
                        variant="outlined"
                        sx={{
                            display: 'inline-block',
                            m: 0,
                            width: '100%'
                        }}
                    >
                        <CardContent sx={{ maxWidth: '100%', minHeight: 220 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    p: 0
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        p: 0,
                                        m: 0,
                                        gap: 1,
                                        alignItems: 'center'
                                    }}
                                >
                                    <IconChip
                                        username={getCompanyJackpot(
                                            this.state.jackpot['company'],
                                            this.state.jackpot['type']
                                        )}
                                    />

                                    <Chip
                                        size="small"
                                        label={this.state.slip ? myDate(this.state.slip['date_created']) : null}
                                        sx={{
                                            '& .MuiChip-label': {
                                                fontFamily: 'GT Walsheim Pro',
                                                fontWeight: '500',
                                                fontSize: 'small'
                                            }
                                        }}
                                    />
                                </Box>

                                <List sx={{ width: '100%' }}>{tipCount}</List>
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 1,
                                    width: 1
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        size="small"
                                        disableElevation
                                        sx={{ width: 1, flexWidth: 5 }}
                                        onClick={this.handleBuyTip}
                                    >
                                        BUY @Ksh. {this.state.slip['price']}
                                    </Button>
                                    {/* <Button
                                        variant="contained"
                                        size="small"
                                        disableElevation
                                        sx={{ width: 1, flexWidth: 5 }}
                                        onClick={this.handleBuyTipNg}
                                        color="warning"
                                    >
                                        BUY @NGN. {this.state.slip['price_ng']}
                                    </Button> */}
                                </Box>
                            </Box>
                        </CardActions>
                    </Card>
                </Grid>
            );
        } catch (err) {
            console.log(err);
            return <Box>None</Box>;
        }
    }
}

function ActiveTip({ slip, jackpot, cookies, limit = null }) {
    const navigate = useNavigate();
    return <ActiveTipComponent slip={slip} jackpot={jackpot} cookies={cookies} navigate={navigate} limit={limit} />;
}

export function JackpotActiveTip({ slip, jackpot, cookies, limit = 8 }) {
    return <ActiveTip slip={slip} jackpot={jackpot} cookies={cookies} limit={limit} />;
}

class MyGamesComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            jackpot_slips: []
        };

        this.handleLoadJackpotSlips = this.handleLoadJackpotSlips.bind(this);
    }

    componentDidMount() {
        this.handleLoadJackpotSlips();
    }

    handleLoadJackpotSlips() {
        let config = {
            method: 'GET',
            url: '/api/jackpot/get-all-jackpots'
            // headers: {
            //     'Authorization': `Bearer ${this.props.cookies.get("token")}`
            // }
        };

        axios
            .request(config)
            .then((res) => {
                const data = res.data;
                console.log(data);

                this.setState({
                    jackpot_slips: data['data']
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    render() {
        return (
            <Box sx={{ p: 0, m: 0 }}>
                <Paper
                    square
                    elevation={1}
                    sx={{
                        py: 1,
                        mx: 0,
                        my: 1,
                        width: '100%',
                        height: '100%',
                        borderRadius: 1
                    }}
                >
                    <PageHeader sx={{ pl: 0 }} title="Jackpot Tips" description="Jackpot tips" />
                    <Box
                        sx={{
                            p: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    >
                        <Grid
                            container
                            spacing={0}
                            sx={{
                                m: 0,
                                p: 0,
                                maxWidth: '100%'
                            }}
                        >
                            {this.state.jackpot_slips.map((val) => (
                                <ActiveTip slip={val.slip} jackpot={val} cookies={this.props.cookies} />
                            ))}
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        );
    }
}

function JackpotTips(props) {
    return <MyGamesComponent {...props} />;
}

export default withCookies(JackpotTips);
