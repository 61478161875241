import React from 'react';

import Box from '@mui/material/Box';
import { Paper, Typography } from '@mui/material';

import { HContainer } from '../../components/TopTipsters';

export default function Error404() {
    return (
        <Box
            sx={{
                p: 1,
                m: 0,
                gap: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                minHeight: '100%'
            }}
        >
            <HContainer>
                <Paper
                    square
                    elevation={1}
                    sx={{
                        p: 1,
                        mx: 0,
                        my: 1,
                        width: '100%',
                        height: '100%',
                        borderRadius: 1
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography
                            variant="h1"
                            component="div"
                            alignSelf="center"
                        >
                            404
                        </Typography>
                        <Typography variant="h5">Page Not Found</Typography>
                    </Box>
                </Paper>
            </HContainer>
        </Box>
    );
}
