import { Container, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import Divider from '@mui/material/Divider';

export default function PageHeader({color="black", ...props}) {
    const hideDescription = props.description === null;

    const sx = Object.assign({}, props.sx, {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start'
    });

    return (
        <Box>
            <Container>
                <Box sx={sx}>
                    <Typography variant="h6" color={color} fontWeight={500}>
                        <strong>
                        {props.title}
                        </strong>
                    </Typography>
                    {hideDescription ? null : (
                        <Typography sx={{ mt: 0 }} variant="caption">
                            {props.description}
                        </Typography>
                    )}
                </Box>
            </Container>
            {!props.disableDivider ? <Divider /> : null}
        </Box>
    );
}
