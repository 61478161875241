import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ActiveTips from '../../components/profile/ActiveTips';
import PropTypes from 'prop-types';
import { HContainer } from '../../components/TopTipsters';
import JackpotTips from '../../components/JackpotTips';
import Subscriptions from '../../components/Subscriptions';
import { JokuBetsAdd } from '../../components/PartnersBanner';
import PremiumTips from '../PremiumTips';

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

export function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

export default function ActiveTipsTabs({ activeTips, activeTopTips }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ p: 0, m: 1 }}>
            <HContainer>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab label="Popular" {...a11yProps(0)} />
                            {/* <Tab label="Popular" {...a11yProps(1)} /> */}
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <ActiveTips home slips={activeTips} top={true} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {/* <ActiveTips home slips={activeTips} /> */}
                    </TabPanel>
                </Box>

                {/* <JokuBetsAdd /> */}

                <Subscriptions />

                <JackpotTips />

                

            </HContainer>
        </Box>
    );
}
