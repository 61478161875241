import React from 'react';

import { Avatar, Button, Grid, Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useNavigate, useParams } from 'react-router-dom';

import axios from '../../api/axios'

import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_KEY } from '../../data';
import { stringAvatar } from '../../routes/profile/Profile';

export function ProfileHeadNew(props) {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const navigate = useNavigate();

    const handleViewProfile = () => {
        navigate(`/profile/${props.user ? props.user.username : ''}`);
    };

    const stat2 = props.stats2 ? props.stats2 : {};

    const stat = [
        { title: 'Tips', value: props.stats ? props.stats[0]['tips'] : 0 },
        { title: 'Wins', value: props.stats ? props.stats[1]['won'] : 0 },
        { title: 'Losses', value: props.stats ? props.stats[3]['lost'] : 0 },

        props.week
            ? {
                  title: 'Week Rating',
                  value: props.stats2 ? props.stats2['week_rating'] : 0
              }
            : {
                  title: 'Rating',
                  value: props.stats ? props.stats[4]['rating'] : 0
              }
    ];

    const StatCard = ({title, value, xs}) => (
        <Grid item xs={xs ? xs : 4}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={500}
                >
                    {value}
                </Typography>
                <Typography
                    variant="caption"
                    fontWeight={500}
                >
                    {`${title}`.toUpperCase()}
                </Typography>
            </Box>
        </Grid>
    );

    const getYield = (num) => {
        if (num === undefined) return 0;
        const _val = Math.round((num + Number.EPSILON) * 100) / 100;
        const sign = _val >= 0 ? "+" : "";
        return `${sign}${_val}`;
    };


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                p: 0,
                width: '100%',
                // ...props.sx,
                '@media screen and (max-width: 600px)': {
                    width: '100%'
                }
            }}
        >
            <Paper
                variant="outlined"
                sx={{
                    p: 1,
                    mx: 0,
                    my: 1,
                    minWidth: '100%',
                    borderRadius: 1
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box
                        sx={{
                            minHeight: '100%',
                            p: 0,
                            display: 'flex',
                            gap: 1,
                            flexDirection: 'column',
                            alignItems: 'left'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-end',
                                gap: 1,
                                p: 0
                            }}
                        >
                             {props.profile &&
                            props.profile['profile_image_url'] ? (
                                <Avatar
                                    sx={{ ml: 0, p: 0 }}
                                    alt="Profile Pic"
                                    src={
                                        props.profile &&
                                        props.profile['profile_image_url']
                                            ? props.profile['profile_image_url']
                                            : null
                                    }
                                />
                            ) : (
                                <Avatar
                                    sx={{ ml: 0, p: 0 }}
                                    {...stringAvatar(
                                        props.user ? props.user.username : 'S F'
                                    )}
                                />
                            )}

                            <Typography
                                sx={{ mx: 1 }}
                                variant="subtitle1"
                                fontWeight="bold"
                            >
                                {props.user ? props.user.username : ''}
                            </Typography>
                        </Box>

                        <Divider />
                        
                        <Grid
                            sx={{ pl: 1 }}
                            container
                            rowSpacing={0}
                            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                        >
                            <StatCard title="Tips" value={stat2["tips"]} />
                           
                            <StatCard title="Wins" value={stat2["won"]} />
                        
                            <StatCard title="Lost" value={stat2["lost"]} />
                        </Grid>

                        <Divider />

                        <Grid
                            sx={{ pl: 1 }}
                            container
                            rowSpacing={0}
                            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                        >
                            <StatCard xs={5.9} title="Profit" value={props.profile ? getYield(props.profile['monthly_rating']) : ""} />
                            <Divider orientation="vertical" flexItem/>
                            <StatCard xs={5.9} title="Rating" value={`${stat2['rating']}%`} />
                        </Grid>

                        <Divider />
                    </Box>
                    
                    {!props.profileSet ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column-reverse',
                                alignItems: 'flex-start',
                                p: 1,
                                marginTop: 1,
                                height: 1,
                                width: 1
                            }}
                        >
                            <Button
                                variant="contained"
                                size="small"
                                disableElevation
                                sx={{ width: 1 }}
                                onClick={handleViewProfile}
                            >
                                View Tips
                            </Button>
                        </Box>
                    ) : null}
                </Box>
            </Paper>
        </Box>
    )
}