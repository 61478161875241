import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createTheme } from '@mui/material';
// import GTWalsheimProRegular from './assets/fonts/GTWalsheimPro-Regular.ttf';
// import GTWalsheimProBold from './assets/fonts/GTWalsheimPro-Bold.ttf';
// import GTWalsheimProMedium from './assets/fonts/GTWalsheimPro-Medium.ttf';

const GTWalsheimProRegular = "https://sokafans-static.s3.eu-central-1.amazonaws.com/fonts/GTWalsheimPro-Regular.ttf";
const GTWalsheimProBold = "https://sokafans-static.s3.eu-central-1.amazonaws.com/fonts/GTWalsheimPro-Bold.ttf";
const GTWalsheimProMedium = "https://sokafans-static.s3.eu-central-1.amazonaws.com/fonts/GTWalsheimPro-Medium.ttf";


const LinkBehavior = React.forwardRef((props, ref) => {
    const { href, ...other } = props;
    // Map href (MUI) -> to (react-router)
    return (
        <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
    );
});

LinkBehavior.propTypes = {
    href: PropTypes.oneOfType([
        PropTypes.shape({
            hash: PropTypes.string,
            pathname: PropTypes.string,
            search: PropTypes.string
        }),
        PropTypes.string
    ]).isRequired
};

export const theme = createTheme({
    palette: {
        primary: {
            main: '#388E3C'
        },
        secondary: {
            main: '#57C5B6'
        }
    },
    typography: {
        fontFamily: 'GT Walsheim Pro'
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
      @font-face {
          font-family: 'GT Walsheim Pro';
          src: url('GTWalsheimPro-Regular.eot');
          src: local('GT Walsheim Pro Regular'), local('GTWalsheimPro-Regular'),
              url('${GTWalsheimProRegular}') format('truetype');
          font-weight: normal;
          font-style: normal;
      }
      
      @font-face {
          font-family: 'GT Walsheim Pro';
          src: url('GTWalsheimPro-Bold.eot');
          src: local('GT Walsheim Pro Bold'), local('GTWalsheimPro-Bold'),
              url('${GTWalsheimProBold}') format('truetype');
          font-weight: bold;
          font-style: normal;
      }
      
      @font-face {
        font-family: 'GT Walsheim Pro';
        src: url('GTWalsheimPro-Medium.eot');
        src: local('GT Walsheim Pro Medium'), local('GTWalsheimPro-Medium'),
            url('${GTWalsheimProMedium}') format('truetype');
        font-weight: 500;
        font-style: normal;
      }
      `
        },

        MuiLink: {
            defaultProps: {
                component: LinkBehavior
            }
        },

        MuiButtonBase: {
            defaultProps: {
                LinkComponent: LinkBehavior
            }
        },

        MuiButton: {
            defaultProps: {
                size: 'small'
            }
        }
    }
});
