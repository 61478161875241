import React from 'react';

import { Box, Grid, Typography, Paper } from '@mui/material';
import { useParams } from 'react-router';

import { useCookies, withCookies } from 'react-cookie';

import { JackpotActiveTip } from '../components/JackpotTips';
import axios from '../api/axios';
import { Helmet } from 'react-helmet';
import { HContainer } from '../components/TopTipsters';
import PageHeader from '../components/PageHeader';

const Jackpot = (props) => {
    const params = useParams();
    const [tips, setTips] = React.useState([]);
    const [title, setTitle] = React.useState("");
    const [description, setDescription] = React.useState("Sokafans Jackpot Predictions. Get Free Jackpot Predictions");
    const [info, setInfo] = React.useState(null);

    const parseJackpotName = (jackpotName) => {
        if (!jackpotName) {
            setTitle("Jackpot Predictions");
            return {none: true};
        }
        const arr = jackpotName.split("-");
        if (!(3 <= arr.length && arr.length <= 4)) {
            setTitle("Jackpot Predictions");
            return {none: true};
        }
        const result = {};
        result["company"] = arr[0] === "sportpesa" ? "SP" : "BT";
        result["type"] = arr[1] === "mega" ? "MG" : "MW";

        let title = "";
        for (let i = 0; i < arr.length; i++)
            title += arr[i][0].toUpperCase() + arr[i].slice(1) + " ";
        title += "Predictions";
        setDescription(prev => prev + " " + title);
        setTitle(title.trim());

        return result;
    };

    const handleLoadTips = () => {
        const config = {
            method: 'GET',
            url: '/api/jackpot/get-all-jackpots',
        };

        axios.request(config).then((response) => {
            const data = response.data["data"];
            if (info["none"]) {
                setTips(data);
                return;
            }
            for (let i = 0; i < data.length; i++) {
                const tip = data[i];
                if (tip["company"] === info["company"] && tip["type"] === info["type"]) {
                    setTips(prev => [...prev, tip]);
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    React.useEffect(() => {
        setInfo(parseJackpotName(params.jackpot));
    }, []);

    React.useEffect(() => {
        if (title && info) {
            console.log("Info set", info);
            handleLoadTips();
        }
    }, [title]);

    return (
        <HContainer>
            <Helmet>
                <title>Sokafans - {title} </title>
                <meta name="description" content={description} />
                
            </Helmet>
            <Paper 
                square 
                elevation={1}
                sx={{
                    py: 1,
                    mx: 0,
                    my: 1,
                    width: "100%",
                    height: "100%",
                    borderRadius: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                }}
            >
                <PageHeader sx={{ pl: 0 }} title={title} desciption="Jackpot page" />

                <Grid
                    container
                    spacing={0}
                    sx={{ m: 0, p: 0, maxWidth: "100%" }}
                >
                    {
                        tips.map((val) => (
                            <JackpotActiveTip slip={val.slip} jackpot={val} cookies={props.cookies} limit={50} />
                        ))
                    }
                </Grid>
            </Paper>
        </HContainer>
    )
};

export default withCookies(Jackpot);