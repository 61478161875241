import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import {
    Avatar,
    Button,
    Chip,
    CircularProgress,
    Link,
    Paper
} from '@mui/material';
import Box from '@mui/material/Box';

import { HContainer } from '../../components/TopTipsters';
import PageHeader from '../../components/PageHeader';
import { StyledDataGrid } from '../../components/data_lists/DataListComponents';
import axios from '../../api/axios';
import BreadCrumbView from '../../components/app_bar/BreadCrumbView';
import { stringAvatarSx } from '../profile/Profile';
import { GameDialog } from './ActiveTipsList';

function createData(data, id, navigate, viewTip) {
    const slip = data.slip;
    const user = data.user;
    const meta = data.meta;

    console.log(data);

    return {
        id: id,
        slip_id: slip['id'],
        navigate: navigate,
        date: slip['date_created'],
        username: user['username'],
        profile_pic: meta['profile'],
        rating: meta['rating'],
        games: data.tips,
        price: 50,
        viewTip: viewTip,
        result: slip['slip_result']
    };
}

const getProfilePic = (profile_url, username) =>
    profile_url ? (
        <Avatar
            sx={{ ml: 0, p: 0, width: 24, height: 24 }}
            alt="Profile Pic"
            src={profile_url}
        />
    ) : (
        <Avatar
            {...stringAvatarSx({
                name: username,
                sx: {
                    ml: 0,
                    p: 0,
                    width: 24,
                    height: 24,
                    fontSize: 'smaller',
                    fontWeight: 500
                }
            })}
        />
    );

const dataColumns = [
    { field: 'id', headerName: 'ID', width: 10 },
    {
        field: 'profile_pic',
        headerName: 'PC',
        minWidth: 35,
        flex: 1,
        renderCell: (params) =>
            getProfilePic(params.row.profile_pic, params.row.username)
    },
    {
        field: 'username',
        headerName: 'Tipster',
        minWidth: 150,
        flex: 1,
        editable: false,
        renderCell: (params) => (
            <Link
                fontWeight="bold"
                fontFamily="GT Walsheim Pro"
                fontSize="small"
                underline="hover"
                style={{ color: 'black' }}
                to={`/profile/${params.row.username}`}
            >
                {params.row.username}
            </Link>
        )
    },
    {
        field: 'rating',
        headerName: 'Rating',
        type: 'number',
        minWidth: 70,
        flex: 1,
        editable: false,
        sortable: true
    },
    {
        field: 'date',
        headerName: 'Date',
        minWidth: 110,
        flex: 1,
        type: 'date',
        editable: false,
        sortable: true
    },
    {
        field: 'result',
        headerName: 'Result',
        minWidth: 75,
        flex: 1,
        renderCell: (params) => {
            if (params.row.result === 'W')
                return <Chip label="won" color="success" />;
            if (params.row.result === 'L')
                return <Chip label="lost" color="error" />;
            return <Chip label="cancel" />;
        }
    },
    {
        field: 'games',
        headerName: 'Tip',
        minWidth: 75,
        flex: 1,
        renderCell: (params) => (
            <Button
                variant="contained"
                disableElevation
                size="small"
                value={params.row.slip_id}
                onClick={params.row.viewTip}
            >
                View
            </Button>
        )
    }
];

class FinishedTipsListComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            finishedTips: [],
            open: false,
            tips: [],
            markets: []
        };

        this.handleLoadFinishedTips = this.handleLoadFinishedTips.bind(this);
        this.handleViewTip = this.handleViewTip.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleLoadMarkets = this.handleLoadMarkets.bind(this);
    }

    componentDidMount() {
        this.handleLoadFinishedTips();
        this.handleLoadMarkets();
    }

    handleLoadMarkets() {
        const options = {
            method: 'GET',
            url: '/api/slip/markets'
        };

        axios
            .request(options)
            .then((response) => {
                const markets = response.data['markets'];
                this.setState({
                    markets: markets
                });
            })
            .catch((error) => {
                if (error) console.log(error.response);
            });
    }

    handleLoadFinishedTips() {
        const { navigate, params } = this.props;
        const username = params.username;

        const options = {
            method: 'GET',
            url: '/api/home/finished'
        };

        const get = (_list) => {
            if (_list) return _list;
            return [];
        };

        axios
            .request(options)
            .then((response) => {
                const data = response.data;
                const slips = data.slips;
                let cnt = 1;
                for (let i = 0; i < slips.length; i++) {
                    const slip = slips[i];
                    if (username) {
                        if (slip.user['username'] !== username) continue;
                    }
                    this.setState((prevState) => ({
                        finishedTips: [
                            ...get(prevState.finishedTips),
                            createData(
                                slip,
                                cnt++,
                                navigate,
                                this.handleViewTip
                            )
                        ]
                    }));
                }
            })
            .catch((error) => {
                if (error) console.log('Error', error.response.data);
            });
    }

    handleCloseModal() {
        this.setState({
            open: false,
            tips: null
        });
    }

    handleViewTip(e) {
        const slip_id = e.target.value;
        let tips = null;
        if (this.state.finishedTips)
            tips = this.state.finishedTips.find(
                (tip) => tip.slip_id === parseInt(slip_id)
            );

        if (!tips) return;

        console.log('Tip View');
        console.log(tips);

        this.setState({
            open: true,
            tips: tips.games
        });
    }

    render() {
        return (
            <Box
                sx={{
                    p: 0,
                    m: 0,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Box
                    sx={{
                        p: 1,
                        m: 0,
                        gap: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    <BreadCrumbView sx={1} />
                    <GameDialog
                        openDialoag={this.state.open}
                        handleClose={this.handleCloseModal}
                        tips={this.state.tips}
                        markets={this.state.markets}
                    />
                    <HContainer>
                        <Paper
                            square
                            elevation={1}
                            sx={{
                                p: 1,
                                mx: 0,
                                my: 1,
                                width: '100%',
                                height: '100%',
                                borderRadius: 1
                            }}
                        >
                            <PageHeader
                                sx={{ pl: 2 }}
                                title="Finished Tips"
                                description="Historical data"
                                disableDivider
                            />

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minHeight: 500
                                }}
                            >
                                {this.state.finishedTips.length === 0 ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '100%',
                                            height: 450,
                                            m: 0
                                        }}
                                    >
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <StyledDataGrid
                                        rows={this.state.finishedTips}
                                        columns={dataColumns}
                                        pageSize={100}
                                        rowsPerPageOptions={[100]}
                                        disableSelectionOnClick
                                    />
                                )}
                            </Box>
                        </Paper>
                    </HContainer>
                </Box>
            </Box>
        );
    }
}

function FinishedTipsList(props) {
    const navigate = useNavigate();
    const params = useParams();

    return (
        <FinishedTipsListComponent
            {...props}
            navigate={navigate}
            params={params}
        />
    );
}

export default withCookies(FinishedTipsList);
