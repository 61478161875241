import React from 'react';

import { alpha, Button, Box, Menu, MenuItem, styled } from '@mui/material';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useNavigate } from 'react-router-dom';
import { blueGradientButton } from '../customStyles';

// noinspection JSValidateTypes
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light'
                ? 'rgb(55, 65, 81)'
                : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                fontFamily: 'GT Walsheim Pro',
                fontWeight: 'bold',
                //color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                )
            }
        }
    }
}));

export default function ProfileButton(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const navigate = useNavigate();

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogoutBtn = () => {
        handleClose();
        props.handleLogout();
    };

    const handleProfileBtn = () => {
        handleClose();
        navigate('/profile');
    };

    const handleSettingBtn = () => {
        handleClose();
        navigate('/account_settings');
    };

    const handleDashboardBtn = () => {
        handleClose();
        navigate('/dashboard');
    };

    return (
        <Box
            sx={{
                maxWidth: 250,
                ml: 1,
                display: 'flex',
                flexDirection: 'row',
                gap: 1
            }}
        >
            <Button
                style={{ ...blueGradientButton }}
                variant="contained"
                onClick={handleDashboardBtn}
            >
                Dashboard
            </Button>

            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                style={{ background: '#211b36' }}
                onClick={handleClick}
                startIcon={<PersonIcon />}
                endIcon={<KeyboardArrowDownIcon />}
            />

            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleProfileBtn} disableRipple>
                    <AccountBoxIcon />
                    Profile
                </MenuItem>

                <MenuItem onClick={handleSettingBtn} disableRipple>
                    <SettingsIcon />
                    Settings
                </MenuItem>

                <Divider sx={{ my: 0.5 }} />

                <MenuItem onClick={handleLogoutBtn} disableRipple>
                    <LogoutIcon />
                    Logout
                </MenuItem>
            </StyledMenu>
        </Box>
    );
}
