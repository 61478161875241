import React from 'react';

import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    Link,
    List,
    ListItem,
    ListItemText,
    OutlinedInput,
    Paper,
    Skeleton,
    Typography
} from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

import Box from '@mui/material/Box';
import PageHeader from '../components/PageHeader';
import { HContainer } from '../components/TopTipsters';
import axios from '../api/axios';
import { LoadingButton } from '@mui/lab';
import Title from '../components/Title';
import { stringAvatar } from './profile/Profile';
import { KeyboardReturnOutlined } from '@mui/icons-material';

function ActiveTipComponentWithResult(props) {
    const tips = props.data;

    const tipCount = [];
    for (let i = 0; i < tips.length; i++) {
        const tip = tips[i];
        tipCount.push(
            <ListItem key={i} disablePadding divider>
                <ListItemText primary={`${tip['Game']} | ${tip['League']}`} secondary={tip['Tip']} />
            </ListItem>
        );
    }

    return (
        <Grid item sx={{ m: 0, p: 0 }}>
            <Card
                elevation={0}
                variant="outlined"
                sx={{
                    m: 0,
                    minWidth: '100%',
                    maxWidth: '100%',
                    width: 270
                }}
            >
                <CardContent sx={{ maxWidth: '100%', minHeight: 200 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            p: 0
                        }}
                    >
                        <List sx={{ width: '100%' }}>{tipCount}</List>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    );
}

export default function CustomerBoughtTips(props) {
    const query = useSearchParams();
    return <CustomerBoughtTipsComponent {...props} query={query} />;
}

class CustomerBoughtTipsComponent extends React.Component {
    constructor(props) {
        super(props);

        const [query, _] = props.query;

        this.state = {
            phoneNumber: null,
            phoneError: null,

            mpesaCode: query.get('tx_ref'),
            mpesaError: null,

            loading: false,

            visible: false,

            tips: []
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.handleMpesaTransactionChange = this.handleMpesaTransactionChange.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({
            phoneError: null,
            mpesaError: null,
            loading: true,
            visible: false
        });

        const phone = this.state.phoneNumber;
        const mpesa = this.state.mpesaCode;

        const valid_email = (email) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);

        if (phone == null || !valid_email(phone)) {
            this.setState({
                phoneError: 'Invalid Email',
                loading: false
            });
            return;
        }

        if (mpesa == null || mpesa.length < 3) {
            this.setState({
                mpesaError: 'Invalid transaction id',
                loading: false
            });
            return;
        }

        const mpesa_code = btoa(mpesa.trim().split(' ')[0]);

        const getPhoneNumber = (phone) => {
            return phone;
        };

        var config = {
            method: 'post',
            url: `/api/manage/find-slip-ng/${getPhoneNumber(phone)}/${mpesa_code.trim()}`
        };

        axios(config)
            .then((response) => {
                const data = response.data;
                this.setState({
                    tips: data.tip,
                    visible: true
                });
            })
            .catch(function (error) {
                const response = error.response;
                if (response.data.message) {
                    alert(response.data.message);
                }

                console.log(error);
            })
            .finally(() => {
                this.setState({
                    loading: false
                });
            });
    }

    handlePhoneNumberChange(e) {
        const phone = e.target.value;
        this.setState({
            phoneNumber: phone
        });
    }

    handleMpesaTransactionChange(e) {
        const mpesa = e.target.value;
        this.setState({
            mpesaCode: mpesa
        });
    }

    render() {
        return (
            <Box>
                <Title title={`JokuBets| Find My Tip`} />
                <HContainer>
                    <PageHeader
                        sx={{
                            pl: 0
                        }}
                        title="Find My Tip"
                        description="Enter your details to get your recently bought tip"
                    />
                    <Box
                        sx={{
                            p: 1,
                            m: 1,
                            display: 'flex',
                            gap: 1,
                            flexDirection: 'column',
                            maxWidth: 600
                        }}
                    >
                        <Box sx={{ my: 1 }}>
                            <Paper
                                square
                                elevation={1}
                                sx={{
                                    p: 1,
                                    mx: 0,
                                    my: 1,
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: 1
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1
                                    }}
                                >
                                    <Typography>Enter Transaction Details</Typography>

                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-amount">Email</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            error={this.state.phoneError}
                                            helperText={this.state.phoneError}
                                            value={this.state.phoneNumber}
                                            onChange={this.handlePhoneNumberChange}
                                            startAdornment={<EmailOutlinedIcon />}
                                            label="Email"
                                        />
                                        <FormHelperText error>{this.state.phoneError}</FormHelperText>
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-amount">
                                            Flutterwave Transaction ID
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            error={this.state.mpesaError}
                                            helperText={this.state.mpesaError}
                                            value={this.state.mpesaCode}
                                            onChange={this.handleMpesaTransactionChange}
                                            label="Flutterwave Transaction ID"
                                        />
                                        <FormHelperText error>{this.state.mpesaError}</FormHelperText>
                                    </FormControl>

                                    <LoadingButton
                                        variant="contained"
                                        disableElevation
                                        disabled={process.env.NODE_ENV === 'production' && false}
                                        loading={this.state.loading}
                                        onClick={this.handleSubmit}
                                    >
                                        Find
                                    </LoadingButton>

                                    <Box>
                                        <Alert severity="info">
                                            <AlertTitle>Info</AlertTitle>
                                            Enter the email address that you used when buying the tip, the email address
                                            which Flutterwave sent a receipt to. The{' '}
                                            <strong>Flutterwave Transaction ID</strong> is in the receipt. Check your{' '}
                                            <strong>spam</strong> box incase you can't find the Flutterwave receipt in
                                            your inbox.
                                        </Alert>
                                    </Box>
                                </Box>
                            </Paper>
                        </Box>

                        <Paper
                            square
                            elevation={1}
                            sx={{
                                p: 1,
                                mx: 0,
                                my: 1,
                                width: '100%',
                                height: '100%',
                                borderRadius: 1
                            }}
                        >
                            {this.state.visible &&
                                (this.state.tips ? (
                                    <ActiveTipComponentWithResult data={this.state.tips} />
                                ) : (
                                    <Box
                                        sx={{
                                            height: 200,
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 1
                                        }}
                                    >
                                        <Skeleton />
                                        <Skeleton />
                                        <Skeleton sx={{ height: 100 }} />
                                    </Box>
                                ))}
                        </Paper>
                    </Box>
                </HContainer>
            </Box>
        );
    }
}
