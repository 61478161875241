import React from 'react';

import Box from '@mui/material/Box';
import {
    Card,
    CardActions,
    CardContent,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    Modal,
    OutlinedInput,
    Paper,
    Typography
} from '@mui/material';
import PageHeader from './PageHeader';
import { HContainer } from './TopTipsters';
import { ArrowRight } from '@mui/icons-material';
import Register from '../routes/auth/Register';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import axios from '../api/axios';

class SubscribeComp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phoneError: '',
            phoneNumber: '',
            textOrient: 'center',

            loading: false
        };

        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.handleBuySubscription = this.handleBuySubscription.bind(this);
        this.handleUpdateScreen = this.handleUpdateScreen.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleUpdateScreen);

        this.handleUpdateScreen();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleUpdateScreen);
    }

    handleUpdateScreen() {
        this.setState({
            textOrient: window.innerWidth >= 720 ? 'center' : 'left'
        });
    }

    handlePhoneNumberChange(e) {
        const phone = e.target.value;
        this.setState({
            phoneNumber: phone
        });
    }

    handleSubmit() {}

    handleBuySubscription() {
        this.setState({
            phoneError: null,
            loading: true
        });

        const { cookies } = this.props;

        const token = cookies.get('token');

        const phone = this.state.phoneNumber;
        if (phone == null || !(phone.length === 10 || phone.length === 9)) {
            this.setState({
                phoneError: 'Enter Valid Phone Number',
                loading: false
            });
            return;
        }

        const getPhoneNumber = (phone) => {
            if (phone.length === 10) return `254${phone.substring(1)}`;
            return `254${phone}`;
        };

        const options = {
            method: 'POST',
            url: `/api/processor/subscribe/${getPhoneNumber(phone)}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        axios
            .request(options)
            .then((res) => {
                alert('Request Sent. Check your phone.');
            })
            .catch((error) => {
                alert('An error occured');
            })
            .finally(() => {
                this.setState({
                    loading: false
                });
            });
    }

    render() {
        const subscriptionInfo = [
            'Make up to KES 3000 daily selling betting tips.',
            'For a one-time payment of KES 1300, start making a passive income.',
            'One Time Subscription Ksh. 1300 Only',
            'PAY NOW then check your mobile phone handset for instant payment' +
                ' request from Safaricom MPESA.'
        ];

        const MyBanner = (bannerInfo) => (
            <Paper
                square
                elevation={1}
                style={{ borderRadius: 0 }}
                sx={{
                    p: 0,
                    m: 0,
                    width: '100%',
                    maxHeight: '100%',
                    // height: 250,
                    borderRadius: 0,
                    backgroundImage:
                        'url(https://images.pexels.com/photos/47730/the-ball-stadion-football-the-pitch-47730.jpeg)',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        px: 2,
                        pt: 2,
                        pb: 0
                    }}
                    style={{
                        color: 'whitesmoke',
                        backgroundColor: 'rgb(6, 22, 61, 0.7)'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Typography
                            sx={{ pl: 1, alignSelf: 'center' }}
                            color="whitesmoke"
                            variant="h5"
                            component="div"
                            gutterBottom
                            fontWeight={500}
                        >
                            Be A Tipster
                        </Typography>

                        {bannerInfo.map((text) => (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'left',
                                    alignSelf: this.state.textOrient
                                }}
                            >
                                <ArrowRight />
                                <Typography variant="subtitle2">
                                    {text}
                                </Typography>
                            </Box>
                        ))}
                    </Box>

                    <Box
                        sx={{
                            width: 500,
                            maxWidth: '100%',
                            alignSelf: 'center',
                            m: 1
                        }}
                    >
                        <Card
                            elevation={0}
                            variant="outlined"
                            sx={{
                                m: 0,
                                minWidth: '100%',
                                maxWidth: '100%'
                            }}
                        >
                            <CardContent sx={{ width: '100%' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        p: 0
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            p: 0,
                                            m: 0,
                                            gap: 1
                                        }}
                                    >
                                        <Typography>
                                            One Time Subscription KES. 1300 ONLY
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                            <CardActions sx={{ width: '100%' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1,
                                        m: 1,
                                        width: '100%'
                                    }}
                                >
                                    <Typography>Enter Phone Details</Typography>

                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-amount">
                                            Phone Number
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            error={this.state.phoneError}
                                            helperText={this.state.phoneError}
                                            value={this.state.phoneNumber}
                                            onChange={
                                                this.handlePhoneNumberChange
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    +254
                                                </InputAdornment>
                                            }
                                            label="Mpesa Phone Number"
                                        />
                                        <FormHelperText error>
                                            {this.state.phoneError}
                                        </FormHelperText>
                                    </FormControl>

                                    <LoadingButton
                                        variant="contained"
                                        disableElevation
                                        disabled={false}
                                        loading={this.state.loading}
                                        onClick={this.handleBuySubscription}
                                    >
                                        PAY NOW
                                    </LoadingButton>

                                    <Alert severity="info">
                                        <AlertTitle>Info</AlertTitle>
                                        Incase of complications, contact support
                                        .
                                    </Alert>
                                </Box>
                            </CardActions>
                        </Card>
                    </Box>
                </Box>
            </Paper>
        );

        return (
            <Box
                sx={{
                    marginBottom: 10
                }}
            >
                <HContainer>{MyBanner(subscriptionInfo)}</HContainer>
            </Box>
        );
    }
}

export default function Subscribe(props) {
    return <SubscribeComp {...props} />;
}
