import TopTipsters from '../components/TopTipsters';
import ActiveTips from '../components/profile/ActiveTips';

import * as React from 'react';
import Box from '@mui/material/Box';
import LatestFinishedTips from '../components/profile/LatestFinishedTips';
import HomeBanner from '../components/HomeBanner';
import axios from '../api/axios';
import UpcomingMatches from '../components/UpcomingMatches';
import Title from '../components/Title';
import ActiveTipsTabs from './component/ActiveTipsTabs';
import TopTipstersTab from './component/TopTipstersTab';
import JackpotTips from '../components/JackpotTips';
import FreeTips from '../components/FreeTips';
import PartnersBanner, { JokuBetsAdd } from '../components/PartnersBanner';
import Subscriptions from '../components/Subscriptions';
import PremiumTips from './PremiumTips';
import News from './News';

// import { Analytics } from '@vercel/analytics/react';

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTips: null,
            activeTopTips: null,
            markets: null,
            finishedSlips: null,

            tipsters: [],
            topTipsters: null,
            loadTipsters: false,
            nextTipsters: null,

            jackpots: null,

            loadFinished: false,

            next: null,
        };

        this.handleLoadActiveTips = this.handleLoadActiveTips.bind(this);
        this.handleLoadFinishedTips = this.handleLoadFinishedTips.bind(this);
        this.handleLoadMarkets = this.handleLoadMarkets.bind(this);
        this.handleLoadTopProfiles = this.handleLoadTopProfiles.bind(this);
        this.handleLoadTopProfilesMore = this.handleLoadTopProfilesMore.bind(this);
        this.handleLoadActiveTopTips = this.handleLoadActiveTopTips.bind(this);
        this.handleLoadJackpotTips = this.handleLoadJackpotTips.bind(this);
        this.handleLoadFinishedTipsMore = this.handleLoadFinishedTipsMore.bind(this);
    }

    handleLoadTopProfiles() {
        const options1 = {
            method: 'GET',
            url: '/v2/api/tipster/data/?all=1'
        };

        this.setState({ loadTipsters: true });


        axios
            .request(options1)
            .then((response) => {
                const tipsters = response.data['results'];
                const next = response.data['next'];
                // console.log(tipsters);
                this.setState({
                    topTipsters: tipsters,
                    nextTipsters: next
                });
            })
            .catch(function (error) {
                console.error(error);
            }).finally(() => {
                this.setState({ loadTipsters: false });
            })
    }

    handleLoadTopProfilesMore() {
        const next = this.state.nextTipsters;
        if (next === null) {
            return;
        }

        if (this.state.loadTipsters) {
            return;
        }

        const url = new URL(next);

        const options1 = {
            method: 'GET',
            url: url.pathname + url.search
        };

        console.log(options1);

        this.setState({ loadTipsters: true });
        axios
            .request(options1)
            .then((response) => {
                const tipsters = response.data['results'];
                const next = response.data['next'];
                console.log(tipsters);
                this.setState(prev => ({
                    topTipsters:[...prev.topTipsters, ...tipsters],
                    nextTipsters: next
                }));
            })
            .catch(function (error) {
                console.error(error);
            }).finally(() => {
                this.setState({ loadTipsters: false });
            })
    }

    handleLoadActiveTips() {
        const options = {
            method: 'GET',
            url: '/v2/api/slip/data/?p=1'
        };

        axios
            .request(options)
            .then((response) => {
                const data = response.data;
                this.setState({
                    activeTips: data.results
                });
            })
            .catch((error) => {
                if (error) console.error(error.response.data);
            });
    }

    handleLoadActiveTopTips() {
        const options = {
            method: 'GET',
            url: '/v2/api/slip/data/?home=1'
        };

        axios
            .request(options)
            .then((response) => {
                const data = response.data;
                this.setState({
                    activeTopTips: data.results
                });
            })
            .catch((error) => {
                if (error) console.error(error.response.data);
            });
    }

    handleLoadFinishedTips() {
        const options = {
            method: 'GET',
            url: '/v2/api/slip/data/finished/'
        };

        this.setState({ loadFinished: true });


        axios
            .request(options)
            .then((response) => {
                const data = response.data;
                const results = data["results"];
                const next = data["next"];

                this.setState({
                    finishedSlips: results,
                    next: next
                });
            })
            .catch((error) => {
                if (error) console.log('Error', error.response.data);
            }).finally(() => {
                this.setState({
                    loadFinished: false
                });
            });
    }

    handleLoadFinishedTipsMore() {
        if (this.state.loadFinished) {
            return;
        }

        if (this.state.next === null) {
            return;
        }
        const url = new URL(this.state.next);

        const options = {
            method: 'GET',
            url: url.pathname + url.search
        };

        this.setState({
            loadFinished: true
        });

        axios
            .request(options)
            .then((response) => {
                const data = response.data;
                const results = data["results"];
                const next = data["next"];

                this.setState(prev => ({
                    finishedSlips: [...prev.finishedSlips, ...results],
                    next: next
                }));
            })
            .catch((error) => {
                if (error) console.log('Error', error.response.data);
            }).finally(() => {
                this.setState({
                    loadFinished: false
                });
            });
    }

    handleLoadMarkets() {
        const options = {
            method: 'GET',
            url: '/api/slip/markets'
        };

        axios
            .request(options)
            .then((response) => {
                const markets = response.data['markets'];
                this.setState({
                    markets: markets
                });
            })
            .catch((error) => {
                if (error) console.log(error.response);
            });
    }

    handleLoadJackpotTips() {
        const config = {
            method: 'GET',
            url: '/api/jackpot/get-all-jackpots'
        };

       
        axios.request(config).then((res) => {
            this.setState({
                jackpots: res.data["data"]
            });
            console.log("Loaded jackpots");
        }).catch((err) => {
            console.log("Can't load jackpot");
            console.log(err);
        })
    }


    componentDidMount() {
        this.handleLoadActiveTips();
        this.handleLoadFinishedTips();
        // this.handleLoadMarkets();
        // this.handleLoadTopProfiles();
        // this.handleLoadActiveTopTips();
        this.handleLoadJackpotTips();
    }

    render() {
        const { activeTopTips, activeTips } = this.state;

        return (
            <Box
                sx={{
                    p: 0,
                    m: 0,
                    display: 'flex',
                    flexDirection: 'column'
                }}
                style={{ backgroundColor: '#dedede' }}
            >
                <Title title="FutaaPredict - The Best Football Tips and Jackpot Predictions" />

                <HomeBanner />      

                <UpcomingMatches />

                <FreeTips />

                <ActiveTipsTabs
                    activeTopTips={activeTopTips}
                    activeTips={activeTips}
                />

                {/* <PremiumTips /> */}

                {/* <TopTipsters
                    home
                    tipsters={this.state.topTipsters}
                    loading={this.state.loadTipsters}
                    onSeeMore={this.handleLoadTopProfilesMore}
                    next={this.state.nextTipsters}
                />  */}
                
                {/* <LatestFinishedTips
                    slips={this.state.finishedSlips}
                    markets={this.state.markets}
                    next={this.state.next}
                    seeMore={this.handleLoadFinishedTipsMore}
                    loading={this.state.loadFinished}
                /> */}

                <News />
                {/* <PartnersBanner joku /> */}
            </Box>
        );
    }
}

export default Home;
