import React from 'react';

import { Avatar, Button, Grid, Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import ActiveTips from '../../components/profile/ActiveTips';
import PageHeader from '../../components/PageHeader';
import LatestFinishedTips from '../../components/profile/LatestFinishedTips';
import { withCookies } from 'react-cookie';
import axios from '../../api/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { HContainer } from '../../components/TopTipsters';
import BreadCrumbView from '../../components/app_bar/BreadCrumbView';

import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_KEY } from '../../data';
import Title from '../../components/Title';
import Subscribe from '../../components/Subscribe';
import { ProfileHeadNew } from '../../components/profile/ProfileHeadNew';

ReactGA.initialize(GOOGLE_ANALYTICS_KEY);

function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; string && i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatarSx({ name, sx }) {
    return {
        sx: {
            bgcolor: stringToColor(name),
            ...sx
        },
        children: `${name.split(' ')[0][0]}${
            name.split(' ').length > 1 ? name.split(' ')[1][0] : ''
        }`
    };
}

export function stringAvatar(m_name) {
    const name = m_name ? m_name : "_";

    return {
        sx: {
            bgcolor: stringToColor(name)
        },
        children: `${name.split(' ')[0][0]}${
            name.split(' ').length > 1 ? name.split(' ')[1][0] : ''
        }`
    };
}

export function ProfileHead(props) {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const navigate = useNavigate();

    const handleViewProfile = () => {
        navigate(`/profile/${props.user ? props.user.username : ''}`);
    };

    // console.log(props);

    const subscription = props.profile
        ? props.profile.subscribed
            ? 'Active Subscription'
            : 'Not Subscribed'
        : '';

    const stat = [
        { title: 'Tips', value: props.stats ? props.stats[0]['tips'] : 0 },
        { title: 'Wins', value: props.stats ? props.stats[1]['won'] : 0 },
        { title: 'Losses', value: props.stats ? props.stats[3]['lost'] : 0 },

        props.week
            ? {
                  title: 'Week Rating',
                  value: props.stats2 ? props.stats2['week_rating'] : 0
              }
            : {
                  title: 'Rating',
                  value: props.stats ? props.stats[4]['rating'] : 0
              }
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                p: 0,
                width: '100%',
                // ...props.sx,
                '@media screen and (max-width: 600px)': {
                    width: '100%'
                }
            }}
        >
            <Paper
                variant="outlined"
                sx={{
                    p: 1,
                    mx: 0,
                    my: 1,
                    minWidth: '100%',
                    borderRadius: 1
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box
                        sx={{
                            minHeight: '100%',
                            p: 0,
                            display: 'flex',
                            gap: 1,
                            flexDirection: 'column',
                            alignItems: 'left'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-end',
                                gap: 1,
                                p: 0
                            }}
                        >
                            {props.profile &&
                            props.profile['profile_image_url'] ? (
                                <Avatar
                                    sx={{ ml: 0, p: 0 }}
                                    alt="Profile Pic"
                                    src={
                                        props.profile &&
                                        props.profile['profile_image_url']
                                            ? props.profile['profile_image_url']
                                            : null
                                    }
                                />
                            ) : (
                                <Avatar
                                    {...stringAvatar(
                                        props.user ? props.user.username : 'S F'
                                    )}
                                />
                            )}

                            <Typography
                                sx={{ mx: 1 }}
                                variant="subtitle1"
                                fontWeight="bold"
                            >
                                {props.user ? props.user.username : ''}
                            </Typography>

                            {props.profileSet && (
                                <Typography
                                    sx={{
                                        mx: 1
                                    }}
                                    variant="caption"
                                    fontWeight="bold"
                                    fontStyle="italics"
                                >
                                    {subscription}
                                </Typography>
                            )}
                        </Box>

                        <Divider />

                        <Grid
                            sx={{ pl: 1 }}
                            container
                            rowSpacing={0}
                            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                        >
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight={500}
                                >
                                    {stat[0].title}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {stat[0].value}
                            </Grid>

                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight={500}
                                >
                                    {stat[1].title}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {stat[1].value}
                            </Grid>

                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight={500}
                                >
                                    {stat[2].title}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {stat[2].value}
                            </Grid>

                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight={500}
                                >
                                    {stat[3].title}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle2"
                                    fontWeight={500}
                                >
                                    {stat[3].value} %
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    {!props.profileSet ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column-reverse',
                                alignItems: 'flex-start',
                                p: 1,
                                marginTop: 1,
                                height: 1,
                                width: 1
                            }}
                        >
                            <Button
                                variant="contained"
                                size="small"
                                disableElevation
                                sx={{ width: 1 }}
                                onClick={handleViewProfile}
                            >
                                View Tips
                            </Button>
                        </Box>
                    ) : null}
                </Box>
            </Paper>
        </Box>
    );
}

class ProfileComponent extends React.Component {
    constructor(props) {
        super(props);

        const username = props.params.username;

        if (!username) {
        }

        this.state = {
            username: username,
            markets: null,
            user: null,
            profile: null,
            stats: null,
            active_tips: null,
            finished_tips: null,
            activeTips: null,

            finishedTips: null,
            loadingFinished: false,
            next: null,

            subscribed: false,

            matches: window.matchMedia('(min-width: 768px)').matches,

            title: username ? `FutaaPredict | ${username}` : 'FutaaPredict | Profile',

            next: "",
            count: 0,
            num_pages: 0
        };

        this.handleInitProfile = this.handleInitProfile.bind(this);
        this.handleInitActiveTips = this.handleInitActiveTips.bind(this);
        this.handleLoadFinishedTips = this.handleLoadFinishedTips.bind(this);
        this.handleLoadFinishedTipsMore = this.handleLoadFinishedTipsMore.bind(this);
        this.handleLoadMarkets = this.handleLoadMarkets.bind(this);
    }

    componentDidMount() {
        const handler = (e) => this.setState({ matches: e.matches });
        window
            .matchMedia('(min-width: 768px)')
            .addEventListener('change', handler);

        this.handleLoadMarkets().then(() => {
            console.log('Loaded Markets');
        });

        this.handleInitProfile();
        this.handleInitActiveTips();
        this.handleLoadFinishedTips();
    }

    handleInitProfile() {
        const { cookies } = this.props;

        const options = {
            method: 'GET',
            url: '/api/account/profile',
            headers: {
                Authorization: `Bearer ${cookies.get('token')}`
            }
        };

        const userOpts = {
            method: 'GET',
            url: `/api/account/profile/${this.state.username}`
        };

        const opt = this.state.username ? userOpts : options;

        axios
            .request(opt)
            .then((response) => {
                const data = response.data;
                this.setState(
                    {
                        user: data.user,
                        username: data.user.username,
                        profile: data.profile,
                        subscribed: data.profile && data.profile.subscribed,
                        stats: data.stats,
                        stats2: data.stats2,
                        active_tips: data.active_tips,
                        // finished_tips: data.finished_tips
                    },
                    () => {
                     this.handleInitActiveTips();
                        this.handleLoadFinishedTips();
                    }
                );
            })
            .catch(function (error) {
                // console.log("Error");
            });
    }

    handleInitActiveTips() {
        const { cookies } = this.props;

        const options = {
            method: 'GET',
            url: '/v2/api/slip/data/?account=1',
            headers: {
                Authorization: `Bearer ${cookies.get('token')}`
            }
        };

        const userOpts = {
            method: 'GET',
            url: `/v2/api/slip/data/?username=${this.state.username}`
        };

        const opt = this.state.username ? userOpts : options;

        axios
            .request(opt)
            .then((response) => {
                const data = response.data;

                const results = data["results"];

                console.log(results);
                this.setState({
                    activeTips: results
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    handleLoadFinishedTips() {
        if (this.state.loadingFinished) {
            return;
        }

        this.setState({ loadingFinished: true });

        const options = {
            method: 'GET',
            url: `/v2/api/slip/data/finished/?username=${this.state.username}`
        };

        axios
            .request(options)
            .then((response) => {
                const data = response.data;
                // console.log(data);
                
                const results = data["results"];
                const next = data["next"];
                const count = data["count"];
                const num_pages = data["num_pages"];

                this.setState({
                    finishedSlips: results,
                    next: next,
                    count: count,
                    num_pages: num_pages
                });
            })
            .catch((error) => {
                if (error) console.log(error.response.data);
            }).finally(() => {
                this.setState({ loadingFinished: false });
            });
    }

    handleLoadFinishedTipsMore() {
        if (this.state.loadingFinished) {
            return;
        }

        const url = new URL(this.state.next);

        const options = {
            method: 'GET',
            url: url.pathname + url.search
        };

        // const options = {
        //     method: 'GET',
        //     url: this.state.next
        // };

        this.setState({ loadingFinished: true });

        axios
            .request(options)
            .then((response) => {
                const data = response.data;
                console.log(data);
                
                const results = data["results"];
                const next = data["next"];
                const count = data["count"];
                const num_pages = data["num_pages"];

                this.setState(prev => ({
                    finishedSlips: [...prev.finishedSlips, ...results],
                    next: next,
                    count: count,
                    num_pages: num_pages
                }));
            })
            .catch((error) => {
                if (error) console.log(error.response.data);
            }).finally(() => {
                this.setState({ loadingFinished: false });
            });
    }

    async handleLoadMarkets() {
        const options = {
            method: 'GET',
            url: '/api/slip/markets'
        };

        await axios
            .request(options)
            .then((response) => {
                const _markets = response.data['markets'];
                this.setState({
                    markets: _markets
                });
            })
            .catch((error) => {
                if (error) console.log(error.response.data);
            });
    }

    render() {
        const { profile } = this.state;
        const subscribed = profile && profile.subscribed;

        return (
            <Box>
                <Title title={this.state.title} />
                <BreadCrumbView />

                {
                    <Box>
                        <HContainer>
                            <Paper
                                square
                                elevation={1}
                                sx={{
                                    p: 1,
                                    mx: 1,
                                    my: 1,
                                    borderRadius: 1,
                                    maxWidth: 400
                                }}
                            >
                                <PageHeader
                                    sx={{ pl: 0 }}
                                    title="Profile"
                                    description="View user information"
                                />

                                <ProfileHeadNew
                                    profileSet
                                    profile={this.state.profile}
                                    user={this.state.user}
                                    stats={this.state.stats}
                                    stats2={this.state.stats2}
                                    sx={{ ml: 1 }}
                                />
                            </Paper>
                        </HContainer>

                        <ActiveTips
                            slips={this.state.activeTips}
                            user={this.state.username}
                        />

                        <LatestFinishedTips
                            slips={this.state.finishedSlips}
                            user={this.state.username}
                            markets={this.state.markets}
                            next={this.state.next}
                            seeMore={this.handleLoadFinishedTipsMore}
                            loading={this.state.loadingFinished}
                        />
                    </Box>
                }
            </Box>
        );
    }
}

function Profile(props) {
    let params = useParams();
    return <ProfileComponent {...props} params={params} />;
}

export default withCookies(Profile);
