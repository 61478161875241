import React from 'react';

import Box from '@mui/material/Box';
import { Avatar, MenuItem, Paper, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useCookies } from 'react-cookie';
import axios from '../../api/axios';

const countries = [
    { value: 'KE', label: 'Kenya' },
    { value: 'NG', label: 'Nigeria' },
    { value: 'TZ', label: 'Tanzania' }
];

const inputFontStyle = {
    fontWeight: '500',
    marginBottom: 5
};

const labelFontStyle = {
    fontWeight: '500'
};

const inputSx = {
    maxWidth: 347,
    minWidth: 300
};

export default function PersonalData(props) {
    const imageUrl = props.profile ? props.profile['profile_image_url'] : null;
    const name = props.user ? props.user['first_name'] : '';
    const username = props.user ? props.user['username'] : '';
    const defCountry = props.profile ? props.profile['country'] : '';
    const city = props.profile ? props.profile['city'] : '';

    const [cookies] = useCookies(['token']);

    const [country, setCountry] = React.useState('KE');
    const [formProfileName, setFormProfileName] = React.useState('optional');

    const [mName, setName] = React.useState(name);
    const [mCity, setCity] = React.useState(city);

    const [loading, setLoading] = React.useState(true);

    const [savingProfile, setSavingProfile] = React.useState(false);

    React.useEffect(() => {
        setLoading(props.loading);
        if (props.profile && !mName) {
            setCity(city);
            setName(name);
        }
    });

    const handleCountryChange = (e) => {
        setCountry(e.target.value);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleCityChange = (e) => {
        setCity(e.target.value);
    };

    const handleUpdate = () => {
        setLoading(true);
        props.onUpdate({
            name: mName,
            city: mCity
        });
    };

    const handleFileUpload = (e) => {
        if (!e.target.files) {
            return;
        }

        setSavingProfile(true);

        const file = e.target.files[0];
        const token = cookies.token;
        const { name } = file;

        setFormProfileName(name);

        const formData = new FormData();
        formData.append('profile_image', file);

        const options = {
            method: 'POST',
            url: '/api/account/user/avatar',
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            },
            data: formData
        };

        axios
            .request(options)
            .then((response) => {
                // console.log(response.data);
                window.location.reload();
            })
            .catch((error) => {
                console.log(error.data);
            })
            .finally(() => {
                setSavingProfile(false);
            });
    };

    const countryItems = [];

    countries.map((option) =>
        countryItems.push(
            <MenuItem
                style={{ ...inputFontStyle }}
                key={option.value}
                value={option.value}
            >
                {option.label}
            </MenuItem>
        )
    );

    return (
        <Box sx={{ p: 1, m: 1 }}>
            <Typography variant="h6" color="black" fontWeight={500}>
                Personal Info
            </Typography>
            <Box
                sx={{
                    width: 1,
                    paddingTop: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                }}
            >
                <Box
                    sx={{
                        width: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        gap: 2
                    }}
                >
                    <Paper variant="outlined">
                        <Avatar
                            src={imageUrl}
                            sx={{ m: '1px', width: 76, height: 76 }}
                            variant="rounded"
                        />
                    </Paper>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 1,
                            alignItems: 'center'
                        }}
                    >
                        <LoadingButton
                            loading={savingProfile}
                            variant="outlined"
                            component="label"
                            startIcon={<UploadFileIcon />}
                        >
                            Upload Profile
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                onChange={handleFileUpload}
                            />
                        </LoadingButton>

                        <Typography
                            component="label"
                            fontSize={10}
                            variant="subtitle2"
                        >
                            {formProfileName}
                        </Typography>
                    </Box>
                </Box>

                <Box
                    sx={{
                        width: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        gap: 2
                    }}
                >
                    <TextField
                        label="Name"
                        variant="outlined"
                        placeholder="Enter Name"
                        // defaultValue="Frank M."
                        sx={{ ...inputSx }}
                        // defaultValue={name}
                        value={mName}
                        onChange={handleNameChange}
                        size="small"
                        InputLabelProps={{ style: { ...labelFontStyle } }}
                        inputProps={{
                            style: { ...inputFontStyle }
                        }}
                    />

                    <TextField
                        label="Username"
                        variant="outlined"
                        required
                        placeholder="Enter username"
                        value={username}
                        sx={{ ...inputSx }}
                        size="small"
                        InputLabelProps={{ style: { ...labelFontStyle } }}
                        inputProps={{
                            style: { ...inputFontStyle },
                            readOnly: true
                        }}
                    />
                </Box>

                <Box
                    sx={{
                        width: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        gap: 2
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Country"
                        required
                        disabled
                        value={country}
                        defaultValue={defCountry}
                        onChange={handleCountryChange}
                        helperText="Please select your country"
                        size="small"
                        sx={{ ...inputSx }}
                        InputLabelProps={{ style: { ...labelFontStyle } }}
                        inputProps={{
                            style: { ...inputFontStyle },
                            readOnly: true
                        }}
                    >
                        {countryItems}
                    </TextField>

                    <TextField
                        label="City"
                        variant="outlined"
                        // defaultValue={city}
                        value={mCity}
                        onChange={handleCityChange}
                        placeholder="Enter City"
                        sx={{ ...inputSx }}
                        size="small"
                        InputLabelProps={{ style: { ...labelFontStyle } }}
                        inputProps={{ style: { ...inputFontStyle } }}
                    />
                </Box>
                <Box
                    sx={{
                        width: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        gap: 2
                    }}
                >
                    <LoadingButton
                        loading={loading}
                        variant="contained"
                        sx={{ w: 1 }}
                        disableElevation
                        onClick={handleUpdate}
                    >
                        Save
                    </LoadingButton>
                </Box>
            </Box>
        </Box>
    );
}
