export const blueGradientButton = {
    // "background":"linear-gradient(45deg, #4d00ec 30%, #3900b8 90%)",
    // "background":"linear-gradient(90deg,#084ce8 0,#003bb2 33.3%,#026 66.6%,#001a4d 100%)"
    color: 'white',
    background: '#084ce8'
    // "boxShadow":"0 3px 5px 2px rgba(255, 105, 135, .30)"
};

// noinspection SpellCheckingInspection
export const modalStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 448,
    bgcolor: 'background.paper',
    borderRadius: 1,
    boxShadow: 24,
    p: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
};
