import React from 'react';
import {
    Avatar,
    Button,
    Chip,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
    Paper,
    Typography
} from '@mui/material';

import Box from '@mui/material/Box';
import PageHeader from '../PageHeader';
import { HContainer } from '../TopTipsters';
import { useNavigate } from 'react-router-dom';
import { LoadingButton, Skeleton } from '@mui/lab';
import { stringAvatar } from '../../routes/profile/Profile';

function FinishedTip(props) {
    // console.log(props);
    let tip = props.tip;
    // console.log(tip);

    const [open, setOpen] = React.useState(false);

    const getMarket = (id) => {
        if (!props.markets) return null;
        let market = props.markets.filter(
            (market) => market['id'] === parseInt(id)
        );
        if (!market[0]) return null;
        return `${market[0]['type']}| ${market[0]['value']}`;
    };

    const IconChip = ({ username }) => {
        return (
            <Link to={`/profile/${username}`} underline="hover" color="black">
                <Chip
                    size="small"
                    style={{
                        cursor: 'pointer'
                    }}
                    sx={{
                        '& .MuiChip-label': {
                            fontFamily: 'GT Walsheim Pro',
                            fontWeight: '500',
                            fontSize: 'small'
                        }
                    }}
                    avatar={
                        <Avatar
                            style={{ color: 'white', fontWeight: 'bold' }}
                            {...stringAvatar(username)}
                        />
                    }
                    label={username}
                />
            </Link>
        );
    };

    const ResultChip = ({ result }) => {
        const size = 'small';
        const sx = {
            '& .MuiChip-label': {
                fontFamily: 'GT Walsheim Pro',
                fontWeight: '500',
                fontSize: 'small'
            }
        }
        const label = result === 'W' ? "won" : (result === 'L' ? "lost" : "cancelled");
        const color = result === 'W' ? "success" : (result === 'L' ? "error" : "warning");

        return <Chip sx={sx} size={size} label={label} color={color} />;
    };

    const OddChip = ({ accumulator, result }) => {
        const plus = result === 'W' ? '+' : (result === 'L' ? '-' : '');
        const color = result === 'W' ? "success" : (result === 'L' ? "error" : "warning");
        const label = `${plus}${accumulator}`;
        
        return <Chip 
                size="small" 
                variant="outlined" 
                label={label} 
                color={color}
                sx={{
                    '& .MuiChip-label': {
                        fontFamily: 'GT Walsheim Pro',
                        fontWeight: '500',
                        fontSize: 'small'
                    }
                }} />
    }

    const myDate = (_date) => {
        const date = new Date(Date.parse(_date));
        // noinspection JSCheckFunctionSignatures
        return new Intl.DateTimeFormat('en-US', {
            dateStyle: 'medium',
            timeStyle: 'short'
        }).format(date);
    };

    const GameItem = ({ _tip }) => (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: 1
            }}
        >
            <Typography variant="subtitle2">
                {`${_tip['home_team']} vs ${_tip['away_team']}`}
            </Typography>
            <Typography variant="caption">
                {`[${_tip['game_result']}] Predicted (${getMarket(
                    _tip['market_type']
                )})`}
            </Typography>
        </Box>
    );

    const tipList = [];


    for (let i = 0; tip.tips && i < tip.tips.length; i++) {
        const _tip = tip.tips[i];
        tipList.push(<GameItem _tip={_tip} />);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const OverFlowGames = () => {
        if (!tip || tip.tips.length <= 2) {
            return null;
        }

        return (
            <Link to="#" underline="none" onClick={handleOpen}>
                <Typography variant="caption">View All</Typography>
            </Link>
        );
    };

    return (
        <Paper variant="outlined" sx={{ w: '100%', p: 1 }}>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Tips</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {tipList}
                    </Box>
                </DialogContent>
            </Dialog>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box
                        sx={{
                            p: 0,
                            m: 0,
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            gap: 1
                        }}
                    >
        
                        <IconChip
                            username={tip.user['username']}
                            profile={null}
                        />
                        
                        <ResultChip result={tip['slip_result']} />

                        <OddChip result={tip['slip_result']} accumulator={tip.accumulator} />
                    
                        <OverFlowGames />

                    </Box>
                    <Typography variant="button" sx={{ p: 0, m: 0 }}>
                        {myDate(tip['date_created'])}
                    </Typography>
                </Box>

                <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column' }}>
                    {tipList.slice(0, 2)}
                </Box>
            </Box>
        </Paper>
    );
}

function LatestFinishedTips(props) {
    const navigate = useNavigate();
    const markets = props.markets;

    const handleSeeMore = () => {
        // if (props.user) {
        //     navigate(`/finished_tips/${props.user}`);
        // } else {
        //     navigate('/finished_tips');
        // }
        if (props.next) {
            props.seeMore();
        }
    };

    const state = {
        finishedTips: props.slips ? props.slips : null
    };

    const latestFinishedList = [];
    let count = 0;

    if (!state.finishedTips) {
        for (let i = 0; i < 2; i++) {
            latestFinishedList.push(
                <Grid item md={12} sm={12} lg={6}>
                    <Box sx={{ width: '100%' }}>
                        <Skeleton />
                        <Skeleton animation="wave" />
                        <Skeleton animation={false} />
                    </Box>
                </Grid>
            );
        }
    } else
        state.finishedTips.map((tip) => {
            if (props.user) {
                if (tip.user['username'] !== props.user) return;
            }
            // if (count >= 10) return;
            count += 1;
            latestFinishedList.push(
                <Grid item xs={12} md={6} sm={6} lg={6}>
                    <FinishedTip tip={tip} markets={markets} />
                </Grid>
            );
        });

    return (
        <Box sx={{ p: 0, m: 1 }}>
            <HContainer>
                <Paper
                    square
                    elevation={1}
                    sx={{
                        p: 1,
                        mx: 0,
                        my: 1,
                        width: '100%',
                        height: '100%',
                        borderRadius: 1
                    }}
                >
                    <PageHeader
                        sx={{ pl: 0 }}
                        title={
                            props.user
                                ? 'Latest Finished Tips'
                                : 'Latest Won Tips'
                        }
                        description="The most recent tips posted"
                    />

                    <Container sx={{ mt: 1 }}>
                        <Box
                            sx={{
                                p: 0,
                                m: 0,
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Grid container spacing={1}>
                                {latestFinishedList}
                            </Grid>
                            {props.next &&
                            // <Button size="small" onClick={handleSeeMore}>
                            //     See More
                            // </Button>
                            <LoadingButton size="small" onClick={handleSeeMore} loading={props.loading}>
                                See More {props.loading ? "..." : ""}
                            </LoadingButton>
                            }
                        </Box>
                    </Container>
                </Paper>
            </HContainer>
        </Box>
    );
}

export default LatestFinishedTips;
