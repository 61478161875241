import React from 'react';

import { Alert } from '@mui/material';

export default function CustomAlert(props) {
    const { alertType, info } = props;

    return (
        <Alert
            sx={{ mx: 1, py: 0 }}
            severity={alertType}
            style={{ display: info ? 'inherit' : 'none' }}
        >
            {info}
        </Alert>
    );
}
