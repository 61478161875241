import React from 'react';

import { Box, Grid, Typography, Paper, Container, Card, Divider } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

// import parse from 'html-react-parser';

import { useCookies, withCookies } from 'react-cookie';

import { PremiumActiveTip } from '../components/PremiumTipsComponent';
import axios from '../api/axios';
import { Helmet } from 'react-helmet';
import { HContainer } from '../components/TopTipsters';
import PageHeader from '../components/PageHeader';
import { requestCall, requestCallNoAccess } from '../api/request';
import Title from '../components/Title';

import ReactHtmlParser from 'react-html-parser';

const defaultNews = {
    title: '',
    slug: '',
    content: '',
    overview: '',
    date_created: new Date().toUTCString(),
    thumbnail: '',
    author: ''
};

const Blog = () => {
    // parse.
    const myDate = (_date) => {
        const date = new Date(Date.parse(_date));
        // noinspection JSCheckFunctionSignatures
        return new Intl.DateTimeFormat('en-US', {
            dateStyle: 'medium',
            timeStyle: 'short'
        }).format(date);
    };

    const params = useParams();
    const slug = params.slug;
    const [post, setPost] = React.useState(defaultNews);

    const [encoded, setEncoded] = React.useState('');
    const [pageTitle, setPageTitle] = React.useState('');

    const thumb =
        'https://images.pexels.com/photos/47730/the-ball-stadion-football-the-pitch-47730.jpeg?auto=compress&cs=tinysrgb&w=300';

    React.useEffect(() => {
        const blogUrl = new URL(`https://futaapredict.com/blog/${slug}`);
        const encodedUrl = encodeURIComponent(blogUrl);
        setEncoded(encodedUrl);

        const url = `/v2/api/manage/post/?slug=${slug}`;
        requestCallNoAccess(url)
            .then((response) => {
                const data = response.data;
                const results = data['results'];
                if (data['count'] > 0) {
                    console.log(results[0]);
                    setPost(results[0]);
                    setPageTitle(encodeURI(results[0]['title']));
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
    }, []);

    return (
        <Container>
            <Title title={`FutaaPredict - ${post['title']}`} />
            <Card sx={{ display: 'flex', flexDirection: 'column', gap: 0, my: 1, p: 0 }}>
                <Box
                    sx={{
                        maxHeight: 200,
                        minHeight: 200,
                        display: post.title ? 'flex' : 'none',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: `black url(${post.thumbnail || thumb}) no-repeat`,
                        backgroundPosition: 'center',
                        backgroundSize: '100% auto'
                    }}
                ></Box>

                <Box sx={{ display: post.title ? 'flex' : 'none', px: 1 }}>
                    <Typography variant="caption" sx={{ fontWeight: 'bolder' }}>
                        Date {': '}
                    </Typography>
                    <Typography variant="caption">{myDate(post.date_created)}</Typography>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
                    <Typography variant="h4">{post.title}</Typography>
                    <Typography variant="subtitle1">{post.overview}</Typography>
                </Box>

                <Box sx={{ display: post.title ? 'flex' : 'none', px: 1 }}>
                    <div>
                        <a href={`https://www.addtoany.com/share#url=${encoded}&title=${pageTitle}`} target="_blank">
                            <img
                                src="https://static.addtoany.com/buttons/a2a.svg"
                                width={32}
                                height={32}
                                style={{ backgroundColor: 'royalblue' }}
                            />
                        </a>
                        <a
                            href={`https://www.addtoany.com/add_to/facebook?linkurl=${encoded}&linkname=${pageTitle}`}
                            target="_blank"
                        >
                            <img
                                src="https://static.addtoany.com/buttons/facebook.svg"
                                width={32}
                                height={32}
                                style={{ backgroundColor: 'royalblue' }}
                            />
                        </a>
                        <a
                            href={`https://www.addtoany.com/add_to/whatsapp?linkurl=${encoded}&linkname=${pageTitle}`}
                            target="_blank"
                        >
                            <img
                                src="https://static.addtoany.com/buttons/whatsapp.svg"
                                width={32}
                                height={32}
                                style={{ backgroundColor: 'royalblue' }}
                            />
                        </a>
                        <a
                            href={`https://www.addtoany.com/add_to/telegram?linkurl=${encoded}&linkname=${pageTitle}`}
                            target="_blank"
                        >
                            <img
                                src="https://static.addtoany.com/buttons/telegram.svg"
                                width={32}
                                height={32}
                                style={{ backgroundColor: 'royalblue' }}
                            />
                        </a>
                        <a
                            href={`https://www.addtoany.com/add_to/x?linkurl=${encoded}&linkname=${pageTitle}`}
                            target="_blank"
                        >
                            <img
                                src="https://static.addtoany.com/buttons/x.svg"
                                width={32}
                                height={32}
                                style={{ backgroundColor: 'royalblue' }}
                            />
                        </a>
                        <a
                            href={`https://www.addtoany.com/add_to/email?linkurl=${encoded}&linkname=${pageTitle}`}
                            target="_blank"
                        >
                            <img
                                src="https://static.addtoany.com/buttons/email.svg"
                                width={32}
                                height={32}
                                style={{ backgroundColor: 'royalblue' }}
                            />
                        </a>
                        <a
                            href={`https://www.addtoany.com/add_to/facebook_messenger?linkurl=${encoded}&linkname=${pageTitle}`}
                            target="_blank"
                        >
                            <img
                                src="https://static.addtoany.com/buttons/facebook_messenger.svg"
                                width={32}
                                height={32}
                                style={{ backgroundColor: 'royalblue' }}
                            />
                        </a>
                        <a
                            href={`https://www.addtoany.com/add_to/copy_link?linkurl=${encoded}&linkname=${pageTitle}`}
                            target="_blank"
                        >
                            <img
                                src="https://static.addtoany.com/buttons/link.svg"
                                width={32}
                                height={32}
                                style={{ backgroundColor: 'royalblue' }}
                            />
                        </a>
                    </div>
                </Box>

                <Divider sx={{ my: 1 }} />
                <Box sx={{ px: 1 }}>{ReactHtmlParser(post.content)}</Box>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ px: 1, display: post.title ? 'flex' : 'none', flexDirection: 'row' }}>
                    <Typography variant="caption">Posted by:</Typography>
                    <Typography variant="caption" sx={{ fontStyle: 'italic', fontWeight: 'bolder' }}>
                        {post.author}
                    </Typography>
                </Box>
            </Card>
        </Container>
    );
};

export default Blog;
