import React from 'react';
import {
    Button,
    CircularProgress,
    Grid,
    Modal,
    Paper,
    Snackbar,
    Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { useNavigate } from 'react-router-dom';

import PageHeader from '../../components/PageHeader';
import { HContainer } from '../../components/TopTipsters';
import BreadCrumbView from '../../components/app_bar/BreadCrumbView';
import { withCookies } from 'react-cookie';
import axios from '../../api/axios';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {
    FacebookShareButton,
    TwitterShareButton,
    TelegramShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    TelegramIcon
} from 'react-share';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import Title from '../../components/Title';
import { DashButton, WithdrawModal } from './components';
import Subscribe from '../../components/Subscribe';
import { LoadingButton } from '@mui/lab';

import ReactGA from 'react-ga';

import { GOOGLE_ANALYTICS_KEY } from '../../data';

ReactGA.initialize(GOOGLE_ANALYTICS_KEY);

class UserDashboardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navigator: props.navigator,
            navigate: props.navigator,
            username: null,

            myRating: 0,

            pendingPayments: 0,
            accountBalance: 0,
            totalEarnings: 0,
            todayEarning: 0,
            monthEarnings: 0,

            withdrawDialog: false,

            shareUrl: '',
            copied: false,

            openSnack: false,

            profile: null,

            emailVerified: true,
            loadingVerification: false
        };

        this.handleAddTipsBtn = this.handleAddTipsBtn.bind(this);
        this.handleViewLatestTips = this.handleViewLatestTips.bind(this);
        this.handleEarningsList = this.handleEarningsList.bind(this);
        this.handleViewPublishedTips = this.handleViewPublishedTips.bind(this);
        this.handleOpenWithdrawCash = this.handleOpenWithdrawCash.bind(this);
        this.handleOpenSettings = this.handleOpenSettings.bind(this);

        this.handleLoadData = this.handleLoadData.bind(this);

        this.handleOpenWithdrawCash = this.handleOpenWithdrawCash.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);

        this.handleCloseSnack = this.handleCloseSnack.bind(this);

        this.handleWithdrawRequestsList =
            this.handleWithdrawRequestsList.bind(this);

        this.handleCloseWithdraw = this.handleCloseWithdraw.bind(this);

        this.handleVerifyEmail = this.handleVerifyEmail.bind(this);
    }

    componentDidMount() {
        ReactGA.event({
            "action": "Open Dashboard",
            "category": "User"
        });

        this.handleLoadData();
    }

    handleCloseSnack() {
        this.setState({
            openSnack: false
        });
    }

    handleLoadData() {
        const { cookies } = this.props;
        const token = cookies.get('token');

        const options = {
            method: 'GET',
            url: '/api/finance/pending_amount',
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        axios.request(options).then((response) => {
            const data = response.data;
            const getValue = (value) => Math.round(value * 100) / 100;

            this.setState({
                pendingPayments: getValue(data['pending_amount']),
                accountBalance: getValue(data['account_balance']),
                totalEarnings: getValue(data['total_earnings']),
                todayEarnings: getValue(data['today_earnings']),
                monthEarnings: getValue(data['month_earnings'])
            });
        });

        const options2 = {
            method: 'GET',
            url: '/api/account/profile',
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        axios.request(options2).then((response) => {
            const data = response.data['dash'];
            const user = response.data['user']['username'];
            console.log(user);

            this.setState({
                myRating: data['rating'],
                username: user,
                shareUrl: `https://futaapredict.com/profile/${user}`,
                profile: response.data['profile'],
                emailVerified: response.data['profile']['email_verified']
            });
        });
    }

    handleVerifyEmail() {
        this.setState({
            loadingVerification: true
        })
        const { cookies } = this.props;

        const token = cookies.get('token');

        const config = {
            method: 'GET',
            url: '/api/account/send-email-verification-code',
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        axios.request(config).then((response) => {
            const data = response.data;
            const message = data['message'];
            const email = data['email'];

            alert(`${message}. Check ${email}`);
        }).catch((error) => {
            const response = error.response;
            if (response.data) {
                alert(response.data['message']);
            }
        }).finally(() => {
            this.setState({
                loadingVerification: false
            })
        });
    }

    handleAddTipsBtn() {
        const { navigator } = this.state;
        const { cookies } = this.props;

        const token = cookies.get('token');

        const options = {
            method: 'GET',
            url: '/api/slip/can-post',
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        axios
            .request(options)
            .then(() => {
                ReactGA.event({
                    "category": "User",
                    "action": "Opened Add Tips Page"
                });

                navigator('/dashboard/add_tips');
            })
            .catch(function () {
                alert("Can't Post more than 2 tips");
            });
    }

    handleViewLatestTips() {
        const { navigator, username } = this.state;
        navigator(`finished_tips/${username}`);
    }

    handleEarningsList() {
        const { navigate } = this.state;
        navigate('earnings');
    }

    handleWithdrawRequestsList() {
        const { navigate } = this.state;
        navigate('withdraw');
    }

    handleViewPublishedTips() {
        const { navigate, username } = this.state;
        navigate(`active_tips/${username}`);
    }

    handleOpenWithdrawCash() {
        if (this.state.accountBalance < 500) {
            alert(
                'Minimum withdrawal amount is Ksh. 500. Account Balance should be at least Ksh. 500'
            );
        } else
            this.setState({
                withdrawDialog: true
            });
    }

    handleCloseDialog() {
        this.setState({
            withdrawDialog: false
        });
    }

    handleOpenSettings() {
        const { navigate } = this.state;
        navigate('/account_settings');
    }

    handleCloseWithdraw() {
        this.handleCloseDialog();
        this.setState({
            openStack: true
        });
    }

    render() {
        const { cookies } = this.props;

        const {
            pendingPayments,
            myRating,
            accountBalance,
            totalEarnings,
            todayEarnings,
            monthEarnings,

            shareUrl,
            profile,
            emailVerified
        } = this.state;

        console.log(profile);

        const subscribed = profile && profile.subscribed;

        const handleClose = (some) => {
            if (!some) console.log('Not Passed');
            this.handleCloseDialog();
        };

        const handleCopy = () => {
            this.setState({ copied: true, openSnack: true });
        };

        return (
            <Box
                sx={{
                    p: 0,
                    m: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0
                }}
            >
                <Title title="FutaaPredict| Dashboard" />
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    open={this.state.openSnack}
                    onClose={this.handleCloseSnack}
                    key={'bottom' + 'left'}
                    autoHideDuration={3000}
                >
                    <Alert
                        onClose={this.handleCloseSnack}
                        severity="success"
                        sx={{ width: '100%' }}
                    >
                        Success
                    </Alert>
                </Snackbar>
                <Modal
                    open={this.state.withdrawDialog}
                    onClose={(_, reason) => handleClose(reason)}
                >
                    <WithdrawModal
                        amount={accountBalance}
                        handleClose={this.handleCloseWithdraw}
                        cookies={cookies}
                    />
                </Modal>
                <BreadCrumbView />
                {true ? (
                    false ? (
                        <Subscribe cookies={cookies} />
                    ) : (
                        <Box
                            sx={{
                                p: 1,
                                m: 0,
                                gap: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }}
                        >
                            <HContainer>
                                <Paper
                                    square
                                    elevation={1}
                                    sx={{
                                        p: 1,
                                        mx: 0,
                                        my: 1,
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: 1
                                    }}
                                >
                                    <PageHeader
                                        sx={{ pl: 2 }}
                                        title="Dashboard"
                                        description="Select an option"
                                        disableDivider
                                    />

                                    <Box
                                        sx={{
                                            p: 0,
                                            m: 1,
                                            ml: 0,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            maxWidth: 700,
                                            gap: 1
                                        }}
                                    >
                                        <Paper
                                            variant="outlined"
                                            sx={{
                                                p: 1,
                                                m: 1,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'left',
                                                maxWidth: 700
                                            }}
                                        >
                                            <Box
                                                spacing={1}
                                                direction="row"
                                                justifyContent="left"
                                                flexWrap="wrap"
                                                sx={{
                                                    p: 0,
                                                    m: 0,
                                                    w: 1,
                                                    gap: 1
                                                }}
                                            >
                                                <Box
                                                    spacing={1}
                                                    direction="row"
                                                    flexWrap="wrap"
                                                    sx={{
                                                        p: 0,
                                                        m: 0,
                                                        w: 1,
                                                        gap: 1,
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <FacebookShareButton
                                                        url={shareUrl}
                                                    >
                                                        <FacebookIcon
                                                            size={32}
                                                            round
                                                        />
                                                    </FacebookShareButton>

                                                    <TwitterShareButton
                                                        url={shareUrl}
                                                    >
                                                        <TwitterIcon
                                                            size={32}
                                                            round
                                                        />
                                                    </TwitterShareButton>

                                                    <TelegramShareButton
                                                        url={shareUrl}
                                                    >
                                                        <TelegramIcon
                                                            size={32}
                                                            round
                                                        />
                                                    </TelegramShareButton>

                                                    <WhatsappShareButton
                                                        url={shareUrl}
                                                    >
                                                        <WhatsappIcon
                                                            size={32}
                                                            round
                                                        />
                                                    </WhatsappShareButton>

                                                    <CopyToClipboard
                                                        text={shareUrl}
                                                        onCopy={handleCopy}
                                                    >
                                                        <Button
                                                            startIcon={
                                                                <ContentCopyIcon />
                                                            }
                                                            variant="contained"
                                                            disableElevation
                                                        >
                                                            Copy Link
                                                        </Button>
                                                    </CopyToClipboard>
                                                </Box>
                                                <Typography
                                                    variant="subtitle2"
                                                    component="div"
                                                >
                                                    Share your subscription link
                                                    and get gamblers to buy your
                                                    tips
                                                </Typography>
                                            </Box>
                                            {false && (
                                                <Alert severity="warning">
                                                    <AlertTitle>
                                                        Email Verification
                                                    </AlertTitle>
                                                    You won't be able to add
                                                    tips until you verify your email. Click{' '}
                                                    <LoadingButton loading={this.state.loadingVerification} onClick={this.handleVerifyEmail} variant="contained" disableElevation>
                                                        <Typography variant="caption">
                                                            here
                                                        </Typography>
                                                    </LoadingButton>{' '}
                                                    Verify email
                                                </Alert>
                                            )}
                                        </Paper>
                                        <Grid
                                            container
                                            spacing={1}
                                            direction="row"
                                            justifyContent="flex-start"
                                            wrap="wrap"
                                            sx={{ p: '1px', m: 0, w: 1 }}
                                        >
                                            <DashButton
                                                label="Pending Payments"
                                                value={`Ksh. ${pendingPayments}`}
                                            />

                                            <DashButton
                                                label="My Rating"
                                                value={`${myRating} %`}
                                            />

                                            <DashButton
                                                label="Account Balance"
                                                value={`Ksh. ${accountBalance}`}
                                            />

                                            <DashButton
                                                label="Total Earnings"
                                                value={`Ksh. ${totalEarnings}`}
                                                onClick={
                                                    this.handleEarningsList
                                                }
                                                ignore
                                            />

                                            <DashButton
                                                label="Today's Income - 40% Fee"
                                                value={`Ksh. ${todayEarnings}`}
                                                onClick={
                                                    this.handleEarningsList
                                                }
                                                ignore
                                            />

                                            <DashButton
                                                label="Monthly Income - 40% Fee"
                                                value={`Ksh. ${monthEarnings}`}
                                                onClick={
                                                    this.handleEarningsList
                                                }
                                                ignore
                                            />
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={1}
                                            direction="row"
                                            justifyContent="flex-start"
                                            wrap="wrap"
                                            sx={{ p: 0, m: 0, w: 1 }}
                                        >
                                            <DashButton
                                                label="Add Tips"
                                                onClick={this.handleAddTipsBtn}
                                            />

                                            <DashButton
                                                label="View Latest Tips"
                                                o
                                                onClick={
                                                    this.handleViewLatestTips
                                                }
                                            />

                                            <DashButton
                                                label="Withdraw Requests"
                                                onClick={
                                                    this
                                                        .handleWithdrawRequestsList
                                                }
                                            />

                                            <DashButton
                                                label="Published Tips"
                                                onClick={
                                                    this.handleViewPublishedTips
                                                }
                                            />

                                            <DashButton
                                                label="Settings"
                                                onClick={
                                                    this.handleOpenSettings
                                                }
                                            />

                                            <DashButton
                                                label="Withdraw Cash"
                                                onClick={
                                                    this.handleOpenWithdrawCash
                                                }
                                            />
                                        </Grid>
                                    </Box>

                                    <Typography
                                        variant="caption"
                                        component="div"
                                    >
                                        Minimum withdrawal is Ksh. 500
                                    </Typography>
                                </Paper>
                            </HContainer>
                        </Box>
                    )
                ) : (
                    <Box
                        sx={{
                            p: 1,
                            m: 0,
                            gap: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    >
                        <HContainer>
                            <Paper
                                square
                                elevation={1}
                                sx={{
                                    p: 1,
                                    mx: 0,
                                    my: 1,
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: 1
                                }}
                            >
                                <Box
                                    sx={{
                                        p: 0,
                                        m: 1,
                                        ml: 0,
                                        display: 'flex',
                                        // flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        maxWidth: 700,
                                        maxHeight: '100%',
                                        width: '100%',
                                        height: 400,
                                        gap: 1
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            </Paper>
                        </HContainer>
                    </Box>
                )}
            </Box>
        );
    }
}

function UserDashboard(props) {
    const navigator = useNavigate();
    return <UserDashboardComponent {...props} navigator={navigator} />;
}

export default withCookies(UserDashboard);
