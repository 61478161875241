import React from 'react';
import {
    Box,
    Card,
    Paper,
    Typography,
    Grid,
    Link,
    Button,
    Modal,
    Divider,
    FormControl,
    FormHelperText,
    OutlinedInput,
    InputLabel,
    InputAdornment,
    Alert,
    AlertTitle
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import PageHeader from './PageHeader';
import { HContainer } from './TopTipsters';
import axios from '../api/axios';
import { Days } from '../data';

const ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // boxShadow: 24,
    p: 0
};

const SubscriptionCard = ({ data, background = null, ...props }) => {
    const { prevResult, todaysOdd, title, description, next_match } = data;
    console.log(data);
    console.log('prev', prevResult);

    const [amount, setAmount] = React.useState(0);
    const [phone, setPhone] = React.useState('');
    const [days, setDays] = React.useState('');
    const [phoneError, setPhoneError] = React.useState(null);
    const handleChange = (e) => {
        setPhone(e.target.value);
    };
    const [loading, setLoading] = React.useState(false);

    const [openModal, setOpenModal] = React.useState(false);

    const handleBuy = (e, days, amount) => {
        setAmount(amount);
        setDays(days);
        setOpenModal(true);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        if (loading) {
            return;
        }
        // alert(type);

        if (phone == null || !(phone.length === 10 || phone.length === 9)) {
            setPhoneError('Enter Valid Phone Number');
            return;
        }

        const getPhoneNumber = (phone) => {
            if (phone.length === 10) return `254${phone.substring(1)}`;
            return `254${phone}`;
        };

        // if (subType !== 'G' && subType !== 'B') {
        //     setPhoneError('Set subscription type');
        //     return;
        // }

        setLoading(true);

        const config = {
            method: 'POST',
            url: '/api/processor/subscribe',
            data: {
                phone: getPhoneNumber(phone),
                type: data['type'],
                days: days
            }
        };

        setLoading(true);
        axios
            .request(config)
            .then((res) => {
                alert('Request sent. Wait for mpesa stk');
            })
            .catch((error) => {
                console.log(error.response);
                if (error.response['data']) {
                    const message = error.response['data']['message'];
                    if (message) {
                        alert(message);
                    } else {
                        alert('Error occurred. Try again');
                    }
                } else {
                    alert('Error occurred. Try again');
                }
            })
            .finally(() => {
                setLoading(false);
                setOpenModal(false);
            });
    };

    const getTime = () => {
        if (next_match === '' || next_match === undefined) {
            return '';
        }
        const date = new Date(next_match);
        const day = new Date().getDay() === date.getDay() ? 'Today' : Days[date.getDay()];

        const parseMinutes = (mins) => {
            if (mins <= 9) return `0${mins}`;
            return `${mins}`;
        };

        const time = `${date.getHours()}:${parseMinutes(date.getMinutes())}`;
        let game_time = `${day} ${time}`;

        return game_time;
    };

    const ResultInfo = ({ date, odds, result }) => (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6">{date}</Typography>
            {result === 'W' || result === 'C' ? (
                <CheckCircleRoundedIcon color={result === 'W' ? 'success' : 'warning'} />
            ) : (
                <CancelRoundedIcon color="error" />
            )}
        </Box>
    );

    return (
        <Card
            elevation={1}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#06001E',

                backgroundImage: `url(https://images.pexels.com/photos/47730/the-ball-stadion-football-the-pitch-47730.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}
        >
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Box sx={{ ...ModalStyle, m: 0, p: 0, minWidth: { xs: '100%', sm: 300 } }}>
                    <Card
                        elevation={0}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            // gap: 0,
                            m: 1
                        }}
                    >
                        <Typography sx={{ p: 1 }} variant="subtitle1">
                            Pay {title} for {(days === 1000 ? "a lifetime subscription" : `${days} days`)} @ Ksh. {amount}
                        </Typography>

                        <Divider />

                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 1 }}>
                            <Typography variant="caption">Enter your m-pesa phone number.</Typography>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="phone">Mpesa Phone Number</InputLabel>
                                <OutlinedInput
                                    id="phone"
                                    size="small"
                                    label="Mpesa Phone Number"
                                    type="phone"
                                    startAdornment={<InputAdornment position="start">+254</InputAdornment>}
                                    error={phoneError !== null}
                                    helperText={phoneError}
                                    value={phone}
                                    onChange={handleChange}
                                />
                                <FormHelperText error>{phoneError}</FormHelperText>
                            </FormControl>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                                <LoadingButton
                                    sx={{ width: '100%', flexGrow: 1 }}
                                    variant="contained"
                                    disableElevation
                                    loading={loading}
                                    onClick={(e) => handleSubmit(e)}
                                >
                                    <Typography variant="caption">Buy</Typography>
                                </LoadingButton>
                                <LoadingButton
                                    sx={{ width: '100%', flexGrow: 1 }}
                                    variant="contained"
                                    color="error"
                                    disableElevation
                                    loading={loading}
                                    onClick={(e) => setOpenModal(false)}
                                >
                                    <Typography variant="caption">Cancel</Typography>
                                </LoadingButton>
                            </Box>
                        </Box>

                        <Card
                            sx={{
                                p: 1,
                                m: 1,
                                color: 'white',
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: '#06001E'
                            }}
                        >
                            <Typography variant="subtitle2">How it Works</Typography>

                            <Typography variant="caption">
                                <p>1. Buy a subscription plan</p>
                                <p>2. Receive sms confirmation</p>
                                <p>3. Every day premium tips are posted. Check using the view button.</p>
                                {/* <p>4. You can also check for posted premium tips here.</p> */}
                            </Typography>

                            {/* <Alert severity="info">
                                <AlertTitle>Info</AlertTitle>
                                Tips are sent instantly via SMS — Ensure you have activated promotional messages !.{' '}
                                <strong>Dial *456*9*5*5*1</strong>
                            </Alert> */}
                        </Card>
                    </Card>
                </Box>
            </Modal>
            <Box
                sx={{
                    py: 2.5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    backgroundColor: background || 'rgb(6, 22, 61, 0.95)'
                }}
            >
                {/* <Box sx={{ background: "red" }}> */}
                <Typography variant="h6">{title}</Typography>
                {(description || '').split('\n').map((it) => (
                    <Typography variant="body">{it}</Typography>
                ))}
                {/* <Typography variant="body">{description}</Typography> */}
                {/* </Box> */}
            </Box>
            <Box
                sx={{
                    backgroundColor: '#06001E',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    pt: 1,
                    gap: 2
                }}
            >
                {/* <Typography variant="h6">Previous Results</Typography> */}

                <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                    {/* {prevResult &&
                        prevResult.map((it) => (
                            <ResultInfo date={it['date']} result={it['result']} odds={it['odds']} />
                        ))} */}
                    <Typography variant="subtitle1">
                        Enjoy up to <span style={{ color: '#F6003B', fontWeight: 'bolder' }}>95%</span> accuracy on our
                        premium plan
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-evenly', gap: 1 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                        <Typography variant="h6">Today's Odd</Typography>
                        <Typography variant="h5" sx={{ color: '#F6003B' }}>
                            {todaysOdd}
                        </Typography>
                    </Box>
                    {/* <Button sx={{ background: "#084CE8", color: "white" }} component={RouterLink} to="/subscriptions">
                        <Typography variant="caption">
                            Get Access Now
                        </Typography>
                    </Button> */}
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <Button sx={{ background: '#084CE8', color: 'white' }} onClick={(e) => handleBuy(e, 1, data['day_price'])}>
                        <Typography variant="caption">
                            Buy {title} 1 Days @ {data['day_price']}
                        </Typography>
                    </Button> */}

                    <Grid container sx={{ width: "100%" }}>
                        <Grid item xs={12} sm={6} columnSpacing={1.5} rowSpacing={1.5}>
                            <Button
                                sx={{ background: '#084CE8', color: 'white', width: 300, m: 1 }}
                                onClick={(e) => handleBuy(e, 1000, data['price'])}
                            >
                                <Typography variant="caption">
                                    Buy {title} @ Ksh. {data['price']}
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                sx={{ color: 'white', width: 300, m: 1 }}
                                color="success"
                                variant="contained"
                                to='/subscriptions'
                                component={RouterLink}
                                // onClick={(e) => handleBuy(e, 1000, data['price'])}
                            >
                                <Typography variant="caption">
                                    {/* Buy {title} @ Ksh. {data['price']} */}
                                    View Tips
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>

                    {/* <Button sx={{ background: '#084CE8', color: 'white' }} onClick={(e) => handleBuy(e, 30, data['month_price'])}>
                        <Typography variant="caption">
                            Buy {title} 30 Days @ {data['month_price']}
                        </Typography>
                    </Button> */}
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-evenly', background: '#084CE8', width: '100%' }}>
                    <Typography variant="caption">Next Match: {getTime()}</Typography>
                </Box>
            </Box>
        </Card>
    );
};

const defaultSubscriptionInfo = {
    // prevResult: [
    //     { date: '08/11', result: 'W', odds: 2.1 },
    //     { date: '07/11', result: 'C', odds: 2.2 },
    //     { date: '06/11', result: 'L', odds: 1.5 },
    //     { date: '05/11', result: 'W', odds: 2.2 }
    // ],
    // todaysOdd: 1.75
    prevResult: []
};

const Subscriptions = () => {
    const [goldData, setGoldData] = React.useState(defaultSubscriptionInfo);
    const [bronzeData, setBronzeData] = React.useState(defaultSubscriptionInfo);
    const [silverData, setSilverData] = React.useState(defaultSubscriptionInfo);

    const handleLoadData = () => {
        const config = {
            method: 'GET',
            url: '/api/manage/get-subscription-kind'
        };

        axios
            .request(config)
            .then((res) => {
                const data = res.data;
                setGoldData(data['gold']);
                setBronzeData(data['bronze']);
                setSilverData(data['silver']);
            })
            .catch((error) => {
                console.log('Error: ', error.response);
            });
    };

    React.useEffect(() => {
        handleLoadData();
    }, []);

    return (
        <Box sx={{ p: 0, m: 0 }}>
            <HContainer>
                <Paper
                    square
                    elevation={1}
                    sx={{
                        py: 1,
                        mx: 0,
                        my: 1,
                        width: '100%',
                        height: '100%',
                        borderRadius: 1
                    }}
                >
                    {/* <PageHeader sx={{ pl: 0 }} title="Subscriptions" /> */}
                    <Box
                        sx={{
                            p: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            gap: 1
                        }}
                    >
                        <Card
                            elevation={1}
                            sx={{
                                background: '#06001E',
                                color: 'white',
                                m: 1,
                                p: 1
                            }}
                        >
                            <Typography variant="h6" fontWeight={500}>
                                <strong>Don't Gamble, Invest</strong>
                            </Typography>
                        </Card>
                        <Grid container spacing={1} sx={{ px: 1 }}>
                            <Grid item xs={12}>
                                <SubscriptionCard data={silverData} />
                            </Grid>
                            {/* <Grid item xs={12} sm={6} lg={6}>
                                <SubscriptionCard
                                    data={bronzeData}
                                    // background="rgba(199, 123, 48, 0.7)"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <SubscriptionCard
                                    data={goldData}
                                    // background="rgba(247, 208, 0, 0.7)"
                                />
                            </Grid> */}
                        </Grid>
                    </Box>
                </Paper>
            </HContainer>
        </Box>
    );
};

export default Subscriptions;
