import React from 'react';

import { Box, Grid, Typography, Paper } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { useCookies, withCookies } from 'react-cookie';

import { PremiumActiveTip } from '../components/PremiumTipsComponent';
import axios from '../api/axios';
import { Helmet } from 'react-helmet';
import { HContainer } from '../components/TopTipsters';
import PageHeader from '../components/PageHeader';
import { requestCall, requestCallNoAccess } from '../api/request';

const defaultNews = {
    title: 'Win 100% With Jackpot',
    slug: 'win-with-jackpot',
    content: 'Play to win',
    description: 'Recent news',
    date_created: new Date().toUTCString(),
    thumbnail: ''
};

const News = () => {
    const [posts, setPosts] = React.useState([]);

    React.useEffect(() => {
        const url = '/v2/api/manage/post/';

        // requestCallNoAccess(url)
        //     .then((response) => {
        //         const data = response.data;
        //         const results = data['results'];
        //         setPosts(results);
        //     })
        //     .catch((error) => {
        //         console.log(error.response);
        //     });
    }, []);

    return (
        <Box sx={{ p: 0, m: 1 }}>
            <HContainer>
                <Paper
                    square
                    elevation={1}
                    sx={{
                        p: 1,
                        mx: 0,
                        my: 1,
                        width: '100%',
                        height: '100%',
                        borderRadius: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alighnItems: 'center'
                    }}
                >
                    <PageHeader sx={{ pl: 0 }} title={'FutaaPredict News'} desciption="" />

                    {/* <Headline post={posts[0]} /> */}

                    <Grid container spacing={1} sx={{ m: 0, p: 0, maxWidth: '100%' }}>
                        {posts.map((post) => (
                            <NewsItem post={post} />
                        ))}
                    </Grid>
                    <Box>
                        <div className="post-body entry-content float-container" id="post-body-3531201744569497172">
                            <p>
                                Get reliable football predictions from your trusted source,{' '}
                                <a href="https://futaapredict.com/">FutaaPredict</a>. We are dedicated to providing you with the
                                most accurate football predictions for today's matches, free of charge. We meticulously
                                analyze all matches scheduled for today, covering leagues from around the world. To
                                ensure our predictions are reliable, we consider various factors such as:
                            </p>
                            <ul>
                                <li>
                                    <strong>Team Form</strong>: Assessing a team's recent performance and match results
                                    to gauge their current level of competitiveness and confidence.
                                </li>
                                <li>
                                    <strong>Head-to-Head Records</strong>: Reviewing the historical performance of the
                                    two teams against each other, which can indicate a likely outcome.
                                </li>
                                <li>
                                    <strong>Home Advantage</strong>: Considering the benefits a team enjoys when playing
                                    at their home stadium, including familiarity with the pitch, crowd support, and
                                    potential favorable refereeing decisions.
                                </li>
                                <li>
                                    <strong>Team Motivation</strong>: Evaluating a team's desire to win, influenced by
                                    factors such as the importance of the match, the quality of the opposition, and
                                    financial incentives.
                                </li>
                                <li>
                                    <strong>Player Injuries and Suspensions</strong>: Analyzing the impact of missing
                                    key players on a team's performance.
                                </li>
                                <li>
                                    <strong>Team Tactics</strong>: Studying the strategies a team employs, which can be
                                    influenced by the opposition's strength, playing conditions, and available
                                    personnel.
                                </li>
                            </ul>
                            <h3>Football Prediction Tips</h3>
                            <p>
                                Our tips are designed to help you make informed decisions. By analyzing player
                                performance and other factors, we provide insights that enhance your football
                                experience.
                            </p>
                            <h3>Additional Prediction Categories</h3>
                            <p>
                                In addition to today's predictions, <a href="https://futaapredict.com/">FutaaPredict</a> offers
                                predictions for:
                            </p>
                            <ul>
                                <li>
                                    <a href="https://futaapredict.com/">Tomorrow's Matches</a>
                                </li>
                                <li>
                                    <a href="https://futaapredict.com/">Weekend Matches</a>
                                </li>
                                <li>
                                    <a href="https://futaapredict.com/">Top Matches</a>
                                </li>
                                <li>
                                    <a href="https://futaapredict.com/">Live Matches</a>
                                </li>
                                <li>
                                    <b>
                                        <a href="https://futaapredict.com/jackpot">Yesterday's Matches</a>
                                    </b>
                                </li>
                                <li>
                                    <b>
                                        <a href="https://futaapredict.com/">Sportpesa and Betika Jackpot predictions</a>
                                    </b>
                                </li>
                            </ul>
                            <h3>Correct Score Predictions</h3>
                            <p>
                                We also offer predictions for correct scores, using statistical models and in-depth
                                analysis to help you make better choices and add excitement to your football experience.
                            </p>
                            <h3>Over 2.5 Goals Predictions</h3>
                            <p>
                                Find predictions for matches likely to have three or more goals scored. We analyze team
                                dynamics and other factors to forecast high-scoring games. Additionally, we provide
                                predictions for matches likely to have fewer than 2.5 goals.
                            </p>
                            <h3>Windrawwin Predictions</h3>
                            <p>
                                Our Windrawwin predictions cover all possible outcomes, helping you explore different
                                betting options. We assess critical factors to provide comprehensive predictions for
                                today's matches.
                            </p>
                            <h3>Other Prediction Markets</h3>
                            <p>We also offer predictions for:</p>
                            <ul>
                                <li>Both Teams to Score (BTTS)</li>
                                <li>Double Chance</li>
                                <li>100% Sure Predictions</li>
                            </ul>
                            <h3>Free Predictions</h3>
                            <p>
                                At{' '}
                                <b>
                                    <span style={{ fontSize: 'medium' }}>
                                        <a href="https://futaapredict.com/">FutaaPredict</a>
                                    </span>
                                </b>
                                , we offer free and VIP predictions. We believe reliable predictions should be
                                accessible to everyone. Our free predictions cover domestic and international leagues,
                                including:
                            </p>
                            <ul>
                                <li>English Premier League</li>
                                <li>Spain La Liga</li>
                                <li>Italy Serie A</li>
                                <li>Brazil Serie A</li>
                                <li>Germany Bundesliga</li>
                            </ul>
                            <h3>Halftime/Full-time Predictions</h3>
                            <p>
                                We offer predictions for the outcomes at both halftime and full-time, analyzing
                                historical data and other factors to help you make informed judgments.
                            </p>
                            <h3>Conclusion</h3>
                            <p>
                                <b>
                                    <span style={{ fontSize: 'medium' }}>
                                        <a href="https://futaapredict.com/">FutaaPredict</a>
                                    </span>
                                </b>{' '}
                                is your ultimate destination for today's football predictions. With our reliable
                                insights, you have the best chance of winning. Visit Sokapedia today and start making
                                informed predictions!
                            </p>
                            <h3>FAQs</h3>
                            <ul>
                                <li>
                                    <strong>Accuracy</strong>: We strive to provide highly accurate predictions using
                                    advanced algorithms.
                                </li>
                                <li>
                                    <strong>Cost</strong>: we provide both free and premium predictions.
                                </li>
                                <li>
                                    <strong>Trust</strong>: Our 100% sure predictions are based on thorough analysis and
                                    expert insights.
                                </li>
                                <li>
                                    <strong>Correct Score Predictions</strong>: We use statistical models and in-depth
                                    analysis for exact score predictions.
                                </li>
                                <li>
                                    <strong>Over 2.5 Goals</strong>: We identify matches with high-scoring potential by
                                    analyzing team dynamics.
                                </li>
                            </ul>
                        </div>
                    </Box>
                </Paper>
            </HContainer>
        </Box>
    );
};

const Headline = ({ post }) => {
    const { title, slug, content, thumbnail } = post;
    return (
        <Paper
            variant="outlined"
            sx={{
                p: 0,
                m: 0,
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            <img src={thumbnail} />
            <Box>Headline - {title}</Box>
        </Paper>
    );
};

const NewsItem = ({ post }) => {
    const { title, slug, content, thumbnail } = post;
    const thumb =
        'https://images.pexels.com/photos/47730/the-ball-stadion-football-the-pitch-47730.jpeg?auto=compress&cs=tinysrgb&w=300';
    return (
        <Grid item xs={12} sm={6}>
            <Paper
                variant="outlined"
                component={RouterLink}
                to={`/blog/${slug}`}
                sx={{
                    p: 0,
                    m: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    maxHeight: 100,
                    minHeight: 100,
                    transition: '0.3s',
                    textDecoration: 'none',
                    '&:hover': {
                        background: 'rgba(255, 255, 255, 0.06)',
                        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                        '-webkit-backdrop-filter': 'blur(0px)',
                        // border: "1px solid rgba(255, 255, 255, 0.11)",
                        cursor: 'pointer'
                    }
                }}
            >
                <Grid container spacing={0}>
                    <Grid item xs={4}>
                        <Box
                            sx={{
                                maxHeight: '100%',
                                minHeight: '100%',
                                display: post.title ? 'flex' : 'none',

                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: `black url(${post.thumbnail || thumb}) no-repeat`,
                                backgroundPosition: 'center',
                                backgroundSize: '100% auto'
                            }}
                        ></Box>
                    </Grid>
                    <Grid item xs={8} sx={{ p: 1 }}>
                        <Typography variant="subtitle1">{title}</Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default News; 
