import React from 'react';

import Box from '@mui/material/Box';
import { Dialog, DialogContent, Link, Typography, Button } from '@mui/material';

import CookieConsent, { Cookies } from 'react-cookie-consent';

import { HContainer } from '../TopTipsters';

import ReactMarkdown from 'react-markdown';

import privacyPolicyFile from '../../assets/privacy.md';
import termsFile from '../../assets/terms.md';
import aboutFile from '../../assets/about.md';

import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import Paper from '@mui/material/Paper';

export function PrivacyPolicy(props) {
    return (
        <ReactMarkdown
            className="markdown"
            style={{
                fontFamily: 'GT Walsheim Pro',
                fontWeight: 'small',
                fontSize: 10
            }}
        >
            {props.file}
        </ReactMarkdown>
    );
}

export default function Footer() {
    const [open, setOpen] = React.useState(false);

    const [file, setFile] = React.useState(null);

    const [pFile, setPFile] = React.useState(null);
    const [tFile, setTFile] = React.useState(null);
    const [aFile, setAFile] = React.useState(null);

    const openTelegram = () => {
        window.open("https://t.me/sokafansurebets", "_blank", "noreferrer");
    };

    const openWhatsApp = () => {
        window.open("https://whatsapp.com/channel/0029Va24KOzAO7RONhm0vV3x", "_blank", "noreferrer");
    };

    React.useEffect(() => {
        if (!pFile) {
            fetch(privacyPolicyFile)
                .then((response) => response.text())
                .then((text) => {
                    setPFile(text);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        if (!tFile) {
            fetch(termsFile)
                .then((response) => response.text())
                .then((text) => {
                    setTFile(text);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        if (!aFile) {
            fetch(aboutFile)
                .then((response) => response.text())
                .then((text) => {
                    setAFile(text);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    });

    const handleClose = () => setOpen(false);

    const handleOpenPrivacy = () => {
        setFile(pFile);
        setOpen(true);
    };

    const handleOpenTerms = () => {
        setFile(tFile);
        setOpen(true);
    };

    const handleOpenAbout = () => {
        setFile(aFile);
        setOpen(true);
    };

    return (
        <Box
            sx={{
                // flex: '0 0 50px',
                backgroundColor: '#06001E',
                marginTop: 'auto'
            }}
        >
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <PrivacyPolicy file={file} />
                    </Box>
                </DialogContent>
            </Dialog>

            <HContainer>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box
                        sx={{
                            p: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            // justifyContent: "space-between",
                            width: "100%",
                            gap: 1
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Typography
                                component="div"
                                color="white"
                                variant="h6"
                            >
                                FutaaPredict
                            </Typography>

                            <Paper sx={{
                                maxWidth: 300,
                                padding: 1,
                                background: '#F0F0F0'
                            }}>
                                <Box
                                    sx={{
                                        mx: 2,
                                        display: 'flex',
                                        gap: 1,
                                        flexDirection: 'column',

                                    }}
                                >
                                    <Typography variant="subtitle2">
                                        For free surebets and jackpot predictions, join
                                        our Telegram channel.
                                    </Typography>

                                    <Button variant="contained" startIcon={<TelegramIcon />} sizeSmall
                                        sx={{
                                            background: '#26A4E2'
                                        }}
                                        onClick={openTelegram}
                                    >
                                        VIEW TELEGRAM CHANNEL
                                    </Button>

                                    <Button variant="contained" startIcon={<WhatsAppIcon />} sizeSmall
                                        sx={{
                                            background: '#25D366',
                                            '&:hover': {
                                                background: "#25D366"
                                            }
                                        }}
                                        onClick={openWhatsApp}
                                    >
                                        VIEW WHATSAPP CHANNEL
                                    </Button>
                                </Box>
                            </Paper>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography
                                    component="div"
                                    color="white"
                                    variant="h6"
                                >
                                    Legal
                                </Typography>

                                <Link
                                    to="#"
                                    underline="hover"
                                    onClick={handleOpenPrivacy}
                                >
                                    <Typography variant="caption">
                                        Privacy Policy
                                    </Typography>
                                </Link>

                                <Link
                                    to="#"
                                    underline="hover"
                                    onClick={handleOpenTerms}
                                >
                                    <Typography variant="caption">
                                        Terms of Service
                                    </Typography>
                                </Link>

                                <Link
                                    to="#"
                                    underline="hover"
                                    onClick={handleOpenAbout}
                                >
                                    <Typography variant="caption">About</Typography>
                                </Link>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography
                                    component="div"
                                    color="white"
                                    variant="h6"
                                >
                                    Contacts
                                </Typography>

                                <Link
                                    to="#"
                                    underline="hover"
                                    onClick={(e) => {
                                        window.location.href =
                                            'mailto:support@futaapredict.com';
                                        e.preventDefault();
                                    }}
                                >
                                    <Typography variant="caption">
                                        support@futaapredict.com
                                    </Typography>
                                </Link>

                                <Link
                                    to="#"
                                    underline="hover"
                                    onClick={(e) => {
                                        window.location.href = 'tel:+254745406125';
                                        e.preventDefault();
                                    }}
                                >
                                    <Typography variant="caption">
                                        +254-745-406125
                                    </Typography>
                                </Link>
                            </Box>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography
                                component="div"
                                color="white"
                                variant="h6"
                            >
                                Links
                            </Typography>

                            {/* <Typography variant="caption"></Typography> */}
                            <Box sx={{ display: "flex", flexDirection: "column" }} component="nav">
                                <Link to="/active_tips?type=top" underline="hover"><Typography variant="caption">Popular Tips</Typography></Link>
                                <Link to="/active_tips" underline="hover"><Typography variant="caption">Latest Tips</Typography></Link>
                                <Link to="/top_tipsters" underline="hover"><Typography variant="caption">Top Tipsters</Typography></Link>
                                <Link to="/jackpot" underline="hover"><Typography variant="caption">Jackpot</Typography></Link>
                                <Link to="/jackpot/sportpesa-mega-jackpot" underline="hover"><Typography variant="caption">Sportpesa Mega Jackpot</Typography></Link>
                                <Link to="/jackpot/sportpesa-mid-week-jackpot" underline="hover"><Typography variant="caption">Sportpesa Mid Week Jackpot</Typography></Link>
                                <Link to="/jackpot/betika-mega-jackpot" underline="hover"><Typography variant="caption">Betika Mega Jackpot</Typography></Link>
                                <Link to="/jackpot/betika-mid-week-jackpot" underline="hover"><Typography variant="caption">Betika Mid Week Jackpot</Typography></Link>

                            </Box>
                        </Box>

                        <Box>
                            News
                        </Box>


                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography
                            variant="subtitle2"
                            fontWeight={300}
                            fontSize="small"
                            color="darkgrey"
                        >
                            Copyright &copy; 2022-{new Date().getFullYear()} FutaaPredict
                        </Typography>
                    </Box>
                </Box>
            </HContainer>

            <CookieConsent
                location="bottom"
                buttonText="Accept!!"
                cookieName="cookieUse"
                style={{ background: '#000', fontFamily: 'GT Walsheim Pro' }}
                buttonStyle={{ color: '#1565C0', fontSize: '13px' }}
                expires={150}
            >
                This website uses cookies to enhance the user experience.{' '}
            </CookieConsent>
        </Box>
    );
}
