import React from 'react';
import Box from '@mui/material/Box';
import { Container, Grid, Paper, Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';

import { useNavigate, useSearchParams } from 'react-router-dom';

import axios from '../../api/axios';
import { ActiveTip } from '../../components/profile/ActiveTips';
import { HContainer } from '../../components/TopTipsters';
import PageHeader from '../../components/PageHeader';
import BreadCrumbView from '../../components/app_bar/BreadCrumbView';
import Title from '../../components/Title';

export default class ActiveTipsPaginatedComponent extends React.Component {
    constructor(props) {
        super(props);

        const [query, _] = props.query;

        let filters;
        if (query.get('tips')) {
            filters = {
                gameA: query.get('team_a'),
                gameB: query.get('team_b')
            };
        }

        this.state = {
            activeTips: [],
            tipFilters: filters,
            query: query,
            page: 1,
            nextPage: null,
            prevPage: null,
            defaultUrl: '/v2/api/slip/data/?',
            url: '/v2/api/slip/data/?',
            numPages: null
        };

        this.handleLoadActiveTips = this.handleLoadActiveTips.bind(this);
        this.handleLoadNextPage = this.handleLoadNextPage.bind(this);
        this.handleLoadPrevPage = this.handleLoadPrevPage.bind(this);
        this.handleProcessFilters = this.handleProcessFilters.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
    }

    handleProcessFilters(baseUrl) {
        console.log(baseUrl);
        const { filters, query } = this.state;

        const top = query.get('type') === 'top';
        const gameA = query.get('team_a');
        const gameB = query.get('team_b');

        if (gameA) {
            baseUrl += `&home_team=${gameA}`;
        }
        if (gameB) {
            baseUrl += `&away_team=${gameB}`;
        }

        if (filters) {
            if (filters.username) {
                baseUrl += `&username=${filters.username}`;
            }
        }

        if (top) {
            baseUrl += '&top=1';
        }

        return baseUrl;
    }

    handleLoadNextPage() {
        const { defaultUrl, nextPage } = this.state;

        let baseUrl = this.handleProcessFilters(defaultUrl + `p=${nextPage}`);

        this.setState({
            url: baseUrl
        });
    }

    handleLoadPrevPage() {
        const { defaultUrl, prevPage } = this.state;

        let baseUrl = this.handleProcessFilters(defaultUrl + `p=${prevPage}`);

        this.setState({
            url: baseUrl
        });

        this.handleLoadActiveTips();
    }

    handleLoadActiveTips() {
        const { query, defaultUrl } = this.state;

        this.setState({
            activeTips: []
        });

        const top = query.get('type') === 'top';

        const options = {
            method: 'GET',
            url: this.handleProcessFilters(defaultUrl)
        };

        console.log(this.state.url);

        axios
            .request(options)
            .then((response) => {
                const data = response.data;
                this.setState({
                    activeTips: data.results,
                    nextPage: data.next,
                    prevPage: data.previous,
                    numPages: data.num_pages
                });
            })
            .catch((error) => {
                alert("Error: Can't Find Page");
            });
    }

    handleChangePage(e, value) {
        const { query } = this.state;

        this.setState({
            page: value,
            activeTips: []
        });

        // e.preventDefault();

        console.log(value);

        const { defaultUrl, page } = this.state;

        let baseUrl = this.handleProcessFilters(defaultUrl + `p=${value}`);

        console.log(baseUrl);

        const options = {
            method: 'GET',
            url: baseUrl
        };

        axios
            .request(options)
            .then((response) => {
                const data = response.data;
                this.setState({
                    activeTips: data.results,
                    nextPage: data.next,
                    prevPage: data.previous,
                    numPages: data.num_pages
                });
                console.log('Loaded');
            })
            .catch((error) => {
                alert("Error: Can't Find Page");
                console.log('Error');
            });
    }

    componentDidMount() {
        this.handleLoadActiveTips();
    }

    render() {
        const { activeTips } = this.state;
        const { navigate } = this.props;

        console.log(activeTips[0]);

        const activeTipsList = [];

        if (!activeTips || activeTips.length === 0) {
            for (let i = 0; i < 2; i++)
                activeTipsList.push(
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        sx={{
                            p: 1,
                            '@media screen and (max-width: 600px)': {
                                width: '100%'
                            }
                        }}
                    >
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={257}
                        />
                    </Grid>
                );
        } else
            for (let i = 0; i < activeTips.length; i++) {
                activeTipsList.push(
                    <ActiveTip
                        slip={activeTips[i]}
                        navigate={navigate}
                    />
                );
            }

        return (
            <Box sx={{ p: 0, m: 1, minHeight: 500 }}>
                <Title title=" FutaaPredict| Active Tips" />
                <HContainer>
                    <BreadCrumbView sx={1} />

                    <Paper
                        square
                        elevation={1}
                        sx={{
                            py: 1,
                            mx: 0,
                            my: 1,
                            width: '100%',
                            height: '100%',
                            borderRadius: 1
                        }}
                    >
                        <PageHeader
                            sx={{ pl: 0 }}
                            title="Active Tips"
                            description="View user information"
                        />

                        <Container sx={{ mt: 1, p: 0 }}>
                            <Box
                                sx={{
                                    p: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}
                            >
                                <Box
                                    sx={{
                                        p: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={this.state.numPages}
                                            page={this.state.page}
                                            color="primary"
                                            onChange={this.handleChangePage}
                                            variant="outlined"
                                            shape="rounded"
                                        />
                                    </Stack>
                                </Box>

                                <Grid
                                    container
                                    spacing={0}
                                    sx={{
                                        m: 0,
                                        p: 0,
                                        maxWidth: '100%'
                                    }}
                                >
                                    {activeTipsList.map((t) => t)}
                                </Grid>

                                <Box
                                    sx={{
                                        p: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={this.state.numPages}
                                            page={this.state.page}
                                            color="primary"
                                            onChange={this.handleChangePage}
                                            variant="outlined"
                                            shape="rounded"
                                        />
                                    </Stack>
                                </Box>
                            </Box>
                        </Container>
                    </Paper>
                </HContainer>
            </Box>
        );
    }
}

// export default function ActiveTipsPage(props) {
//     const navigate = useNavigate();
//     const query = useSearchParams();
//     return (
//             <ActiveTipsPageComponent query={query} navigate={navigate} {...props} />
//     );
// }
