import React from 'react';
import { useNavigate } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import BreadCrumbView from '../../components/app_bar/BreadCrumbView';
import { HContainer } from '../../components/TopTipsters';
import { Chip, CircularProgress, Link, Paper, Typography } from '@mui/material';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
import { StyledDataGrid } from '../../components/data_lists/DataListComponents';
import axios from '../../api/axios';

function createData(payment, id) {
    return {
        id: id,
        amount: payment.amount,
        slip: payment.slip,
        status: payment.status,
        date: Date.parse(payment.date)
    };
}

const parseDate = (date) => {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

const dataColumns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 10
    },
    {
        field: 'amount',
        headerName: 'Amount',
        minWidth: 120,
        flex: 1
    },
    {
        field: 'date',
        headerName: 'Date',
        minWidth: 150,
        flex: 1,
        renderCell: (params) => (
            <Typography variant="body1">
                {parseDate(new Date(params.row.date))}
            </Typography>
        )
    },
    {
        field: 'slip',
        headerName: 'Slip ID',
        minWidth: 80,
        flex: 1,
        editable: false,
        renderCell: (params) => (
            <Link
                fontWeight="bold"
                fontFamily="GT Walsheim Pro"
                fontSize="small"
                underline="hover"
                style={{ color: 'black' }}
                to={`/dashboard/slip/${params.row.slip}`}
            >
                {params.row.slip}
            </Link>
        )
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 130,
        flex: 1,
        renderCell: (params) => {
            const value = params.row.status;
            const size = 'small',
                variant = 'outlined';
            if (value === 'W') {
                return (
                    <Chip
                        label="pending"
                        color="primary"
                        variant={variant}
                        size={size}
                    />
                );
            }

            if (value === 'P') {
                return (
                    <Chip
                        label="paid"
                        color="success"
                        variant={variant}
                        size={size}
                    />
                );
            }

            return <Chip label="unpaid" variant={variant} size={size} />;
        }
    }
];

class TipsterPaymentsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            payments: [],
            username: null,
            loading: true
        };

        this.handleLoadPayments = this.handleLoadPayments.bind(this);
        this.handleViewTip = this.handleViewTip.bind(this);
    }

    componentDidMount() {
        this.handleLoadPayments();
    }

    handleViewTip(e) {}

    handleLoadPayments() {
        const { cookies } = this.props;
        const token = cookies.get('token');

        const options = {
            method: 'GET',
            url: '/api/finance/get_payments',
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        axios
            .request(options)
            .then((response) => {
                const data = response.data;
                const payments = data['payments'];
                let id = 1;
                payments.map((payment) => {
                    this.setState((prevState) => ({
                        payments: [
                            ...prevState.payments,
                            createData(payment, id++)
                        ]
                    }));
                    return null;
                });
                this.setState({ loading: false });
                console.log(payments);
            })
            .catch(() => {
                this.setState({
                    loading: false
                });
            });
    }

    render() {
        return (
            <Box
                sx={{
                    p: 1,
                    m: 0,
                    gap: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                    // maxWidth: 700
                }}
            >
                <BreadCrumbView sx={1} />
                <HContainer>
                    <Paper
                        square
                        elevation={1}
                        sx={{
                            p: 1,
                            mx: 0,
                            my: 1,
                            width: '100%',
                            height: '100%',
                            borderRadius: 1
                        }}
                    >
                        <PageHeader
                            sx={{ pl: 2 }}
                            title="Payments"
                            description="Passed payments"
                            disableDivider
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                minHeight: 500
                            }}
                        >
                            {this.state.loading ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                        height: 450,
                                        m: 0
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <StyledDataGrid
                                    rows={this.state.payments}
                                    columns={dataColumns}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                />
                            )}
                        </Box>
                    </Paper>
                </HContainer>
            </Box>
        );
    }
}

function TipsterPayments(props) {
    const navigate = useNavigate();

    return <TipsterPaymentsComponent {...props} navigate={navigate} />;
}

export default withCookies(TipsterPayments);
