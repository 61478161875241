import React from 'react';
import Box from '@mui/material/Box';
import { Button, Link, TextField, Typography } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { withCookies } from 'react-cookie';

import * as Validator from 'validatorjs';

import { modalStyle } from '../../components/customStyles';
import axios from '../../api/axios';
import { LoadingButton } from '@mui/lab';

import ReactGA from 'react-ga';

import { GOOGLE_ANALYTICS_KEY } from '../../data';

ReactGA.initialize(GOOGLE_ANALYTICS_KEY);

const inputFontStyle = {
    fontWeight: '500',
    marginBottom: 5
};

const labelFontStyle = {
    fontWeight: '500'
};

class RegisterComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            email: '',
            phoneNumber: '',
            password: '',
            confirmPassword: '',
            profileImage: null,
            profileImageName: '',

            emailError: null,
            usernameError: null,
            phoneError: null,
            passwordError: null,
            confirmError: null,

            uploading: false
        };

        this.handleRegister = this.handleRegister.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
    }

    componentDidMount() {
        ReactGA.event({
            "category": "User",
            "action": "Open Registration Form"
        })
    }

    handleRegister() {
        const { cookies } = this.props;

        let data = {
            username: this.state.username,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword
        };

        let rules = {
            username: 'required',
            email: 'required|email',
            phoneNumber: 'required|digits:10',
            password: 'required|min:8',
            confirmPassword: 'required|min:8'
        };

        let validation = new Validator(data, rules);
        if (validation.fails(null)) {
            const emailErrors = validation.errors.first('email');
            const usernameErrors = validation.errors.first('username');
            const phoneErrors = validation.errors.first('phoneNumber');
            const passwordErrors = validation.errors.first('password');
            let confirmErrors = validation.errors.first('confirmPassword');

            this.setState({
                emailError: emailErrors,
                usernameError: usernameErrors,
                phoneError: phoneErrors,
                passwordError: passwordErrors,
                confirmError: confirmErrors
            });
            return;
        } else if (this.state.confirmPassword !== this.state.password) {
            this.setState({
                confirmError: 'Must be similar to password'
            });
            return;
        } else {
            this.setState({
                emailError: null,
                usernameError: null,
                phoneError: null,
                passwordError: null,
                confirmError: null
            });
        }

        const options = {
            method: 'POST',
            url: '/api/account/register',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                username: data.username,
                password: data.password,
                email: data.email,
                phone_number: data.phoneNumber
            }
        };

        axios
            .request(options)
            .then((response) => {
                ReactGA.event({
                    "category": "User",
                    "action": "Created an account"
                });
                
                let resData = response.data;
                // console.log(resData);

                cookies.set('token', resData['token']);
                cookies.set('refresh', resData['refresh']);

                // noinspection JSUnresolvedFunction
                this.props.handleRegister();
            })
            .catch((error) => {
                const errorData = error.response.data;
                if (errorData['username']) {
                    this.setState({
                        usernameError: errorData['username']
                    });
                }
                if (errorData['email']) {
                    this.setState({
                        emailError: errorData['email']
                    })
                }
            });
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleFileUpload(e) {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        const { name } = file;
        this.setState({
            profileImageName: name,
            profileImage: file
        });
    }

    render() {
        const { hideLogin, handleLogin } = this.props;

        return (
            <Box sx={modalStyle}>
                <Box
                    sx={{
                        paddingTop: 5,
                        '@media screen and (max-width: 40em)': {
                            paddingTop: 15
                        },
                        paddingBottom: '0 !important',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        id="modal-modal-register-title"
                        variant="h5"
                        component="div"
                        fontWeight={500}
                    >
                        Registration
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        gap: 1,
                        paddingX: 6,
                        paddingY: 1
                    }}
                >
                    <TextField
                        error={this.state.emailError}
                        helperText={this.state.emailError}
                        label="Email"
                        variant="standard"
                        autoComplete="off"
                        required
                        InputLabelProps={{ style: { ...labelFontStyle } }}
                        inputProps={{ style: { ...inputFontStyle } }}
                        type="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                    />

                    <TextField
                        error={this.state.usernameError}
                        helperText={this.state.usernameError}
                        label="Username"
                        variant="standard"
                        autoComplete="off"
                        required
                        InputLabelProps={{ style: { ...labelFontStyle } }}
                        inputProps={{ style: { ...inputFontStyle } }}
                        type="text"
                        name="username"
                        value={this.state.username}
                        onChange={this.handleInputChange}
                    />

                    <TextField
                        error={this.state.phoneError}
                        helperText={this.state.phoneError}
                        label="Phone Number"
                        variant="standard"
                        autoComplete="off"
                        required
                        InputLabelProps={{ style: { ...labelFontStyle } }}
                        inputProps={{ style: { ...inputFontStyle } }}
                        type="tel"
                        name="phoneNumber"
                        value={this.state.phoneNumber}
                        onChange={this.handleInputChange}
                    />

                    <TextField
                        error={this.state.passwordError}
                        helperText={this.state.passwordError}
                        label="Password"
                        variant="standard"
                        autoComplete="off"
                        required
                        InputLabelProps={{ style: { ...labelFontStyle } }}
                        style={{ ...inputFontStyle }}
                        type="password"
                        inputProps={{
                            autocomplete: 'new-password',
                            form: {
                                autocomplete: 'off'
                            }
                        }}
                        name="password"
                        value={this.state.password}
                        onChange={this.handleInputChange}
                    />

                    <TextField
                        error={this.state.confirmError}
                        helperText={this.state.confirmError}
                        label="Confirm Password"
                        variant="standard"
                        autoComplete="off"
                        required
                        InputLabelProps={{ style: { ...labelFontStyle } }}
                        style={{ ...inputFontStyle }}
                        type="password"
                        inputProps={{
                            autocomplete: 'new-password',
                            form: {
                                autocomplete: 'off'
                            }
                        }}
                        name="confirmPassword"
                        value={this.state.confirmPassword}
                        onChange={this.handleInputChange}
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 1,
                            alignItems: 'center'
                        }}
                    >
                        <Button
                            variant="outlined"
                            component="label"
                            startIcon={<UploadFileIcon />}
                        >
                            Upload Profile
                            <input
                                type="file"
                                hidden
                                onChange={this.handleFileUpload}
                            />
                        </Button>

                        <Typography
                            component="label"
                            fontSize={10}
                            variant="subtitle2"
                        >
                            {'Optional*'}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            p: 1,
                            m: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            gap: 0
                        }}
                    >
                        <LoadingButton
                            variant="contained"
                            sx={{ minWidth: 200, marginBottom: 1 }}
                            disableElevation
                            loading={this.state.uploading}
                            onClick={this.handleRegister}
                        >
                            Register
                        </LoadingButton>

                        <Typography
                            variant="subtitle2"
                            alignSelf="center"
                            style={{ textAlign: 'center' }}
                        >
                            By creating an account, you agree to our
                            <Link href="#" underline="none" variant="body2">
                                {' '}
                                privacy policy.
                            </Link>
                        </Typography>
                    </Box>
                </Box>

                {hideLogin ? null : (
                    <Box
                        sx={{
                            height: 40,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                        style={{ background: '#e0e0e0' }}
                    >
                        <Typography
                            variant="subtitle2"
                            fontSize={15}
                            alignSelf="center"
                            style={{ textAlign: 'center' }}
                        >
                            Already have an account?
                            <Link
                                href="#"
                                underline="none"
                                variant="body2"
                                onClick={handleLogin}
                            >
                                {' '}
                                Login.
                            </Link>
                        </Typography>
                    </Box>
                )}
            </Box>
        );
    }
}

function Register(props) {
    return <RegisterComponent {...props} />;
}

export default withCookies(Register);
