import React from 'react';

import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    Link,
    List,
    ListItem,
    ListItemText,
    OutlinedInput,
    Paper,
    Skeleton,
    Typography
} from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import PageHeader from '../components/PageHeader';
import { HContainer } from '../components/TopTipsters';
import axios from '../api/axios';
import { LoadingButton } from '@mui/lab';
import Title from '../components/Title';
import { stringAvatar } from './profile/Profile';

function ActiveTipComponentWithResult(props) {
    const tips = props.data;

    const tipCount = [];
    for (let i = 0; i < tips.length; i++) {
        const tip = tips[i];
        tipCount.push(
            <ListItem key={i} disablePadding divider>
                <ListItemText
                    primary={`${tip['Game']} | ${tip['League']}`}
                    secondary={tip['Tip']}
                />
            </ListItem>
        );
    }

    return (
        <Grid item sx={{ m: 0, p: 0 }}>
            <Card
                elevation={0}
                variant="outlined"
                sx={{
                    m: 0,
                    minWidth: '100%',
                    maxWidth: '100%',
                    width: 270
                }}
            >
                <CardContent sx={{ maxWidth: '100%', minHeight: 200 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            p: 0
                        }}
                    >
                        <List sx={{ width: '100%' }}>{tipCount}</List>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    );
}

export default class CustomerBoughtTips extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phoneNumber: null,
            phoneError: null,

            mpesaCode: null,
            mpesaError: null,

            loading: false,

            visible: false,

            tips: []
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.handleMpesaTransactionChange =
            this.handleMpesaTransactionChange.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({
            phoneError: null,
            mpesaError: null,
            loading: true,
            visible: false
        });

        const phone = this.state.phoneNumber;
        const mpesa = this.state.mpesaCode;

        if (phone == null || !(phone.length === 10 || phone.length === 9)) {
            this.setState({
                phoneError: 'Enter Valid Phone Number',
                loading: false
            });
            return;
        }

        if (mpesa == null || mpesa.length < 8) {
            this.setState({
                mpesaError: 'Invalid transaction code',
                loading: false
            });
            return;
        }

        const mpesa_code = mpesa.trim().split(" ")[0];

        const getPhoneNumber = (phone) => {
            if (phone.length === 10) return `254${phone.substring(1)}`;
            return `254${phone}`;
        };

        var config = {
            method: 'post',
            url: `/api/manage/find-slip/${getPhoneNumber(phone)}/${mpesa_code
                .toUpperCase()
                .trim()}`
        };

        axios(config)
            .then((response) => {
                const data = response.data;
                this.setState({
                    tips: data.tip,
                    visible: true
                });
            })
            .catch(function (error) {
                const response = error.response;
                if (response.data.message) {
                    alert(response.data.message);
                }

                console.log(error);
            })
            .finally(() => {
                this.setState({
                    loading: false
                });
            });
    }

    handlePhoneNumberChange(e) {
        const phone = e.target.value;
        this.setState({
            phoneNumber: phone
        });
    }

    handleMpesaTransactionChange(e) {
        const mpesa = e.target.value;
        this.setState({
            mpesaCode: mpesa
        });
    }

    render() {
        return (
            <Box>
                <Title title={`FutaaPredict| Find My Tip`} />
                <HContainer>
                    <PageHeader
                        sx={{
                            pl: 0
                        }}
                        title="Find My Tip"
                        description="Enter your details to get your recently bought tip"
                    />
                    <Box
                        sx={{
                            p: 1,
                            m: 1,
                            display: 'flex',
                            gap: 1,
                            flexDirection: 'column',
                            maxWidth: 600
                        }}
                    >
                        <Box sx={{ my: 1 }}>
                            <Paper
                                square
                                elevation={1}
                                sx={{
                                    p: 1,
                                    mx: 0,
                                    my: 1,
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: 1
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1
                                    }}
                                >
                                    <Typography>
                                        Enter Transaction Details
                                    </Typography>

                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-amount">
                                            Phone
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            error={this.state.phoneError}
                                            helperText={this.state.phoneError}
                                            value={this.state.phoneNumber}
                                            onChange={
                                                this.handlePhoneNumberChange
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    +254
                                                </InputAdornment>
                                            }
                                            label="Mpesa Phone Number"
                                        />
                                        <FormHelperText error>
                                            {this.state.phoneError}
                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-amount">
                                            Mpesa Transaction | Mpesa
                                            Transaction Code
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            error={this.state.mpesaError}
                                            helperText={this.state.mpesaError}
                                            value={this.state.mpesaCode}
                                            onChange={
                                                this
                                                    .handleMpesaTransactionChange
                                            }
                                            label="Mpesa Transaction Code"
                                        />
                                        <FormHelperText error>
                                            {this.state.mpesaError}
                                        </FormHelperText>
                                    </FormControl>

                                    <LoadingButton
                                        variant="contained"
                                        disableElevation
                                        disabled={
                                            process.env.NODE_ENV ===
                                                'production' && false
                                        }
                                        loading={this.state.loading}
                                        onClick={this.handleSubmit}
                                    >
                                        Find
                                    </LoadingButton>
                                </Box>
                            </Paper>
                        </Box>

                        <Paper
                            square
                            elevation={1}
                            sx={{
                                p: 1,
                                mx: 0,
                                my: 1,
                                width: '100%',
                                height: '100%',
                                borderRadius: 1
                            }}
                        >
                            {this.state.visible &&
                                (this.state.tips ? (
                                    <ActiveTipComponentWithResult
                                        data={this.state.tips}
                                    />
                                ) : (
                                    <Box
                                        sx={{
                                            height: 200,
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 1
                                        }}
                                    >
                                        <Skeleton />
                                        <Skeleton />
                                        <Skeleton sx={{ height: 100 }} />
                                    </Box>
                                ))}
                        </Paper>
                    </Box>
                </HContainer>
            </Box>
        );
    }
}
