import React from 'react';

import { useCookies } from 'react-cookie';
import { Navigate, useLocation } from 'react-router-dom';

export default function RequireAuth({ children }) {
    const [cookies] = useCookies(['token']);
    let location = useLocation();

    if (!cookies.token) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return children;
}
