import React from 'react';

import { Breadcrumbs, Link, Paper } from '@mui/material';
import { HContainer } from '../TopTipsters';
import Box from '@mui/material/Box';

import useBreadcrumbs from 'use-react-router-breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export default function BreadCrumbView(props) {
    const breadcrumbs = useBreadcrumbs();

    return (
        <Box sx={{ p: 0, mx: props.sx ? 0 : 1 }}>
            <HContainer>
                <Paper
                    square
                    elevation={1}
                    sx={{
                        p: 1,
                        mx: 0,
                        my: 1,
                        width: '100%',
                        height: '100%',
                        borderRadius: 1
                    }}
                >
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                    >
                        {breadcrumbs.map(({ match, breadcrumb }) => (
                            <Link
                                underline="hover"
                                variant="body2"
                                fontFamily="GT Walsheim Pro"
                                fontWeight="bold"
                                fontSize="small"
                                to={match.pathname}
                            >
                                {breadcrumb}
                            </Link>
                        ))}
                    </Breadcrumbs>
                </Paper>
            </HContainer>
        </Box>
    );
}
