import React from 'react';
import { Button, Paper, Typography, Grid } from '@mui/material';
import Box from '@mui/material/Box';

import PageHeader from './PageHeader';
import { useNavigate } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { HContainer } from './TopTipsters';

import { Days } from '../data';

import Carousel from 'react-material-ui-carousel';
import axios from '../api/axios';

function UpcomingMatch(props) {
    const { time, teamA, teamB, league, tips, game_state, home_goals, away_goals } = props.data;

    const { navigate } = props;

    const handleViewTips = () => navigate(`/active_tips?tips=1&team_a=${teamA}&team_b=${teamB}`);

    return (
        <Box
            sx={{
                width: '30%',
                '@media screen and (max-width: 600px)': {
                    width: '100%'
                },
                maxHeight: 120,
                // minWidth: 280
                minWidth: '100%'
            }}
        >
            <Paper sx={{ width: '100%' }}>
                <Box
                    sx={{
                        p: 0,
                        m: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Box sx={{ backgroundColor: '#f6003b', px: 2 }}>
                            <Typography variant="subtitle2" component="div" color="white">
                                {tips} Tip{tips !== 1 ? 's' : ''}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                px: 1,
                                m: '0',
                                backgroundColor: '#06001E'
                            }}
                        >
                            <Typography
                                variant="subtitle2"
                                color={time === 'Live' ? '#FFD565' : 'white'}
                                alignSelf="center"
                                css={{
                                    animation: '1.5s linear infinite',
                                    '@keyframes animate': {
                                        '0%': {
                                            opacity: 0.1
                                        },
                                        '50%': {
                                            opacity: 0.7
                                        },
                                        '100%': {
                                            opacity: 0
                                        }
                                    }
                                }}
                            >
                                {time === 'Live' ? `Live '${game_state} (${home_goals}:${away_goals})` : time}
                            </Typography>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            m: 0,
                            p: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            gap: 1,
                            flexWrap: 'wrap'
                        }}
                    >
                        <Typography variant="subtitle2" style={{ textTransform: 'uppercase' }}>
                            {teamA}
                        </Typography>
                        <Typography variant="caption">vs</Typography>
                        <Typography variant="subtitle2" style={{ textTransform: 'uppercase' }}>
                            {teamB}
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="button" display="block" gutterBottom>
                            {league}
                        </Typography>
                    </Box>

                    <Box sx={{ margin: '0 auto' }}>
                        <Button onClick={handleViewTips} variant="outlined" sx={{ borderRadius: 0 }}>
                            View Tips
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}

class UpcomingMatchesComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            matches: [],
            phoneSize: false
        };

        this.handleLoadUpcomingMatches = this.handleLoadUpcomingMatches.bind(this);
    }

    handleLoadUpcomingMatches() {
        const options = {
            method: 'GET',
            url: '/api/home/top-games'
        };

        const createMatch = (game) => {
            const data = game['data'];
            const date = new Date(data['game_date']);

            const day = new Date().getDay() === date.getDay() ? 'Today' : Days[date.getDay()];

            const parseMinutes = (mins) => {
                if (mins <= 9) return `0${mins}`;
                return `${mins}`;
            };

            const time = `${date.getHours()}:${parseMinutes(date.getMinutes())}`;

            let game_time = `${day} ${time}`;

            const home_goals = data['home_goals'],
                away_goals = data['away_goals'];

            if (home_goals !== null && away_goals !== null) {
                game_time = 'Live';
            }

            return {
                time: game_time,
                teamA: data['home_team'],
                teamB: data['away_team'],
                league: data['league'],
                state: game['state'],
                tips: game['tips'],
                home_goals: data['home_goals'],
                away_goals: data['away_goals'],
                game_state: data['game_status_time']
            };
        };

        axios
            .request(options)
            .then((res) => {
                const games = res.data['games'];
                this.setState({
                    matches: []
                });
                games.map((game) => {
                    this.setState((prev) => ({
                        matches: [...prev.matches, createMatch(game)]
                    }));
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    componentDidMount() {
        this.handleLoadUpcomingMatches();

        this.interval = setInterval(() => this.handleLoadUpcomingMatches(), 1000 * 30);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        let matches = [];
        for (let i = 0; this.state.matches && i < this.state.matches.length; i++) {
            matches.push(
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <UpcomingMatch navigate={this.props.navigate} data={this.state.matches[i]} />
                </Grid>
            );
        }

        return (
            <Box sx={{ p: 0, m: 1 }}>
                <HContainer>
                    <Paper
                        square
                        elevation={1}
                        sx={{
                            p: 1,
                            mx: 0,
                            my: 1,
                            width: '100%',
                            borderRadius: 1
                        }}
                    >
                        <PageHeader title="Upcoming Top Games" disableDivider />
                    </Paper>

                    {this.state.phoneSize ? (
                        <Carousel>{matches}</Carousel>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 1,
                                justifyContent: 'left',
                                flexWrap: 'wrap'
                            }}
                        >
                            <Grid container spacing={1}>
                                {matches}
                            </Grid>
                        </Box>
                    )}
                </HContainer>
            </Box>
        );
    }
}

function UpcomingMatches(props) {
    const navigate = useNavigate();
    return <UpcomingMatchesComponent {...props} navigate={navigate} />;
}

export default withCookies(UpcomingMatches);
