import React from 'react';
import Box from '@mui/material/Box';
import {
    AppBar,
    Link,
    Slide,
    Toolbar,
    Typography,
    useScrollTrigger
} from '@mui/material';

import PropTypes, { instanceOf } from 'prop-types';

import { useNavigate } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';

import AuthButtons from './AuthButtons';
import ProfileButton from './ProfileButton';
import axios from '../../api/axios';

import Logo from '../../assets/ft_logo.png';

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined
    });

    return (
        <Slide
            sx={{ p: 0, m: 0 }}
            appear={false}
            direction="down"
            in={!trigger}
        >
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func
};

class TopAppBarComponent extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        const { cookies } = this.props;

        this.state = {
            isLoggedIn: false,
            username: ''
        };

        this.handleRegister = this.handleRegister(this);
        this.handleLoginButton = this.handleLoginButton.bind(this);
        this.handleLogoutButton = this.handleLogoutButton.bind(this);
        this.updateAppBar = this.updateAppBar.bind(this);
    }

    updateAppBar() {
        const { cookies } = this.props;

        if (cookies.get('token')) {
            this.setState({ isLoggedIn: true });
        }

        const options = {
            method: 'GET',
            url: '/api/account/profile',
            headers: {
                Authorization: `Bearer ${cookies.get('token')}`
            }
        };

        axios
            .request(options)
            .then(function (response) {})
            .catch((error) => {
                // console.log(error.response.data);
                const data = error.response.status;
                if (data === 401) {
                    cookies.remove('token');
                }
            });
    }

    componentDidMount() {
        this.updateAppBar();
    }

    handleLoginButton() {
        this.updateAppBar();
        window.location.reload();
    }

    handleLogoutButton() {
        const { cookies } = this.props;

        this.setState({ isLoggedIn: false });

        cookies.remove('token', { path: '/' });

        this.updateAppBar();
        window.location.reload();
    }

    handleRegister() {}

    render() {
        const buttonStyleSx = {
            marginLeft: 1,
            marginRight: 0,
            height: 30
        };

        return (
            <AppBar
                position="sticky"
                elevation={0}
                sx={{
                    display: 'flex',
                    height: 45,
                    justifyContent: 'center'
                }}
                style={{
                    // background: '#06001e'
                }}
            >
                <Toolbar>
                    <Typography
                        variant="h6"
                        component="div"
                        fontSize={15}
                        sx={{ flexGrow: 1 }}
                    >
                        <Link to="/" underline="none" color="inherit">
                            {
                                <img
                                    alt="logo"
                                    loading="lazy"
                                    src={Logo}
                                    style={{
                                        maxHeight: 57,
                                        maxWidth: 200,
                                        borderRadius: 5,
                                        alignSelf: 'center',
                                        justifySelf: 'center',
                                        // marginTop: 7
                                    }}
                                />
                            }
                        </Link>
                    </Typography>
                    {/* {this.state.isLoggedIn ? (
                        <Box
                            sx={{
                                m: 0,
                                p: 0,
                                gap: 1,
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <ProfileButton
                                label={this.state.username}
                                handleLogout={this.handleLogoutButton}
                            />
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                m: 0,
                                p: 0,
                                gap: 1,
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <AuthButtons
                                sx={buttonStyleSx}
                                handleLogin={this.handleLoginButton}
                            />
                        </Box>
                    )} */}
                </Toolbar>
            </AppBar>
        );
    }
}

function TopAppBar(props) {
    let navigate = useNavigate();
    return <TopAppBarComponent {...props} navigate={navigate} />;
}

export default withCookies(TopAppBar);
