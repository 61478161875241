import React from 'react';

import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import Divider from '@mui/material/Divider';

import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';

import axios from '../../api/axios';
import { HContainer } from '../../components/TopTipsters';
import PersonalData from '../../components/account_settings/PersonalData';
import AccountData from '../../components/account_settings/AccountData';

class UserAccountSettingsComponent extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            profile: null,
            loading: false
        };

        this.handleUpdateInformation = this.handleUpdateInformation.bind(this);
        this.handleUpdateAccount = this.handleUpdateAccount.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
    }

    handleUpdateInformation() {
        const { cookies } = this.props;
        let token = cookies.get('token');

        this.setState({
            loading: true
        });

        const options = {
            method: 'GET',
            url: '/api/account/profile',
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        axios
            .request(options)
            .then((response) => {
                let data = response.data;

                this.setState({
                    user: data['user'],
                    profile: data['profile'],
                    loading: false
                });
            })
            .catch((error) => {
                if (error) console.log(error);
            });
    }

    componentDidMount() {
        this.handleUpdateInformation();
    }

    handleUpdateAccount(values) {
        const { cookies } = this.props;
        let token = cookies.get('token');

        this.setState({
            loading: true
        });

        console.log('Updating');

        const options = {
            method: 'POST',
            url: '/api/account/user/details',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: {
                name: values['name'],
                city: values['city']
            }
        };

        axios
            .request(options)
            .then(() => {
                this.handleUpdateInformation();
            })
            .catch((error) => {
                if (error) console.log(error.response);
            });
    }

    async handleChangePassword(values) {
        const { cookies } = this.props;
        let token = cookies.get('token');

        const options = {
            method: 'PUT',
            url: '/api/account/change-password',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: values
        };

        await axios
            .request(options)
            .then(() => {
                this.handleUpdateInformation();
            })
            .catch((error) => {
                console.log(error.response.data);
            });
    }

    handleChangePhone = (phoneNumber) => {
        // TODO Change phone number
        console.log(`To Change: ${phoneNumber}`);
    };

    handleChangeEmail = (email) => {
        // TODO Change email
        console.log(`To Change: ${email}`);
    };

    render() {
        return (
            <Box
                sx={{
                    p: 1,
                    m: 0,
                    gap: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}
            >
                <HContainer>
                    <Paper
                        square
                        elevation={1}
                        sx={{
                            p: 1,
                            mx: 0,
                            my: 1,
                            width: '100%',
                            height: '100%',
                            borderRadius: 1
                        }}
                    >
                        <PersonalData
                            user={this.state.user}
                            profile={this.state.profile}
                            onUpdate={this.handleUpdateAccount}
                            loading={this.state.loading}
                        />
                    </Paper>
                </HContainer>

                <HContainer>
                    <Paper
                        square
                        elevation={1}
                        sx={{
                            p: 1,
                            mx: 0,
                            my: 1,
                            width: '100%',
                            height: '100%',
                            borderRadius: 1
                        }}
                    >
                        <AccountData
                            user={this.state.user}
                            profile={this.state.profile}
                            changePass={this.handleChangePassword}
                            changePhone={this.handleChangePhone}
                            changeEmail={this.handleChangeEmail}
                        />
                    </Paper>
                </HContainer>
                <Divider />
            </Box>
        );
    }
}

function UserAccountSettings(props) {
    let navigate = useNavigate();
    let location = useLocation();

    return (
        <UserAccountSettingsComponent
            {...props}
            navigate={navigate}
            location={location}
        />
    );
}

export default withCookies(UserAccountSettings);
