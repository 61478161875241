import React from 'react';
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    Container,
    Grid,
    Link,
    List,
    ListItem,
    ListItemText,
    Paper,
    Skeleton,
    Typography
} from '@mui/material';

import Rating from '@mui/material/Rating';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import PageHeader from '../PageHeader';
import { useNavigate } from 'react-router-dom';
import { HContainer } from '../TopTipsters';
import { stringAvatar } from '../../routes/profile/Profile';

class ActiveTipComponent extends React.Component {
    constructor(props) {
        super(props);

        const { slip } = this.props;

        this.state = {
            slip: slip ? slip : null,
            tips: slip ? slip.tips : [],
            user: slip ? slip.user : [],
            accumulator: slip ? slip.accumulator : 0,
            meta: slip ? slip.metadata : {},
            rating: slip ? slip.metadata.rating / 10 : 0
        };

        this.handleBuyTip = this.handleBuyTip.bind(this);
        this.handleBuyTipNg = this.handleBuyTipNg.bind(this);
        this.handleClickChip = this.handleClickChip.bind(this);

        console.log(slip);
    }

    handleBuyTip() {
        this.props.navigate(`/buy_tip/${this.state.slip['id']}`);
    }

    handleBuyTipNg() {
        this.props.navigate(`/buy_tip_ng/${this.state.slip['id']}`);
    }

    handleClickChip(user) {
        const { navigate } = this.props;
        navigate(`/profile/${user}`);
    }

    render() {
        const IconChip = ({ username }) => {
            return (
                <Link
                    // to={`/profile/${username}`}
                    to={'/'}
                    underline="hover"
                    color="black"
                >
                    <Chip
                        size="small"
                        style={{
                            cursor: 'pointer'
                        }}
                        sx={{
                            '& .MuiChip-label': {
                                fontFamily: 'GT Walsheim Pro',
                                fontWeight: '500',
                                fontSize: 'small'
                            }
                        }}
                        avatar={<Avatar style={{ color: 'white', fontWeight: 'bold' }} {...stringAvatar(username)} />}
                        label={username}
                    />
                </Link>
            );
        };

        const getYield = (num) => {
            const _val = Math.round((num + Number.EPSILON) * 100) / 100;
            const sign = _val >= 0 ? '+' : '';
            return `${sign}${_val}`;
        };

        try {
            if (!this.state.slip) {
                return <>Empty</>;
            }

            const myDate = (_date) => {
                const date = new Date(Date.parse(_date));
                // noinspection JSCheckFunctionSignatures
                return new Intl.DateTimeFormat('en-US', {
                    dateStyle: 'medium'
                }).format(date);
            };

            const tipCount = [];
            if (this.state.slip)
                for (let i = 0; i < this.state.tips.length; i++) {
                    const game_date = new Date(this.state.tips[i]['game_date']);
                    const hours = (game_date - Date.now()) / 36e5;
                    var label = '';
                    if (hours > 0) {
                        label = 'Not Started';
                    } else if (hours > -3) {
                        label = 'In play';
                    } else {
                        label = 'Played';
                    }

                    // label = this.props.buy ? label : null;

                    tipCount.push(
                        <ListItem key={i} disablePadding divider>
                            <ListItemText
                                secondaryTypographyProps={{
                                    color: 'black'
                                }}
                                primaryTypographyProps={{
                                    color: 'black',
                                    fontSize: 'smaller'
                                }}
                                primary={`${this.state.tips[i]['home_team']} vs ${this.state.tips[i]['away_team']}`}
                            />
                        </ListItem>
                    );
                }

            return (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{
                        p: 1,
                        '@media screen and (max-width: 600px)': {
                            width: '100%'
                        }
                    }}
                >
                    <Card
                        elevation={0}
                        variant="outlined"
                        sx={{
                            display: 'inline-block',
                            m: 0,
                            width: '100%'
                        }}
                    >
                        <CardContent sx={{ maxWidth: '100%', minHeight: 220 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    p: 0
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        p: 0,
                                        m: 0,
                                        gap: 1,
                                        alignItems: 'center'
                                    }}
                                >
                                    <IconChip
                                        // username={this.state.user['username']}
                                        username={'futaapredict'}
                                    />

                                    <Chip
                                        size="small"
                                        label={this.state.slip ? myDate(this.state.slip['date_created']) : null}
                                        sx={{
                                            '& .MuiChip-label': {
                                                fontFamily: 'GT Walsheim Pro',
                                                fontWeight: '500',
                                                fontSize: 'small'
                                            }
                                        }}
                                    />
                                </Box>

                                <Tooltip
                                    title="Tipsters's Rating and last 10 days odds."
                                    placement="bottom-start"
                                    enterTouchDelay={100}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: 1,
                                            mt: 1
                                        }}
                                    >
                                        <Rating
                                            name="half-rating-read"
                                            size="small"
                                            // defaultValue={
                                            //     (this.state.meta['rating'] + 40) / 20
                                            // }
                                            defaultValue={4.5}
                                            precision={0.1}
                                            readOnly
                                        />

                                        {/* <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                        >
                                            {`(${this.state.meta['rating']}% ${getYield(this.state.meta['month'])})`}
                                        </Typography> */}
                                    </Box>
                                </Tooltip>

                                <List sx={{ width: '100%' }}>{tipCount}</List>
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 1,
                                    width: 1
                                }}
                            >
                                {' '}
                                {
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 1
                                        }}
                                    >
                                        {' '}
                                        <Button
                                            variant="outlined"
                                            disableElevation
                                            sx={{
                                                display: 'flex',
                                                gap: 1,
                                                width: 1,
                                                flexWidth: 5
                                            }}
                                        >
                                            {/* <Box sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: 1
                                            }}> */}
                                            <Typography variant="caption">Odds</Typography>

                                            <Typography
                                                variant="subtitle2"
                                                sx={{
                                                    color: '#b20038'
                                                }}
                                            >
                                                {`\t${this.state['accumulator']}`}
                                            </Typography>
                                            {/* </Box> */}
                                        </Button>
                                        {!this.props['buy'] && (
                                            <>
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    disableElevation
                                                    sx={{ width: 1, flexWidth: 5 }}
                                                    onClick={this.handleBuyTip}
                                                >
                                                    BUY @Ksh. {this.state.slip['price']}
                                                </Button>
                                                {/* <Button
                                                    variant="contained"
                                                    size="small"
                                                    disableElevation
                                                    sx={{ width: 1, flexWidth: 5 }}
                                                    onClick={this.handleBuyTipNg}
                                                    color='warning'
                                                >
                                                    BUY @NGN. {this.state.slip['price_ng']}
                                                </Button> */}
                                            </>
                                        )}
                                        <Typography variant="caption">
                                            <Link to={`/select-country/${this.state.slip['id']}`}>
                                                Buy from other countries
                                            </Link>
                                        </Typography>
                                        
                                    </Box>
                                }
                            </Box>
                        </CardActions>
                    </Card>
                </Grid>
            );
        } catch (err) {
            console.log(err);
            return null;
        }
    }
}

export function ActiveTip(props) {
    // console.log(props);
    const navigate = useNavigate();
    return <ActiveTipComponent {...props} navigate={navigate} />;
}

export default function ActiveTips(props) {
    const navigate = useNavigate();
    console.log(props);
    const handleSeeMore = () => {
        if (!props.user) {
            if (props.top) {
                navigate('/active_tips?type=top');
            } else {
                navigate('/active_tips');
            }
        } else {
            navigate(`/active_tips/${props.user}`);
        }
    };

    const state = {
        activeTips: props.slips ? props.slips : null
    };

    let tipsters = [];
    if (!state.activeTips) {
        for (let i = 0; i < 2; i++)
            tipsters.push(
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{
                        p: 1,
                        '@media screen and (max-width: 600px)': {
                            width: '100%'
                        }
                    }}
                >
                    <Skeleton variant="rectangular" width="100%" height={257} />
                </Grid>
            );
    } else {
        for (let i = 0; i < Math.min(state.activeTips.length, 12); i++) {
            tipsters.push(<ActiveTip slip={state.activeTips[i]} navigate={navigate} />);
        }
    }

    if (props.home) {
        return (
            <Box sx={{ p: 0, m: 0 }}>
                <Paper
                    square
                    elevation={1}
                    sx={{
                        py: 1,
                        mx: 0,
                        my: 1,
                        width: '100%',
                        height: '100%',
                        borderRadius: 1
                    }}
                >
                    <PageHeader sx={{ pl: 0 }} title="Premium Tips" description="View Tips" />

                    {/*<Container sx={{ mt: 1, p: 0 }}>*/}
                    <Box
                        sx={{
                            p: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    >
                        <Grid
                            container
                            spacing={0}
                            sx={{
                                m: 0,
                                p: 0,
                                maxWidth: '100%'
                            }}
                        >
                            {tipsters.map((t) => t)}
                        </Grid>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Button size="small" onClick={handleSeeMore}>
                                See More
                            </Button>
                        </Box>
                    </Box>
                    {/*</Container>*/}
                </Paper>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 0, m: 1 }}>
            <HContainer>
                <Paper
                    square
                    elevation={1}
                    sx={{
                        py: 1,
                        mx: 0,
                        my: 1,
                        width: '100%',
                        height: '100%',
                        borderRadius: 1
                    }}
                >
                    <PageHeader sx={{ pl: 0 }} title="Active Tips" description="Top Football Tips" />

                    <Container sx={{ mt: 1, p: 0 }}>
                        <Box
                            sx={{
                                p: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }}
                        >
                            <Grid
                                container
                                spacing={0}
                                sx={{
                                    m: 0,
                                    p: 0,
                                    maxWidth: '100%'
                                }}
                            >
                                {tipsters.map((t) => t)}
                            </Grid>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                <Button size="small" onClick={handleSeeMore}>
                                    See More
                                </Button>
                            </Box>
                        </Box>
                    </Container>
                </Paper>
            </HContainer>
        </Box>
    );
}
