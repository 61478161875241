import React from 'react';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga';

import Home from './routes/Home';
import Profile from './routes/profile/Profile';
import ProfileV2 from './routes/profile/ProfileV2';
import AddTips from './routes/AddTips';
import UserAccountSettings from './routes/profile/UserAccountSettings';
import UserDashboard from './routes/profile/UserDashboard';
import BuyTip from './routes/BuyTip';
import RequireAuth from './components/RequireAuth';
import PasswordChange from './routes/auth/PasswordChange';
import ActiveTipsList from './routes/data_lists/ActiveTipsList';
import FinishedTipsList from './routes/data_lists/FinishedTipsList';
import TopTipstersList from './routes/data_lists/TopTipstersList';
import TipsterPayments from './routes/data_lists/TipsterPayments';
import WithdrawRequest from './routes/profile/WithdrawRequest';
import Error404 from './routes/info/Error404';
import ActiveTipsPage from './routes/data_lists/ActiveTipsPage';
import EmailVerification from './routes/EmailVerification';
import Jackpot from './routes/Jackpot';
import Blog from './routes/Blog';
import BuyTipNg from './routes/BuyTipNg';
import BuyTipUg from './routes/BuyTipUg';
import SelectCurrency from './routes/SelectCurrency';

import { GOOGLE_ANALYTICS_KEY } from './data';
import CustomerBoughtTips from './routes/CustomerBoughtTips';
import CustomerBoughtTipsNg from './routes/CustomerBoughtTipsNg';
import SubscriptionsPage from './routes/SubscriptionsPage';

ReactGA.initialize(GOOGLE_ANALYTICS_KEY);

export default function MRoutes() {
    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />}>
                    <Route index element={<Home />} />

                    <Route
                        exact
                        path="profile"
                        element={
                            <RequireAuth>
                                <Profile />
                            </RequireAuth>
                        }
                    ></Route>

                    <Route path="blog/:slug" element={<Blog />} />
                    
                    <Route path="email-verification/:code" element={ <EmailVerification/> } />

                    <Route path="profile/:username" element={<Profile />} />

                    <Route
                        path="profile/finished_tips/:username"
                        element={<FinishedTipsList />}
                    />
                    <Route
                        path="profile/active_tips/:username"
                        element={<ActiveTipsList />}
                    />

                    <Route
                        path="account_settings"
                        element={
                            <RequireAuth>
                                <UserAccountSettings />
                            </RequireAuth>
                        }
                    />

                    <Route
                        path="dashboard"
                        element={
                            <RequireAuth>
                                <UserDashboard />
                            </RequireAuth>
                        }
                    />

                    <Route
                        path="dashboard/add_tips"
                        element={
                            <RequireAuth>
                                <AddTips />
                            </RequireAuth>
                        }
                    />

                    <Route
                        path="dashboard/earnings"
                        element={
                            <RequireAuth>
                                <TipsterPayments />
                            </RequireAuth>
                        }
                    />

                    <Route
                        path="dashboard/withdraw"
                        element={
                            <RequireAuth>
                                <WithdrawRequest />
                            </RequireAuth>
                        }
                    />

                    <Route
                        path="dashboard/finished_tips/:username"
                        element={
                            <RequireAuth>
                                <FinishedTipsList />
                            </RequireAuth>
                        }
                    />

                    <Route
                        path="dashboard/active_tips/:username"
                        element={
                            <RequireAuth>
                                <ActiveTipsList />
                            </RequireAuth>
                        }
                    />

                    <Route path="change_password" element={<PasswordChange />}>
                        <Route
                            path=":user_token"
                            element={<PasswordChange />}
                        />
                    </Route>

                    <Route path="buy_tip" element={<BuyTip />}>
                        <Route path=":id" element={<BuyTip />} />
                    </Route>

                    <Route path='buy_tip_ng/:id' element={<BuyTipNg />}/>
                    <Route path='buy_tip_ug/:id' element={<BuyTipUg />}/>
                    <Route path='select-country/:id' element={<SelectCurrency />}/>
                    

                    <Route path="jackpot" element={<Jackpot/>} >
                        <Route path=":jackpot" element={<Jackpot />} />
                    </Route>

                    <Route path="active_tips" element={<ActiveTipsPage />}>
                        <Route path=":username" element={<ActiveTipsList />} />
                    </Route>

                    <Route path="finished_tips" element={<FinishedTipsList />}>
                        <Route
                            path=":username"
                            element={<FinishedTipsList />}
                        />
                    </Route>

                    <Route path="top_tipsters" element={<TopTipstersList />} />

                    <Route path="find_tip" element={<CustomerBoughtTips />} />
                    <Route path="find_tip_ng" element={<CustomerBoughtTipsNg />} />

                    <Route path="subscriptions" element={<SubscriptionsPage />} />
                    <Route path="*" element={<Error404 />} />

                    {/* <Route path="login" element={<LoginPage />} /> */}
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
