import React from 'react';
import Box from '@mui/material/Box';
import { Button, TextField, Typography } from '@mui/material';

import { modalStyle } from '../../components/customStyles';
import axios from '../../api/axios';

export default function PasswordReset(props) {
    const [email, setEmail] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');

    const handleForgotPassword = () => {
        setErrorMessage('');

        const options = {
            method: 'POST',
            url: '/api/account/reset-password/',
            headers: { 'Content-Type': 'application/json' },
            data: {
                email: email
            }
        };

        axios
            .request(options)
            .then((res) => {
                if (res) setErrorMessage('Check your email for the link');
            })
            .catch((error) => {
                setErrorMessage(error.response.data['email']);
            });
    };

    const inputFontStyle = {
        fontWeight: '500',
        marginBottom: 5
    };

    const labelFontStyle = {
        fontWeight: '500'
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    return (
        <Box sx={modalStyle}>
            <form>
                <Box
                    sx={{
                        paddingTop: 5,
                        paddingBottom: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        id="modal-modal-login-title"
                        variant="h5"
                        component="div"
                        fontWeight={500}
                    >
                        Password Reset
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        gap: 1,
                        paddingX: 8,
                        paddingY: 5
                    }}
                >
                    <TextField
                        label="Email"
                        variant="standard"
                        InputLabelProps={{ style: { ...labelFontStyle } }}
                        inputProps={{ style: { ...inputFontStyle } }}
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                    />

                    <Box
                        sx={{
                            p: 1,
                            m: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            gap: 3
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{ w: 1, minWidth: '100%' }}
                            disableElevation
                            onClick={handleForgotPassword}
                        >
                            Reset The Password
                        </Button>

                        <Typography
                            variant="caption"
                            display="block"
                            color="red"
                        >
                            {errorMessage}
                        </Typography>

                        <Button
                            variant="text"
                            component="div"
                            onClick={props.handleLogin}
                        >
                            Remembered The Password?
                        </Button>
                    </Box>
                </Box>
            </form>
        </Box>
    );
}
