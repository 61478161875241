import React from 'react';
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    Container,
    Grid,
    Link,
    List,
    ListItem,
    ListItemText,
    Paper,
    Skeleton,
    Typography
} from '@mui/material';

import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import axios from '../api/axios';
import Divider from '@mui/material/Divider';
import PageHeader from './PageHeader';
import { stringAvatar } from '../routes/profile/Profile';
import { HContainer } from './TopTipsters';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

class ActiveTipComponent extends React.Component {
    constructor(props) {
        super(props);

        const { slip } = this.props;
        console.log(slip);

        this.state = {
            slip: slip ? slip : null,
            tips: slip ? slip.tips : [],
            user: slip ? slip.user : [],
            accumulator: slip ? slip.accumulator : 0,
            meta: slip ? slip.metadata : {},
            rating: slip ? slip.metadata.rating / 10 : 0
        };

        this.handleBuyTip = this.handleBuyTip.bind(this);
        this.handleClickChip = this.handleClickChip.bind(this);

        console.log(slip);
    }

    handleBuyTip() {
        this.props.navigate(`/buy_tip/${this.state.slip['id']}`);
    }

    handleClickChip(user) {
        const { navigate } = this.props;
        navigate(`/profile/${user}`);
    }

    render() {
        const IconChip = ({ username }) => {
            return (
                <Link to={`/profile/${username}`} underline="hover" color="black">
                    <Chip
                        size="small"
                        style={{
                            cursor: 'pointer'
                        }}
                        sx={{
                            '& .MuiChip-label': {
                                fontFamily: 'GT Walsheim Pro',
                                fontWeight: '500',
                                fontSize: 'small'
                            }
                        }}
                        avatar={<Avatar style={{ color: 'white', fontWeight: 'bold' }} {...stringAvatar(username)} />}
                        label={username}
                    />
                </Link>
            );
        };

        const getYield = (num) => {
            const _val = Math.round((num + Number.EPSILON) * 100) / 100;
            const sign = _val >= 0 ? '+' : '';
            return `${sign}${_val}`;
        };

        const getType = () => {
            const _type = this.props.type;
            if (_type === "CS") return "Correct Score";
            if (_type === "HF") return "HalfTime/FullTime";
            return "";
        };

        try {
            if (!this.state.slip) {
                return <>Empty</>;
            }

            const myDate = (_date) => {
                const date = new Date(Date.parse(_date));
                // noinspection JSCheckFunctionSignatures
                return new Intl.DateTimeFormat('en-US', {
                    dateStyle: 'medium'
                }).format(date);
            };

            const tipCount = [];
            if (this.state.slip)
                for (let i = 0; i < this.state.tips.length; i++) {
                    // label = this.props.buy ? label : null;

                    tipCount.push(
                        <ListItem key={i} disablePadding divider>
                            <ListItemText
                                secondaryTypographyProps={{
                                    color: 'black'
                                }}
                                primaryTypographyProps={{
                                    color: 'black',
                                    fontSize: 'smaller'
                                }}
                                primary={`${this.state.tips[i]['home_team']} vs ${this.state.tips[i]['away_team']}`}
                            />
                        </ListItem>
                    );
                }

            return (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{
                        p: 1,
                        '@media screen and (max-width: 600px)': {
                            width: '100%'
                        }
                    }}
                >
                    <Card
                        elevation={0}
                        variant="outlined"
                        sx={{
                            display: 'inline-block',
                            m: 0,
                            width: '100%'
                        }}
                    >
                        <CardContent sx={{ maxWidth: '100%', minHeight: 220 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    p: 0
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        p: 0,
                                        m: 0,
                                        gap: 1,
                                        alignItems: 'center'
                                    }}
                                >
                                    <IconChip
                                        username={getType()}
                                    />

                                    <Chip
                                        size="small"
                                        label={this.state.slip ? myDate(this.state.slip['date_created']) : null}
                                        sx={{
                                            '& .MuiChip-label': {
                                                fontFamily: 'GT Walsheim Pro',
                                                fontWeight: '500',
                                                fontSize: 'small'
                                            }
                                        }}
                                    />
                                </Box>

                                <List sx={{ width: '100%' }}>{tipCount}</List>
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 1,
                                    width: 1
                                }}
                            >
                                {' '}
                                {
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: 1
                                        }}
                                    >
                                        {' '}
                                        {!this.props['buy'] && (
                                            <Button
                                                variant="contained"
                                                size="small"
                                                disableElevation
                                                sx={{ width: 1, flexWidth: 5 }}
                                                onClick={this.handleBuyTip}
                                            >
                                                BUY @Ksh. {this.state.slip['price']}
                                            </Button>
                                        )}
                                        <Button
                                            variant="outlined"
                                            disableElevation
                                            sx={{
                                                display: 'flex',
                                                gap: 1,
                                                width: 1,
                                                flexWidth: 5
                                            }}
                                        >
                                            {/* <Box sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: 1
                                            }}> */}
                                            <Typography variant="caption">Odds</Typography>

                                            <Typography
                                                variant="subtitle2"
                                                sx={{
                                                    color: '#b20038'
                                                }}
                                            >
                                                {`\t${this.state['accumulator']}`}
                                            </Typography>
                                            {/* </Box> */}
                                        </Button>
                                    </Box>
                                }
                            </Box>
                        </CardActions>
                    </Card>
                </Grid>
            );
        } catch (err) {
            console.log(err);
            return null;
        }
    }
}

export function PremiumActiveTip({ slip, type, cookies, limit = 8 }) {
    const navigate = useNavigate();
    console.log(slip);
    return <ActiveTipComponent slip={slip} type={type} cookies={cookies} navigate={navigate} limit={limit} />;
}
