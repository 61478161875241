import React from 'react';
import { Modal, Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { ArrowRight } from '@mui/icons-material';
import { HContainer } from './TopTipsters';
import Register from '../routes/auth/Register';

// import soccer from '../assets/img/soccer.webp';
// const soccer = "https://sokafans-static.s3.eu-central-1.amazonaws.com/images/soccer.webp";
const soccer =
    'https://img.freepik.com/free-photo/large-football-stadium-with-bright-lights-night_587448-8168.jpg?t=st=1715421937~exp=1715425537~hmac=ad85c333fe3b0bf67840cb7ba70181ce332481d3ed73351968f53f34fc088017&w=826';

export default function HomeBanner() {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => setOpen(false);

    const bannerInfo = [
        'Get well analyzed predictions.',
        // 'Thousands of tipsters with proven track record.',
        'Sure predictions on your favorite games.',
        // (<p></p>),
        // (<p></p>),
        // (<p></p>),
        // 'Make money online selling betting tips. Register to get started.',
        // 'Share your subscription link and get punters to buy your tip.'
    ];

    return (
        <Box
            sx={{
                p: 0,
                m: 0,
                height: 'inherit',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
            style={{ borderRadius: 0 }}
        >
            <HContainer>
                <Paper
                    square
                    elevation={1}
                    style={{ borderRadius: 0 }}
                    sx={{
                        p: 0,
                        m: 0,
                        width: '100%',
                        height: '100%',
                        borderRadius: 0,
                        backgroundImage:
                            `url(${soccer})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                    }}
                >
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            px: 2,
                            pt: 2,
                            pb: 0
                        }}
                        style={{
                            color: 'whitesmoke',
                            // backgroundColor: 'rgb(6, 22, 61, 0.7)'
                            backgroundColor: 'rgb(10, 57, 21, 0.2)'
                        }}
                    >
                        <Typography
                            sx={{ pl: 1 }}
                            color="whitesmoke"
                            variant="h3"
                            component="div"
                            gutterBottom
                            fontWeight={500}
                        >
                            <i>Futaa</i><i><strong>PREDICT</strong></i>
                        </Typography>

                        <>
                            {bannerInfo.map((text) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ArrowRight />
                                    <Typography variant="subtitle2">
                                        {text}
                                    </Typography>
                                </Box>
                            ))}
                        </>

                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-register-title"
                            >
                                <Register hideLogin />
                            </Modal>
                        </Box>
                    </Box>
                </Paper>
            </HContainer>
        </Box>
        // </Root>
    );
}
