import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import {
    Avatar,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Link,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography
} from '@mui/material';
import axios from '../../api/axios';
import PageHeader from '../../components/PageHeader';
import { HContainer } from '../../components/TopTipsters';
import Box from '@mui/material/Box';
import { StyledDataGrid } from '../../components/data_lists/DataListComponents';

import BreadCrumbView from '../../components/app_bar/BreadCrumbView';
import Title from '../../components/Title';

export function GameDialog(props) {
    const { tips } = props;
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (props.tips && props.tips.length > 0) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    });

    const handleClose = () => {
        props.handleClose();
    };

    const tipCount = [];
    if (tips)
        for (let i = 0; i < tips.length; i++) {
            tipCount.push(
                <ListItem key={i} disablePadding divider>
                    <ListItemText
                        secondaryTypographyProps={{
                            color: 'black'
                        }}
                        secondary={`${tips[i]['home_team']} vs ${tips[i]['away_team']}`}
                    />
                </ListItem>
            );
        }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Tips</DialogTitle>
            <DialogContent>
                <List sx={{ width: '100%' }}>{tipCount}</List>
            </DialogContent>
        </Dialog>
    );
}

function createData(data, id, navigate, viewTip) {
    const slip = data.slip;
    const tips = data.tips;
    const user = data.user;

    React.useEffect(() => {
        document.title = 'FutaaPredict| Active Tips';
    });

    console.log(data);
    console.log(tips);

    return {
        id: id,
        slip_id: slip['id'],
        navigate: navigate,
        date: slip['date_created'],
        username: user['username'],
        rating: 0,
        count: data.tips.length,
        games: data.tips,
        price: 50,
        viewTip: viewTip
    };
}

const dataColumns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 5,
        flex: 1
    },
    {
        field: 'profile_pic',
        headerName: 'PC',
        minWidth: 35,
        flex: 1,
        renderCell: (params) => (
            <Avatar
                sx={{ width: 24, height: 24 }}
                src={params.row.profile_pic}
            />
        )
    },
    {
        field: 'username',
        headerName: 'Tipster',
        minWidth: 100,
        flex: 1,
        editable: false,
        renderCell: (params) => (
            <Link
                fontWeight="bold"
                fontFamily="GT Walsheim Pro"
                fontSize="small"
                underline="hover"
                style={{ color: 'black' }}
                to={`/profile/${params.row.username}`}
            >
                {params.row.username}
            </Link>
        )
    },
    {
        field: 'rating',
        headerName: 'Rating',
        type: 'number',
        minWidth: 70,
        flex: 1,
        editable: false,
        sortable: true
    },
    {
        field: 'date',
        headerName: 'Date',
        minWidth: 110,
        flex: 2,
        type: 'date',
        editable: false,
        sortable: true,
        renderCell: (params) => (
            <Typography variant="subtitle1">{params.row.date}</Typography>
        )
    },
    {
        field: 'count',
        headerName: 'Count',
        minWidth: 60,
        flex: 1
    },
    {
        field: 'games',
        headerName: 'Tip',
        minWidth: 75,
        flex: 1,
        renderCell: (params) => (
            <Button
                variant="contained"
                disableElevation
                size="small"
                textSizeSmall
                value={params.row.slip_id}
                onClick={params.row.viewTip}
            >
                View
            </Button>
        )
    },
    {
        field: 'price',
        headerName: 'Price',
        minWidth: 75,
        flex: 1,
        type: 'number',
        editable: false,
        renderCell: (params) => `Ksh. ${params.row.price}`
    },
    {
        field: 'buy',
        headerName: 'Buy',
        minWidth: 75,
        flex: 1,
        renderCell: (params) => (
            <Button
                variant="contained"
                disableElevation
                size="small"
                onClick={() => {
                    params.row.navigate(`/buy_tip/${params.row.slip_id}`);
                }}
            >
                Buy
            </Button>
        )
    }
];

class ActiveTipsListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTips: null,
            open: false,
            tips: []
        };

        this.handleLoadActiveTips = this.handleLoadActiveTips.bind(this);
        this.handleViewTip = this.handleViewTip.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    componentDidMount() {
        document.title = 'FutaaPredict| Active Tips';
        this.handleLoadActiveTips();
    }

    handleViewTip(e) {
        const slip_id = e.target.value;
        let tips = null;
        if (this.state.activeTips)
            tips = this.state.activeTips.find(
                (tip) => tip.slip_id === parseInt(slip_id)
            );

        if (!tips) return;

        console.log('Tip View');
        console.log(tips);

        this.setState({
            open: true,
            tips: tips.games
        });
    }

    handleCloseModal() {
        this.setState({
            open: false,
            tips: null
        });
    }

    handleLoadActiveTips() {
        const { navigate, params } = this.props;
        const username = params.username;

        const options = {
            method: 'GET',
            url: '/api/home/active'
        };

        const get = (_list) => {
            if (_list) return _list;
            return [];
        };

        axios
            .request(options)
            .then((response) => {
                const data = response.data;
                const slips = data.slips;
                let cnt = 1;

                slips.map((slip) => {
                    if (username) {
                        if (slip.user['username'] !== username) return null;
                    }
                    this.setState((prevState) => ({
                        activeTips: [
                            ...get(prevState.activeTips),
                            createData(
                                slip,
                                cnt++,
                                navigate,
                                this.handleViewTip
                            )
                        ]
                    }));
                    return null;
                });

                // for (let i = 0; i < slips.length; i++) {
                //     const slip = slips[i];
                //     console.log(username);
                //     if (username) {
                //         if (slip.user['username'] !== username) continue;
                //     }
                //     this.setState((prevState) => ({
                //         activeTips: [
                //             ...get(prevState.activeTips),
                //             createData(
                //                 slip,
                //                 cnt++,
                //                 navigate,
                //                 this.handleViewTip
                //             )
                //         ]
                //     }));
                // }
            })
            .catch(function (error) {
                console.log('Error', error.response.data);
            });
    }

    render() {
        return (
            <Box
                sx={{
                    p: 1,
                    m: 0,
                    gap: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}
            >
                <Title title=" FutaaPredict | Active Tips" />

                <BreadCrumbView sx={1} />

                <GameDialog
                    openDialoag={this.state.open}
                    handleClose={this.handleCloseModal}
                    tips={this.state.tips}
                />

                <HContainer>
                    <Paper
                        square
                        elevation={1}
                        sx={{
                            p: 1,
                            mx: 0,
                            my: 0,
                            width: '100%',
                            height: '100%',
                            borderRadius: 1
                        }}
                    >
                        <PageHeader
                            sx={{ pl: 2 }}
                            title="Active Tips"
                            description="Select an option"
                            disableDivider
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                minHeight: 500
                            }}
                        >
                            {!this.state.activeTips ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                        height: 450,
                                        m: 0
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <StyledDataGrid
                                    rows={this.state.activeTips}
                                    columns={dataColumns}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                />
                            )}
                        </Box>
                    </Paper>
                </HContainer>
            </Box>
        );
    }
}

function ActiveTipsList(props) {
    const navigate = useNavigate();
    const params = useParams();

    return (
        <ActiveTipsListComponent
            {...props}
            navigate={navigate}
            params={params}
        />
    );
}

export default withCookies(ActiveTipsList);
