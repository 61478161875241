import React from 'react';

import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    Link,
    List,
    ListItem,
    ListItemText,
    OutlinedInput,
    Paper,
    Skeleton,
    Typography
} from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate, useParams, redirect } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

import Box from '@mui/material/Box';
import PageHeader from '../components/PageHeader';
import { HContainer } from '../components/TopTipsters';
import axios from '../api/axios';
import { LoadingButton } from '@mui/lab';
import Title from '../components/Title';
import { stringAvatar } from './profile/Profile';
import { CURRENCIES } from '../data';

function ActiveTipComponent(props) {
    const data = props.data;

    console.log(data);

    const [state, _] = React.useState({
        slip: data.slip,
        tips: data.slip.tips,
        user: data.user,
        accumulator: data.accumulator,
        meta: data.meta,
        rating: data.meta.rating / 10
    });

    const IconChip = ({ username }) => {
        return (
            <Link to={`/profile/${username}`} underline="hover" color="black">
                <Chip
                    size="small"
                    style={{
                        cursor: 'pointer'
                    }}
                    sx={{
                        '& .MuiChip-label': {
                            fontFamily: 'GT Walsheim Pro',
                            fontWeight: '500',
                            fontSize: 'small'
                        }
                    }}
                    avatar={<Avatar style={{ color: 'white', fontWeight: 'bold' }} {...stringAvatar(username)} />}
                    label={username}
                />
            </Link>
        );
    };

    const render = () => {
        try {
            const tipCount = [];

            const myDate = (_date) => {
                const date = new Date(Date.parse(_date));
                // noinspection JSCheckFunctionSignatures
                return new Intl.DateTimeFormat('en-US', {
                    dateStyle: 'medium',
                    timeStyle: 'short'
                }).format(date);
            };

            if (state.slip)
                for (let i = 0; i < state.tips.length; i++) {
                    const game_date = new Date(state.tips[i]['game_date']);
                    const hours = (game_date - Date.now()) / 36e5;
                    var label = '';
                    if (hours > 0) {
                        label = 'Not Started';
                    } else if (hours > -3) {
                        label = 'In play';
                    } else {
                        label = 'Played';
                    }

                    tipCount.push(
                        <ListItem key={i} disablePadding divider>
                            <ListItemText
                                secondaryTypographyProps={{
                                    color: 'black'
                                }}
                                primaryTypographyProps={{
                                    color: 'black',
                                    fontSize: 'smaller'
                                }}
                                primary={`${state.tips[i]['home_team']} vs ${state.tips[i]['away_team']}`}
                                secondary={
                                    <Chip
                                        size="small"
                                        label={`${myDate(game_date)} - ${label}`}
                                        sx={{
                                            '& .MuiChip-label': {
                                                fontFamily: 'GT Walsheim Pro',
                                                fontWeight: '500',
                                                fontSize: 'small'
                                            }
                                        }}
                                    />
                                }
                            />
                        </ListItem>
                    );
                }

            if (!state.slip) {
                return null;
            }

            return (
                <Grid item sx={{ m: 0, p: 0 }}>
                    <Card
                        elevation={0}
                        variant="outlined"
                        sx={{
                            m: 0,
                            minWidth: props.buy ? '100%' : 270,
                            maxWidth: '100%',
                            width: 270
                        }}
                    >
                        <CardContent sx={{ maxWidth: '100%', minHeight: 200 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    p: 0
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        p: 0,
                                        m: 0,
                                        gap: 1
                                    }}
                                >
                                    <Chip
                                        size="small"
                                        label={state.slip ? myDate(state.slip['date_created']) : null}
                                        sx={{
                                            '& .MuiChip-label': {
                                                fontFamily: 'GT Walsheim Pro',
                                                fontWeight: '500',
                                                fontSize: 'small'
                                            }
                                        }}
                                    />
                                    {/*<Chip variant="outlined" label={this.state.user['username']} />*/}

                                    <IconChip username={state.user['username']} />
                                </Box>
                                <List sx={{ width: '100%' }}>{tipCount}</List>
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 1,
                                    width: 1
                                }}
                            >
                                {' '}
                                {false ? null : (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: 1
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            disableElevation
                                            sx={{
                                                display: 'flex',
                                                gap: 1,
                                                width: 1,
                                                flexWidth: 5
                                            }}
                                        >
                                            {/* <Box sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: 1
                                            }}> */}
                                            <Typography variant="caption">Odds</Typography>

                                            <Typography
                                                variant="subtitle2"
                                                sx={{
                                                    color: '#b20038'
                                                }}
                                            >
                                                {`\t${state['accumulator']}`}
                                            </Typography>
                                            {/* </Box> */}
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </CardActions>
                    </Card>
                </Grid>
            );
        } catch (err) {
            console.log(err);
            return <>Empty</>;
        }
    };

    return render();
}

class BuyTipComp extends React.Component {
    constructor(props) {
        super(props);
        const { navigate, params } = this.props;

        if (!params.id) {
            navigate('/');
        }

        this.state = {
            id: params.id,

            emailValue: null,
            emailError: null,

            slip: null,
            loading: false
        };

        this.handleLoadTip = this.handleLoadTip.bind(this);
        this.handleEmailValueChange = this.handleEmailValueChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleLoadTip() {
        const options = {
            method: 'GET',
            url: `/api/slip/get/${this.state.id}`
        };

        axios
            .request(options)
            .then((response) => {
                this.setState({
                    slip: response.data
                });
            })
            .catch(function (error) {});
    }

    handleEmailValueChange(e) {
        const phone = e.target.value;
        this.setState({
            emailValue: phone
        });
    }

    handleSubmit() {
        this.setState({
            emailError: null,
            loading: true
        });

        // CHANGE

        const email = this.state.emailValue;
        // if (phone == null || !(phone.length === 10 || phone.length === 9)) {
        //     this.setState({
        //         emailError: 'Enter Valid Phone Number',
        //         loading: false
        //     });
        //     return;
        // }

        // const getEmailValue = (phone) => {
        //     if (phone.length === 10) return `254${phone.substring(1)}`;
        //     return `254${phone}`;
        // };

        const options = {
            method: 'POST',
            url: '/api/processor/buy-flw-ug',
            headers: { 'Content-Type': 'application/json' },
            data: {
                email: email,
                slip_id: this.state.id
            }
        };

        axios
            .request(options)
            .then(function (response) {
                console.log(response.data);
                alert('Request Sent');
                const link = response.data['link'];
                window.location.replace(link);
            })
            .catch(function (error) {
                const response = error.response;
                if (response.data && response.data['message']) {
                    alert(response.data['message']);
                } else {
                    alert(response.data);
                }
            })
            .finally(() => {
                this.setState({
                    loading: false
                });
            });
    }

    componentDidMount() {
        this.handleLoadTip();
    }

    render() {
        // noinspection JSUnresolvedVariable
        return (
            <Box>
                <Title title={`FutaaPredict Uganda| Buy Tip ${this.state.id}`} />
                <HContainer>
                    <PageHeader sx={{ pl: 0 }} title="Buy Tip" description="Buy tip" />

                    <Box
                        sx={{
                            p: 1,
                            m: 1,
                            display: 'flex',
                            gap: 1,
                            flexDirection: 'column',
                            maxWidth: 600
                        }}
                    >
                        <Paper
                            square
                            elevation={1}
                            sx={{
                                p: 1,
                                mx: 0,
                                my: 1,
                                width: '100%',
                                height: '100%',
                                borderRadius: 1
                            }}
                        >
                            {this.state.slip ? (
                                <ActiveTipComponent data={this.state.slip} buy />
                            ) : (
                                <Box
                                    sx={{
                                        height: 200,
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1
                                    }}
                                >
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton sx={{ height: 100 }} />
                                </Box>
                            )}
                        </Paper>

                        {/* <Alert severity="warning">
                            <AlertTitle>Info</AlertTitle>
                            <strong>
                                10.0 Odds and below are more likely to win. Avoid buying extremely high odds.
                            </strong>

                            <br></br>

                            <small>
                                Visit the tipster's profile and see whether they have been consistently winning with
                                that kind of odd.
                            </small>
                        </Alert> */}

                        <Box sx={{ mt: 1 }}>
                            <Paper
                                square
                                elevation={1}
                                sx={{
                                    p: 1,
                                    mx: 0,
                                    my: 1,
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: 1
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1
                                    }}
                                >
                                    <Typography variant="h6">Flutterwave Payment - Uganda</Typography>

                                    <Typography variant="caption">
                                        Enter your email where you'll receive the tip after paying.
                                    </Typography>

                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-amount">Email</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            error={this.state.emailError}
                                            helperText={this.state.emailError}
                                            value={this.state.emailValue}
                                            onChange={this.handleEmailValueChange}
                                            startAdornment={<EmailOutlinedIcon />}
                                            label="Email"
                                        />
                                        <FormHelperText error>{this.state.emailError}</FormHelperText>
                                    </FormControl>

                                    <LoadingButton
                                        variant="contained"
                                        disableElevation
                                        disabled={process.env.NODE_ENV === 'production' && false}
                                        loading={this.state.loading}
                                        onClick={this.handleSubmit}
                                    >
                                        Buy @ {CURRENCIES['ug']} {this.state.slip ? (this.state.slip.slip['price'] * 30) : '-'}
                                    </LoadingButton>

                                    <Alert severity="info">
                                        <AlertTitle>Info</AlertTitle>
                                        Tips are sent instantly via E-Mail — Ensure you check that you have given the
                                        right email, and also check you spam box.
                                    </Alert>

                                    <Alert severity="warning">
                                        <AlertTitle>More</AlertTitle>
                                        Click{' '}
                                        <a href="/find_tip" target="_blank">
                                            here
                                        </a>{' '}
                                        to get your tip. After buying it.
                                    </Alert>
                                </Box>
                            </Paper>
                        </Box>
                    </Box>
                </HContainer>
            </Box>
        );
    }
}

export default function BuyTip(props) {
    const navigate = useNavigate();
    const params = useParams();
    return <BuyTipComp {...props} navigate={navigate} params={params} />;
}
