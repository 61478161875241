import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    Typography
} from '@mui/material';
import React from 'react';
import axios from '../../api/axios';
import Box from '@mui/material/Box';
import { modalStyle } from '../../components/customStyles';
import { LoadingButton } from '@mui/lab';
import MuiAlert from '@mui/material/Alert';

export const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant="standard"
            {...props}
            sx={{
                fontSize: 'small',
                fontFamily: 'GT Walsheim Pro'
            }}
        />
    );
});

export function DashButton(props) {
    return (
        <Grid item xs={6} sm={6} md={4} lg={4} sx={{ p: 0, m: 0 }}>
            <Button
                color={props.onClick && !props.ignore ? 'primary' : 'success'}
                size={props.onClick && !props.ignore ? 'small' : 'inherit'}
                disableElevation
                variant="outlined"
                sx={{
                    m: 0,
                    p: 0,
                    minHeight: props.onClick && !props.ignore ? 108 : 50,
                    width: '100%',
                    maxWidth: '100%',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                onClick={props.onClick}
            >
                <Typography variant="button" textAlign="center">
                    {props.label}
                </Typography>
                {props.value ? <Typography>{props.value}</Typography> : null}
            </Button>
        </Grid>
    );
}

export function WithdrawModal(props) {
    const { amount, cookies } = props;

    const [phoneError, setPhoneError] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const setError = (err) => {
        setPhoneError(err);
        setLoading(err != null);
    };

    const handleSubmit = () => {
        setError(null);
        // CHANGE

        const phone = phoneNumber;
        if (phone == null || !(phone.length === 10 || phone.length === 9)) {
            setError('Enter a valid phone number');
            return;
        }

        const getPhoneNumber = (phone) => {
            if (phone.length === 10) return `254${phone.substring(1)}`;
            return `254${phone}`;
        };

        const options = {
            method: 'POST',
            url: '/api/finance/withdraw_request',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${cookies.get('token')}`
            },
            data: {
                phone_number: getPhoneNumber(phoneNumber)
            }
        };

        axios
            .request(options)
            .then(function () {
                props.handleClose();
            })
            .catch(function (error) {
                const response = error.response;
                if (response.data && response.data['message']) {
                    alert(response.data['message']);
                } else {
                    alert(response.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                ...modalStyle
            }}
        >
            <Paper
                square
                elevation={1}
                sx={{
                    p: 0,
                    mx: 1,
                    my: 1,
                    borderRadius: 1,
                    maxWidth: '100%',
                    minWidth: 350,
                    alignSelf: 'center',
                    justifySelf: 'center'
                }}
            >
                <form>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            gap: 1,
                            paddingX: 3,
                            paddingY: 5,
                            m: 0
                        }}
                    >
                        <Box
                            sx={{
                                px: 0,
                                paddingTop: 3,
                                paddingBottom: 3,
                                display: 'flex',
                                gap: 1,
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Typography
                                id="modal-modal-login-title"
                                variant="h6"
                                component="div"
                                fontWeight={500}
                            >
                                Withdraw Request
                            </Typography>

                            <Typography
                                id="modal-modal-login-title"
                                variant="p"
                                component="div"
                            >
                                The amount value is based on your unpaid recent
                                tip sales
                            </Typography>
                        </Box>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="outlined-adornment-amount">
                                Amount
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-amount"
                                value={amount}
                                disabled
                                startAdornment={
                                    <InputAdornment position="start">
                                        Ksh
                                    </InputAdornment>
                                }
                                label="Amount"
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel htmlFor="outlined-adornment-amount">
                                Phone
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-amount"
                                error={
                                    phoneError !== undefined ||
                                    phoneError !== ''
                                }
                                helperText={phoneError}
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                startAdornment={
                                    <InputAdornment position="start">
                                        +254
                                    </InputAdornment>
                                }
                                label="Phone"
                            />
                            <FormHelperText error>{phoneError}</FormHelperText>
                        </FormControl>

                        <Typography
                            id="modal-modal-login-title"
                            variant="caption"
                            component="div"
                        >
                            Enter your MPESA number where the deposit will be
                            made
                        </Typography>

                        <Box
                            sx={{
                                p: 0,
                                m: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 3
                            }}
                        >
                            <LoadingButton
                                variant="contained"
                                sx={{ w: 1, minWidth: '100%' }}
                                loading={loading}
                                disableElevation
                                onClick={handleSubmit}
                            >
                                Withdraw
                            </LoadingButton>

                            <Typography
                                variant="caption"
                                display="block"
                                color="red"
                            >
                                {phoneError}
                            </Typography>
                        </Box>
                    </Box>
                </form>
            </Paper>
        </Box>
    );
}
