import axios from "./axios";

export const requestCall = (url, accessToken, method = 'GET', data = {}) => {
    const config = {
        url: url,
        method: method,
        headers: {
            Authorization: `Bearer ${accessToken}`
        },
        data: data
    };
    // console.log(config);

    return axios.request(config);
}

export function requestCallNoAccess(url, method = 'GET', data = {}) {
    const config = {
        url: url,
        method: method,
        data: data
    };

    return axios.request(config);
}