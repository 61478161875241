import React from 'react';
import Box from '@mui/material/Box';
import { Button, Link, TextField, Typography } from '@mui/material';
import { useCookies } from 'react-cookie';

import { modalStyle } from '../../components/customStyles';
import axios from '../../api/axios';
import PasswordInput from '../../components/controls/PasswordInput';
import { LoadingButton } from '@mui/lab';

import ReactGA from 'react-ga';

import { GOOGLE_ANALYTICS_KEY } from '../../data';

ReactGA.initialize(GOOGLE_ANALYTICS_KEY);

export default function Login(props) {
    const [_, setCookie] = useCookies(['token', 'refresh']);
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        ReactGA.event({
            "category": "User",
            "action": "Open Login Form"
        })
    }, []);

    const handleLogin = () => {
        setLoading(true);
        setErrorMessage('');
        axios
            .post('/api/account/email-login', {
                email: email,
                password: password
            })
            .then(function (response) {
                ReactGA.event({
                    "category": "User",
                    "action": "Logged into account",
                });

                let data = response.data;
                setLoading(false);
                setErrorMessage('Success');

                if (data['access']) setCookie('token', data['access']);
                if (data['refresh']) setCookie('refresh', data['refresh']);

                props.handleLogin();
            })
            .catch(function (err) {
                setLoading(false);
                console.log(err);
                const res = err.response;
                if (res && res.data && res.data['message']) {
                    setErrorMessage(res.data['message']);
                } else {
                    setErrorMessage('An error occurred');
                }
            });
    };

    const inputFontStyle = {
        fontWeight: '500',
        marginBottom: 5
    };

    const labelFontStyle = {
        fontWeight: '500'
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    return (
        <Box sx={modalStyle}>
            <form>
                <Box
                    sx={{
                        paddingTop: 5,
                        paddingBottom: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        id="modal-modal-login-title"
                        variant="h5"
                        component="div"
                        fontWeight={500}
                    >
                        Login
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        gap: 1,
                        paddingX: 6,
                        paddingY: 5
                    }}
                >
                    <TextField
                        label="Email"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ style: { ...labelFontStyle } }}
                        inputProps={{ style: { ...inputFontStyle } }}
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                    />

                    <PasswordInput
                        label="Password"
                        value={password}
                        onChange={handlePasswordChange}
                    />

                    <Box
                        sx={{
                            p: 0,
                            m: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            gap: 3
                        }}
                    >
                        <LoadingButton
                            loading={loading}
                            variant="contained"
                            sx={{ w: 1, minWidth: '100%' }}
                            disableElevation
                            onClick={handleLogin}
                        >
                            Login
                        </LoadingButton>

                        <Typography
                            variant="caption"
                            display="block"
                            color="red"
                        >
                            {errorMessage}
                        </Typography>

                        <Button variant="text" onClick={props.handleForgotPass}>
                            Forgot Password
                        </Button>
                    </Box>
                </Box>

                <Box
                    sx={{
                        height: 40,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                    style={{ background: '#e0e0e0' }}
                >
                    <Typography
                        variant="subtitle2"
                        fontSize={15}
                        alignSelf="center"
                        style={{ textAlign: 'center' }}
                    >
                        Don't have an account?
                        <Link
                            href="#"
                            underline="none"
                            variant="body2"
                            onClick={props.handleRegister}
                        >
                            {' '}
                            Register.
                        </Link>
                    </Typography>
                </Box>
            </form>
        </Box>
    );
}
