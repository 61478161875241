import React from 'react';

import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    Link,
    List,
    ListItem,
    ListItemText,
    OutlinedInput,
    Paper,
    Skeleton,
    Typography
} from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import PageHeader from '../components/PageHeader';
import { HContainer } from '../components/TopTipsters';
import axios from '../api/axios';
// import axios from 'axios';

import { LoadingButton } from '@mui/lab';
import Title from '../components/Title';
import { stringAvatar } from './profile/Profile';

export function ActiveTipComponentWithResult(props) {
    const tips = props.data['tips'];
    const markets = props.markets || [];
    console.log(props.slip);

    let accumulator = 1.0;

    const tipCount = [];
    for (let i = 0; i < tips.length; i++) {
        const tip = tips[i];
        const game = `${tip['home_team']} vs ${tip['away_team']}`;
        const league = tip['league'];

        let result = '';
        for (let j = 0; j < markets.length; j++) {
            if (markets[j]['id'] === tip['market_type']) {
                result = markets[j]['value'];
                break;
            }
            console.log(markets[j]['id'], tip['market_type']);
        }
        accumulator *= tip['odd'];
        tipCount.push(
            <ListItem key={i} disablePadding divider>
                <ListItemText primary={`${game} | ${league}`} secondary={result} />
            </ListItem>
        );
    }

    return (
        <Grid item sx={{ m: 0, p: 0 }} xs={12} sm={12} md={6} lg={4}>
            <Card
                elevation={0}
                variant="outlined"
                sx={{
                    m: 0,
                    minWidth: '100%',
                    maxWidth: '100%',
                    width: 270
                }}
            >
                
                <CardHeader subheader={`Odds: ${Math.round(accumulator * 100) / 100}`} />


                <CardContent sx={{ maxWidth: '100%', minHeight: 200 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            p: 0
                        }}
                    >
                        <List sx={{ width: '100%' }}>{tipCount}</List>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    );
}

const SubscribeForm = ({ gold, bronze }) => {
    const [phone, setPhone] = React.useState('');
    const [subType, setSubTypeFunc] = React.useState('B'); // G or B
    // console.log(gold, bronze);

    const [selectedType, setSelectedType] = React.useState(bronze);
    const setSubType = (type) => {
        setSubTypeFunc(type);
        if (type === "G") {
            setSelectedType(gold);
        } else {
            setSelectedType(bronze);
        }
    };

    React.useState(() => {
        setSelectedType(bronze);
    }, []);

    const [phoneError, setPhoneError] = React.useState(null);
    const handleChange = (e) => {
        setPhone(e.target.value);
    };

    const [loading, setLoading] = React.useState(false);

    const handleSubmit = (e, days) => {
        e.preventDefault();
        if (loading) {
            return;
        }
        // alert(type);

        if (phone == null || !(phone.length === 10 || phone.length === 9)) {
            setPhoneError('Enter Valid Phone Number');
            return;
        }

        const getPhoneNumber = (phone) => {
            if (phone.length === 10) return `254${phone.substring(1)}`;
            return `254${phone}`;
        };

        if (subType !== 'G' && subType !== 'B') {
            setPhoneError('Set subscription type');
            return;
        }

        setLoading(true);

        const config = {
            method: 'POST',
            url: '/api/processor/subscribe',
            data: {
                phone: getPhoneNumber(phone),
                type: subType,
                days: days
            }
        };

        setLoading(true);
        axios
            .request(config)
            .then((res) => {
                alert('Request sent. Wait for mpesa stk');
            })
            .catch((error) => {
                console.log(error.response);
                if (error.response['data']) {
                    const message = error.response['data']['message'];
                    if (message) {
                        alert(message);
                    } else {
                        alert('Error occurred. Try again');
                    }
                } else {
                    alert('Error occurred. Try again');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Card
            elevation={1}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 0,
                m: 0,
                width: '100%',
                minWidth: '100%'
            }}
        >
            <PageHeader title="Subscribe" />

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 1, minHeight: 300 }}>
                <Typography variant="caption">Enter your m-pesa phone number.</Typography>
                <FormControl fullWidth>
                    <InputLabel htmlFor="phone">Mpesa Phone Number</InputLabel>
                    <OutlinedInput
                        id="phone"
                        size="small"
                        label="Mpesa Phone Number"
                        type="phone"
                        startAdornment={<InputAdornment position="start">+254</InputAdornment>}
                        error={phoneError !== null}
                        helperText={phoneError}
                        value={phone}
                        onChange={handleChange}
                    />
                    <FormHelperText error>{phoneError}</FormHelperText>
                </FormControl>

                <Typography variant="caption">Select subscription type.</Typography>

                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Chip
                            label={bronze['title']}
                            onClick={() => setSubType('B')}
                            variant={subType === 'B' ? 'contained' : 'outlined'}
                            sx={{ width: "100%" }}
                            color={subType === "B" ? "primary" : "default"}
                            size='small'
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Chip
                            label={gold['title']}
                            onClick={() => setSubType('G')}
                            variant={subType === 'G' ? 'contained' : 'outlined'}
                            sx={{ width: "100%" }}
                            color={subType === "G" ? "primary" : "default"}
                            size='small'
                        />
                    </Grid>
                </Grid>
                {selectedType['price'] && (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <LoadingButton
                            sx={{ width: '100%' }}
                            variant="contained"
                            disableElevation
                            loading={loading}
                            value="B"
                            onClick={(e) => handleSubmit(e, 7)}
                        >
                            <Typography variant="caption">
                                7 Days @ Ksh. {subType === "B" ? bronze['price'] : gold["price"]}
                            </Typography>
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LoadingButton
                            sx={{ width: '100%' }}
                            variant="contained"
                            disableElevation
                            loading={loading}
                            value="G"
                            onClick={(e) => handleSubmit(e, 30)}
                        >
                            <Typography variant="caption">
                                30 days @ Ksh. {subType === "B" ? bronze['month_price'] : gold["month_price"]}
                            </Typography>
                        </LoadingButton>
                    </Grid>
                </Grid>)}

                <Card
                    sx={{
                        p: 1,
                        color: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '#06001E'
                    }}
                >
                    <Typography variant="subtitle2">How it Works</Typography>

                    <Typography variant="caption">
                        <p>1. Buy a subscription plan</p>
                        <p>2. Receive sms confirmation</p>
                        <p>3. Every day a premium tip will be send to your phone via sms.</p>
                        <p>4. You can also check for posted premium tips here.</p>
                    </Typography>

                    <Alert severity="info">
                        <AlertTitle>Info</AlertTitle>
                        Tips are sent instantly via SMS — Ensure you have activated promotional messages !.{' '}
                        <strong>Dial *456*9*5*5*1</strong>
                    </Alert>
                </Card>
            </Box>
        </Card>
    );
};

const SubscriberPanel = () => {
    const [phone, setPhone] = React.useState('');

    const [phoneError, setPhoneError] = React.useState(null);
    const handleChange = (e) => {
        setPhone(e.target.value);
    };

    const [loading, setLoading] = React.useState(false);
    const [tips, setTips] = React.useState([]);
    const [markets, setMarkets] = React.useState([]);

    const handleLoadMarkets = () => {
        const options = {
            method: 'GET',
            url: '/api/slip/markets'
        };

        axios
            .request(options)
            .then((response) => {
                // // console.log(response.data);
                const markets = response.data['markets'];
                setMarkets(markets);
            })
            .catch((error) => {
                // console.log("Error");
                if (error) console.log(error.response);
            });
    };

    React.useEffect(() => {
        handleLoadMarkets();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (loading) {
            return;
        }
        // alert(type);

        if (phone == null || !(phone.length === 10 || phone.length === 9)) {
            setPhoneError('Enter Valid Phone Number');
            return;
        }

        const getPhoneNumber = (phone) => {
            if (phone.length === 10) return `254${phone.substring(1)}`;
            return `254${phone}`;
        };
        setLoading(true);

        const config = {
            method: 'GET',
            url: '/api/manage/get-subscription-slips/'.concat(getPhoneNumber(phone))
        };

        setLoading(true);
        axios
            .request(config)
            .then((res) => {
                const data = res.data;
                console.log(data);

                const slips = data['data'];
                setTips(slips);
            })
            .catch((error) => {
                console.log(error.response);
                if (error.response['data']) {
                    const message = error.response['data']['message'];
                    if (message) {
                        alert(message);
                    } else {
                        alert('Error occurred. Try again');
                    }
                } else {
                    alert('Error occurred. Try again');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Card
            elevation={1}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 0,
                m: 0,
                width: '100%',
                minWidth: '100%'
            }}
        >
            <PageHeader title="Subscriber Panel" />

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 1 }}>
                <Typography variant="subtitle1">Enter phone number to view premium tips</Typography>
                <Typography variant="caption">The phone number should have an active subscription.</Typography>

                <FormControl fullWidth>
                    <InputLabel htmlFor="phone">Mpesa Phone Number</InputLabel>
                    <OutlinedInput
                        id="phone"
                        size="small"
                        label="Mpesa Phone Number"
                        type="phone"
                        startAdornment={<InputAdornment position="start">+254</InputAdornment>}
                        error={phoneError !== null}
                        helperText={phoneError}
                        value={phone}
                        onChange={handleChange}
                    />
                    <FormHelperText error>{phoneError}</FormHelperText>
                </FormControl>

                <LoadingButton variant="contained" disableElevation loading={loading} onClick={handleSubmit}>
                    <Typography variant="subtitle1">Get Tips</Typography>
                </LoadingButton>
            </Box>

            <Divider />

            <Grid container sx={{ p: 1 }} spacing={1}>
                {tips.map((slip) => (
                    <ActiveTipComponentWithResult data={slip['slip']} slip={slip} markets={markets} />
                ))}
            </Grid>
        </Card>
    );
};

const defaultSubscriptionInfo = {
    prevResult: []
};

const SubscriptionsPage = () => {
    const [goldData, setGoldData] = React.useState({ ...defaultSubscriptionInfo });
    const [bronzeData, setBronzeData] = React.useState({
        ...defaultSubscriptionInfo
    });

    const handleLoadData = () => {
        const config = {
            method: 'GET',
            url: '/api/manage/get-subscription-kind'
        };

        axios
            .request(config)
            .then((res) => {
                const data = res.data;
                setGoldData(data['gold']);
                setBronzeData(data['bronze']);
            })
            .catch((error) => {
                console.log('Error: ', error.response);
            });
    };

    React.useEffect(() => {
        handleLoadData();
    }, []);

    return (
        <Box sx={{ p: 0, m: 1 }}>
            <Title title="FutaaPredict | Subscribe to get premium predictions" />
            <HContainer>
                <Grid container spacing={1} sx={{ my: 1 }}>
                    {/* <Grid item xs={12} sm={12} md={4} lg={4}>
                        <SubscribeForm gold={goldData} bronze={bronzeData} />
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                        <SubscriberPanel />
                    </Grid>
                </Grid>
            </HContainer>
        </Box>
    );
};

export default SubscriptionsPage;
