import React from 'react';
import Box from '@mui/material/Box';
import { Container, Grid, Paper, Skeleton } from '@mui/material';

import { useNavigate, useSearchParams } from 'react-router-dom';

import axios from '../../api/axios';
import { ActiveTip } from '../../components/profile/ActiveTips';
import { HContainer } from '../../components/TopTipsters';
import PageHeader from '../../components/PageHeader';
import BreadCrumbView from '../../components/app_bar/BreadCrumbView';
import Title from '../../components/Title';
import ActiveTipsPaginatedComponent from './ActiveTipsPaginated';

class ActiveTipsPageComponent extends React.Component {
    constructor(props) {
        super(props);

        const [query, _] = props.query;

        let filters;
        if (query.get('tips')) {
            filters = {
                gameA: query.get('team_a'),
                gameB: query.get('team_b')
            };
        }

        this.state = {
            activeTips: [],
            tipFilters: filters,
            query: query
        };

        this.handleLoadActiveTips = this.handleLoadActiveTips.bind(this);
    }

    handleLoadActiveTips() {
        const { query } = this.state;

        const top = query.get('type') === 'top';

        const options = {
            method: 'GET',
            url: top ? '/api/home/active/top' : `/api/home/active`
        };

        axios
            .request(options)
            .then((response) => {
                const data = response.data;
                this.setState({
                    activeTips: data.slips
                });
            })
            .catch((error) => {
                if (error) console.log('Error', error.response.data);
            });
    }

    componentDidMount() {
        this.handleLoadActiveTips();
    }

    render() {
        const { activeTips } = this.state;
        const { navigate } = this.props;

        const activeTipsList = [];

        if (!activeTips || activeTips.length === 0) {
            for (let i = 0; i < 2; i++)
                activeTipsList.push(
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        sx={{
                            p: 1,
                            '@media screen and (max-width: 600px)': {
                                width: '100%'
                            }
                        }}
                    >
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={257}
                        />
                    </Grid>
                );
        } else
            for (let i = 0; i < activeTips.length; i++) {
                if (this.state.query.get('tips')) {
                    let found = false;
                    const { gameA, gameB } = this.state.tipFilters;
                    for (let j = 0; j < activeTips[i].tips.length; j++) {
                        const tip = activeTips[i].tips[j];
                        if (
                            tip['home_team'] === gameA &&
                            tip['away_team'] === gameB
                        ) {
                            found = true;
                            break;
                        }
                    }
                    if (!found) continue;
                }
                activeTipsList.push(
                    <ActiveTip slip={activeTips[i]} navigate={navigate} />
                );
            }

        return (
            <Box sx={{ p: 0, m: 1, minHeight: 500 }}>
                <Title title=" FutaaPredict| Active Tips" />
                <HContainer>
                    <BreadCrumbView sx={1} />

                    <Paper
                        square
                        elevation={1}
                        sx={{
                            py: 1,
                            mx: 0,
                            my: 1,
                            width: '100%',
                            height: '100%',
                            borderRadius: 1
                        }}
                    >
                        <PageHeader
                            sx={{ pl: 0 }}
                            title="Active Tips"
                            description="View user information"
                        />

                        <Container sx={{ mt: 1, p: 0 }}>
                            <Box
                                sx={{
                                    p: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}
                            >
                                <Grid
                                    container
                                    spacing={0}
                                    sx={{
                                        m: 0,
                                        p: 0,
                                        maxWidth: '100%'
                                    }}
                                >
                                    {activeTipsList.map((t) => t)}
                                </Grid>
                            </Box>
                        </Container>
                    </Paper>
                </HContainer>
            </Box>
        );
    }
}

export default function ActiveTipsPage(props) {
    const navigate = useNavigate();
    const query = useSearchParams();
    return (
        <ActiveTipsPaginatedComponent
            query={query}
            navigate={navigate}
            {...props}
        />
    );
}
