import React from 'react';

import Box from '@mui/material/Box';
import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import PasswordInput from '../../components/controls/PasswordInput';
import CustomAlert from './CustomAlert';

const inputFontStyle = {
    fontWeight: '500',
    marginBottom: 5
};

const labelFontStyle = {
    fontWeight: '500'
};

export default function AccountData(props) {
    const phoneNumber = props.profile ? props.profile['phone_number'] : '';
    const email = props.user ? props.user['email'] : '';

    const [alertType, setAlertType] = React.useState('error');

    const [currentPassword, setCurrentPassword] = React.useState('');
    const [cpError, setCpError] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [npError, setNpError] = React.useState('');
    const [repeatPassword, setRepeatPassword] = React.useState('');
    const [rpError, setRpError] = React.useState('');

    const [passwordInfo, setPasswordInfo] = React.useState(null);

    const [mPhoneNumber, setPhoneNumber] = React.useState('');
    const [mPhoneErr, setPhoneErr] = React.useState('');

    const [mEmail, setEmail] = React.useState('');
    const [mEmailErr, setEmailErr] = React.useState('');

    const defaultToggle = true;

    const [changePass, setChangePass] = React.useState(defaultToggle);
    const [passLoading, setPassLoading] = React.useState(false);
    const [changePhone, setChangePhone] = React.useState(defaultToggle);
    const [changeEmail, setChangeEmail] = React.useState(defaultToggle);

    const toggleChangePass = () => setChangePass(!changePass);
    const toggleChangePhone = () => setChangePhone(!changePhone);
    const toggleChangeEmail = () => setChangeEmail(!changeEmail);

    const resetErrors = () => {
        setNpError('');
        setCpError('');
        setRpError('');
        setPhoneErr('');
        setEmailErr('');
        setPasswordInfo(null);
    };

    const handleChangeNP = (e) => setNewPassword(e.target.value);
    const handleChangeCP = (e) => setCurrentPassword(e.target.value);
    const handleChangeRP = (e) => setRepeatPassword(e.target.value);
    const handleChangePhone = (e) => setPhoneNumber(e.target.value);
    const handleChangeEmail = (e) => setEmail(e.target.value);

    async function handleSubmitChangePassword() {
        resetErrors();

        if (!currentPassword) {
            setCpError('Fill this');
            return;
        }
        if (!newPassword || newPassword.length < 8) {
            setNpError('Password should be 8 characters or more');
            return;
        }
        if (repeatPassword !== newPassword) {
            setRpError('Confirm password is not equal');
            return;
        }

        const data = {
            old_password: currentPassword,
            new_password: newPassword
        };

        try {
            setPassLoading(true);
            await props.changePass(data);
            setChangePass(false);
            setAlertType('success');
            setPasswordInfo('Saved');
        } catch (e) {
            setAlertType('error');
            setPasswordInfo(e.old_password[0]);
        }

        setPassLoading(false);
    }

    const handleSubmitChangePhone = () => {
        resetErrors();
        if (!mPhoneNumber || mPhoneNumber.length !== 10) {
            setPhoneErr('Phone number should have 10 digits');
            return;
        }

        if (!/^\d+$/.test(mPhoneNumber)) {
            setPhoneErr('Only digits allowed');
            return;
        }

        try {
            props.changePhone(mPhoneNumber);
            setChangePhone(false);
        } catch (e) {
            setPhoneErr(e);
        }
    };

    const handleSubmitChangeEmail = () => {
        resetErrors();
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mEmail)) {
            setEmailErr('Enter valid email');
            return;
        }

        try {
            props.changeEmail(mEmail);
        } catch (e) {
            setEmailErr(e);
        } finally {
            setChangeEmail(false);
        }
    };

    const ChangePasswordForm = () => {
        return (
            <Box sx={{ p: 0, mr: 1, minWidth: '97%' }}>
                <Paper sx={{ p: 0 }} variant="outlined">
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            gap: 1,
                            paddingX: 1,
                            paddingY: 1,
                            m: 0
                        }}
                    >
                        <PasswordInput
                            error={cpError}
                            value={currentPassword}
                            onChange={handleChangeCP}
                            ariaLabel="outlined-current-password-profile"
                            label="Current Password"
                        />

                        <PasswordInput
                            error={npError}
                            value={newPassword}
                            onChange={handleChangeNP}
                            ariaLabel="outlined-new-password-profile"
                            label="New Password"
                        />

                        <PasswordInput
                            error={rpError}
                            value={repeatPassword}
                            onChange={handleChangeRP}
                            ariaLabel="outlined-repeat-password-profile"
                            label="Confirm Password"
                        />

                        <LoadingButton
                            variant="contained"
                            loading={passLoading}
                            disableElevation
                            onClick={handleSubmitChangePassword}
                        >
                            Change Password
                        </LoadingButton>

                        <CustomAlert
                            alertType={alertType}
                            info={passwordInfo}
                        />
                    </Box>
                </Paper>
            </Box>
        );
    };

    const ChangePhoneNumberForm = () => {
        return (
            <Box sx={{ p: 0, mr: 1, minWidth: '97%' }}>
                <Paper sx={{ p: 0 }} variant="outlined">
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            gap: 1,
                            paddingX: 1,
                            paddingY: 1,
                            m: 0
                        }}
                    >
                        <TextField
                            label="Phone Number"
                            variant="outlined"
                            error={mPhoneErr !== ''}
                            helperText={mPhoneErr}
                            value={mPhoneNumber}
                            onChange={handleChangePhone}
                            placeholder="Enter Phone Number"
                            sx={{ width: '100%' }}
                            size="small"
                            InputLabelProps={{ style: { ...labelFontStyle } }}
                            inputProps={{ style: { ...inputFontStyle } }}
                        />

                        <LoadingButton
                            variant="contained"
                            disableElevation
                            onClick={handleSubmitChangePhone}
                        >
                            Change Phone Number
                        </LoadingButton>
                    </Box>
                </Paper>
            </Box>
        );
    };

    const ChangeEmailForm = () => {
        return (
            <Box sx={{ p: 0, mr: 1, minWidth: '97%' }}>
                <Paper sx={{ p: 0 }} variant="outlined">
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            gap: 1,
                            paddingX: 1,
                            paddingY: 1,
                            m: 0
                        }}
                    >
                        <TextField
                            label="Email"
                            variant="outlined"
                            error={mEmailErr !== ''}
                            helperText={mEmailErr}
                            value={mEmail}
                            onChange={handleChangeEmail}
                            placeholder="Enter Email"
                            sx={{ width: '100%' }}
                            size="small"
                            InputLabelProps={{ style: { ...labelFontStyle } }}
                            inputProps={{ style: { ...inputFontStyle } }}
                        />

                        <LoadingButton
                            variant="contained"
                            disableElevation
                            onClick={handleSubmitChangeEmail}
                        >
                            Change Email
                        </LoadingButton>
                    </Box>
                </Paper>
            </Box>
        );
    };

    return (
        <Box sx={{ p: 1, m: 1, maxWidth: 650 }}>
            <Typography variant="h6" color="black" fontWeight={500}>
                Account Data
            </Typography>
            <Box
                sx={{
                    width: 1,
                    paddingTop: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                }}
            >
                <Grid container spacing={1} width="100%">
                    <Grid item sm={12} md={8} lg={4}>
                        <Typography variant="subtitle2" fontWeight={500}>
                            Password
                        </Typography>
                    </Grid>

                    <Grid item sm={12} md={8} lg={8} sx={{ p: 0, m: 0 }}>
                        <Box
                            sx={{
                                p: 0,
                                m: 0,
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 1,
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                                width: '100%'
                            }}
                        >
                            {!changePass ? (
                                ChangePasswordForm()
                            ) : (
                                <Typography
                                    variant="subtitle1"
                                    color="darkgrey"
                                >
                                    Password Set
                                </Typography>
                            )}

                            <Button
                                disableElevation
                                variant="text"
                                sx={{ maxHeight: 30 }}
                                onClick={toggleChangePass}
                            >
                                {changePass ? 'CHANGE' : 'CLOSE'}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={8} md={4}>
                        <Typography variant="subtitle2" fontWeight={500}>
                            Phone Number
                        </Typography>
                    </Grid>

                    <Grid item sm={12} md={8} lg={8} sx={{ p: 0, m: 0 }}>
                        <Box
                            sx={{
                                p: 0,
                                m: 0,
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 1,
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                                width: '100%'
                            }}
                        >
                            {!changePhone ? (
                                ChangePhoneNumberForm()
                            ) : (
                                <Typography
                                    variant="subtitle1"
                                    color="darkgrey"
                                >
                                    {phoneNumber}
                                </Typography>
                            )}

                            <Button
                                disableElevation
                                variant="text"
                                sx={{ maxHeight: 30 }}
                                onClick={toggleChangePhone}
                            >
                                {changePhone ? 'CHANGE' : 'CLOSE'}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={8} md={4}>
                        <Typography variant="subtitle2" fontWeight={500}>
                            Email
                        </Typography>
                    </Grid>

                    <Grid item sm={12} md={8} lg={8} sx={{ p: 0, m: 0 }}>
                        <Box
                            sx={{
                                p: 0,
                                m: 0,
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 1,
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                                width: '100%'
                            }}
                        >
                            {!changeEmail ? (
                                ChangeEmailForm()
                            ) : (
                                <Typography
                                    variant="subtitle1"
                                    color="darkgrey"
                                >
                                    {email}
                                </Typography>
                            )}

                            <Button
                                disableElevation
                                variant="text"
                                sx={{ maxHeight: 30 }}
                                onClick={toggleChangeEmail}
                            >
                                {changeEmail ? 'CHANGE' : 'CLOSE'}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
