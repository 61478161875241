import React from 'react';

import { CssBaseline, ThemeProvider } from '@mui/material';
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';

import Footer from './components/footer/Footer';
import TopAppBar from './components/app_bar/TopAppBar';
import { theme } from './MTheme';
import axios from 'axios';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ReactGA from 'react-ga';

import { GOOGLE_ANALYTICS_KEY } from './data';

ReactGA.initialize(GOOGLE_ANALYTICS_KEY);

export default class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            countryName: '',
            countryCode: '',
            viewDialog: 0, // 0 = search, 1 = openDialog,
            openDialog: false
        };

        this.handleOpenJoku = this.handleOpenJoku.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }

    componentDidMount() {
        if (this.state.viewDialog !== 0) {
            return;
        }

        ReactGA.event({
            action: 'Jokubets Dialog. NG Visitor',
            category: 'User',
            nonInteraction: true
        });

        ReactGA.modalview('/jokubets-modal');

        axios
            .get('https://ipapi.co/json')
            .then((res) => {
                let { country_name, country_code } = res.data;

                this.setState({
                    countryName: country_name,
                    countryCode: country_code
                });

                let msg = `${country_name} - ${country_code}`;

                console.log(msg);

                if (country_code === 'NG') {
                    this.setState({
                        openDialog: true
                    });
                }
            })
            .catch((err) => {
                console.log('Error: ');
                console.log(err);
            });
    }

    handleCloseDialog() {
        this.setState({
            openDialog: false
        });
    }

    handleOpenJoku() {
        ReactGA.event({
            category: 'User',
            action: 'Clicked jokubets link'
        });
        window.open('https://jokubets.com', '_blank').focus();
    }

    render() {
        const { openDialog } = this.state;

        return (
            <React.Fragment>
                <ThemeProvider theme={theme}>
                    <CssBaseline />

                    <Box
                        // style={{ backgroundColor: '#dedede' }}
                        sx={{
                            p: 0,
                            m: 0,
                            // height: "100vh",
                            maxWidth: '100%',
                            position: 'relative',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div class="announcement">
                            <p>
                                If you don't receive an sms with your tip, find it
                                here!&nbsp;&nbsp;&nbsp;
                                <a href="find_tip" target="_blank">
                                    Click{' '}
                                </a>. &nbsp;&nbsp;&nbsp;
                                For premium tips click here &nbsp;&nbsp;&nbsp;
                                <a href="subscriptions" target="_blank">
                                    Click{' '}
                                </a>
                            </p>
                        </div>

                        <Dialog open={false} onClose={this.handleCloseDialog}>
                            <DialogTitle>Visit Nigerian Site</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Visit our new Nigerian site{' '}
                                    <a href="https://jokubets.com" target="_blank">
                                        jokubets.com
                                    </a>
                                    .
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseDialog}>Cancel</Button>
                                <Button onClick={this.handleOpenJoku}>Go</Button>
                            </DialogActions>
                        </Dialog>
                        <TopAppBar />

                        <Box sx={{ p: 0, m: 0, width: '100%' }}>
                            <Outlet />
                        </Box>

                        <Footer />
                    </Box>
                </ThemeProvider>
            </React.Fragment>
        );
    }
}
