import React from 'react';
import { Button, Container, Grid, Paper, Skeleton, styled } from '@mui/material';
import Box from '@mui/material/Box';

import PageHeader from './PageHeader';
import { ProfileHead } from '../routes/profile/Profile';
import { useNavigate } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { ProfileHeadNew } from './profile/ProfileHeadNew';
import { LoadingButton } from '@mui/lab';

export const HContainer = styled(Container)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        padding: 0
    },
    [theme.breakpoints.up('md')]: {
        padding: 0
    }
}));

function Tipster(props) {
    // eslint-disable-next-line no-useless-constructor
    const { data } = props;

    const state = {
        user: data.user,
        profile: data.profile,
        stats: data.stats,
        stats2: data.stats2,
        active_tips: data.active_tips,
        finished_tips: data.finished_tips,
        profileSet: data.profileSet
    };

    return (
        <ProfileHeadNew
            profile={state.profile}
            user={state.user}
            stats={state.stats}
            stats2={state.stats2}
            week={props.week}
        />
    );
}

function TopTipsters(props) {
    const { tipsters } = props;
    console.log(tipsters);

    let tipsters_grid = [];

    const nTipsters = tipsters ? tipsters.length : 0;
    // const limit = home ? Math.min(nTipsters, 8) : nTipsters;

    if (!tipsters) {
        for (let i = 0; i < 2; i++) {
            tipsters_grid.push(
                <Grid xs={12} sm={6} md={6} lg={3} item sx={{ m: 0, p: 1 }}>
                    <Skeleton variant="rectangular" width="100%" height={257} />
                </Grid>
            );
        }
    } else
        for (let i = 0; i < nTipsters; i++) {
            console.log(tipsters[i]);
            tipsters_grid.push(
                <Grid xs={12} sm={6} md={6} lg={3} item sx={{ m: 0, p: 1 }}>
                    <Tipster
                        // week={this.props.week}
                        data={tipsters[i].result}
                    />
                </Grid>
            );
        }

    return (
        <Box sx={{ p: 0, m: 1 }}>
            <HContainer>
                <Paper
                    square
                    elevation={1}
                    sx={{
                        py: 1,
                        mx: 0,
                        my: 1,
                        width: '100%',
                        height: '100%',
                        borderRadius: 1
                    }}
                >
                    <PageHeader title="Top Tipsters" description="The best tipsters in this site" />

                    <Grid container spacing={0} sx={{ px: 1 }}>
                        {tipsters_grid.map((t) => t)}
                    </Grid>

                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        {props.next && (
                            <LoadingButton size="small" onClick={props.onSeeMore} loading={props.loading}>
                                See More
                            </LoadingButton>
                        )}
                    </Box>
                </Paper>
            </HContainer>
        </Box>
    );
}

export default withCookies(TopTipsters);
