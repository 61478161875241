import React from 'react';
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    Link,
    List,
    ListItem,
    ListItemText,
    OutlinedInput,
    Paper,
    Skeleton,
    Typography,
    Box,
    Container
} from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate, useParams, redirect, Link as RouterLink } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import Title from '../components/Title';

import NigeriaFlagImage from '../assets/ngn.png';
import UgandaFlagImage from '../assets/ugx.png';

const SelectCurrency = () => {
    const params = useParams();
    const id = params.id;

    const details = [
        {
            country: 'Nigeria',
            flag: NigeriaFlagImage,
            link: `/buy_tip_ng/${id}`
        },
        {
            country: 'Uganda',
            flag: UgandaFlagImage,
            link: `/buy_tip_ug/${id}`
        }
    ];

    return (
        <Container sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Title title="FutaaPredict| Select Country" />
            <PageHeader sx={{ pl: 0 }} title="Select Country" description="Buy tip" />

            <Paper sx={{ display: 'flex', flexDirection: 'column', p: 1, mb: 1, maxWidth: 600, gap: 1 }}>
                {details.map((it, idx) => (
                    <Paper
                        variant="outlined"
                        id={idx}
                        sx={{ display: 'flex', flexDirection: 'row', p: 1, gap: 1, alignItems: 'center' }}
                        component={RouterLink}
                        to={it.link}
                    >
                        <img src={it.flag} alt={it.country} style={{ height: '70px', borderRadius: 1 }} />
                        <Typography variant="h4" sx={{ flex: 1 }}>
                            {it.country}
                        </Typography>
                    </Paper>
                ))}
            </Paper>
        </Container>
    );
};

export default SelectCurrency;
